export default function ({ $axios, req }) {
  if (process.server) {
    /**
     * @description
     * SSR 환경에서 x-forwarded-for 헤더에 clientIp를 추가해 클라이언트의 ip를 추적할 수 있게 합니다.
     */
    const clientIP = req.connection.remoteAddress;
    $axios.defaults.headers.common['x-forwarded-for'] = clientIP;

    /**
     * @description
     * SSR 환경에서 client의 user-agent를 헤더에 실어서 보내줍니다.
     */
    $axios.defaults.headers['user-agent'] = req.headers['user-agent'];

    $axios.onRequest(config => {
      config.headers['x-apigateway-event'] = '';
      config.headers['x-apigateway-context'] = '';
      // console.log('AXIOS_SERVER', config.headers);
    });
  }

  // $axios.onRequest(config => {
  //   if (config.method === 'get') {
  //     // config.baseURL = 'https://apis-v2.gguge.com/api';
  //     // config.baseURL = 'https://beta.gguge.com/api';
  //   }
  // });
}
