
/**
 * @description
 * 신규 홈 > 네비게이션 헤더
 */

import { mapActions, mapMutations } from 'vuex';
import { DOM_ID_LIST } from '@/utils/enums.js';

import NavLogo from '@/components/common/nav/NavLogo.vue';
import MenuList from '@/components/common/nav/MenuList.vue';
import AppDrawer from '@/components/layout/AppDrawer';
import SearchBar from '@/components/common/nav/SearchBar.vue';
import PageSwitch from './PageSwitch.vue';
import KeywordList from './KeywordList.vue';

export default {
  name: 'HomeHeader',
  components: {
    NavLogo,
    PageSwitch,
    MenuList,
    AppDrawer,
    SearchBar,
    KeywordList,
  },
  data() {
    return {
      isDrawer: false,
      before: 0,
    };
  },
  computed: {
    navigationId: () => DOM_ID_LIST.NAV,
  },
  watch: {
    $route() {
      this.closeDrawer();
    },
    '$auth.loggedIn'() {
      this.setPersonalizedProducts();
    },
  },
  mounted() {
    this.setPersonalizedProducts();
    // <header>가 scrollUp될 때, position: sticky로 수정하고, transition되도록 함.
    addEventListener('scroll', this.scrollEvent);
  },
  methods: {
    ...mapActions('user/product', ['selectUserMyProductList']),
    ...mapActions('user/favorite', [
      'selectFavoriteProductIds',
      'selectFavoriteCourseIds',
    ]),
    ...mapMutations(['setIsHiddenNavHeader']),
    ...mapMutations({
      resetAllUserProduct: 'user/product/RESET_ALL_USER_PRODUCT',
      resetFavoriteProduct: 'user/favorite/RESET_FAVORITE_PRODUCTS',
    }),
    setPersonalizedProducts() {
      if (this.$auth.loggedIn) {
        // 참여한 수업 표기
        this.selectUserMyProductList();

        // 찜바구니 수업 표기
        this.selectFavoriteProductIds();
        this.selectFavoriteCourseIds();
      } else {
        // 로그아웃시 전체 초기화
        this.resetAllUserProduct();
        this.resetFavoriteProduct();
      }
    },
    closeDrawer() {
      this.isDrawer = false;
    },
    toggleDrawer() {
      this.isDrawer = !this.isDrawer;
    },
    /**
     * @description 웹 네비게이션 헤더에서 아래로 스크롤하면 헤더 숨김. 위로 스크롤 하면 헤더 나타냄
     */
    scrollEvent() {
      if (window.scrollY <= 0 || window.scrollY >= window?.scrollHeight) return;

      if (this.before < window.scrollY) {
        // 스크롤 내릴 때: 헤더가 viewport에서 사라짐
        this.setIsHiddenNavHeader(true);
      } else {
        // 스크롤 올릴 때: 헤더가 viewport에 나타나야 함.
        this.setIsHiddenNavHeader(false);
      }
      this.before = scrollY;
    },
  },
};
