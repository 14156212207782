export const state = () => ({
  helpfulReviewIds: [],
  reviewReward: 0,
});

export const getters = {
  helpfulReviewIds: state => state.helpfulReviewIds,
  reviewReward: state => state.reviewReward,
};

export const actions = {
  // 리뷰 목록 검색 조회
  async searchReviewList(
    _,
    { page = 1, limit, productId, teacherId, userId, listType },
  ) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/user/review/search`,
        {
          params: {
            page,
            size: limit,
            productId,
            teacherId,
            userId,
            listType: listType === 'RELEASE_DATE' ? undefined : listType, // POPULARITY, RATING, RELEASE_DATE
          },
        },
      );

      const proxyList = data.items.map(item => {
        if (item.images) item.images = item.images.split(',');
        else item.images = [];
        if (productId) item.productTitle = ''; // 수업 상세 페이지에서는 수업 제목 노출 안 함
        /**
         * @description
         * 리뷰 작성자의 아이 나이와 작성일을 노출하는 데 사용되는 데이터
         * replace 적용한 이유는 safari 15버전에서 new Date 객체가 안 만들어지는 포멧에 대응하기 위함.
         *
         * ex) createdAt: 2023-06-15 06:10:31 => 2023/06/15 06:10:31
         */
        item.createdAt = item.createdAt.replace(/-/g, '/').replace('T', ' ');
        item.teacherCommentedAt = item.teacherCommentedAt
          ? item.teacherCommentedAt.replace(/-/g, '/').replace('T', ' ')
          : null;
        item.adminCommentedAt = item.adminCommentedAt
          ? item.adminCommentedAt.replace(/-/g, '/').replace('T', ' ')
          : null;
        item.user = {
          id: item.userId,
          name: item.userName,
          profileImage: item.userProfileImageUrl,
        };
        item.teacher = {
          id: item.teacherId,
          name: item.teacherName,
          profileImage: item.teacherProfileImageUrl,
        };
        return item;
      });

      return {
        ...data,
        items: proxyList,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // 나의 리뷰 목록 조회
  selectReviewList(_, { page, limit, isReview }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/review`, {
          params: {
            page,
            size: limit,
            reviewStatus: isReview ? 'written' : 'writable',
          },
        })
        .then(res => {
          // 데이터 모양 잡기
          // res.items.forEach(s => {
          //   s.schedule.product.id = s.schedule.productId;
          //   if (s.userProductReview) {
          //     if (s.userProductReview.images) {
          //       s.userProductReview.images =
          //         s.userProductReview.images.split(',');
          //     }
          //   }
          // });
          resolve(res);
        })
        .catch(reject);
    });
  },
  selectReview(_, { userScheduleId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/user/review/user-schedule/${userScheduleId}`,
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 등록
  createReview(_, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/user/review/user-schedule/${id}`,
          formData,
          // {
          //   headers: {
          //     'Content-Type': 'multipart/form-data',
          //   },
          // },
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 수정
  updateReview(_, { id, formData }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(
          `${process.env.API_V3}/v3/user/review/user-schedule/${id}`,
          formData,
        )
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 나의 일반 스케줄 리뷰 삭제
  deleteReview(_, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`${process.env.API_V3}/v3/user/review/user-schedule/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 유저가 도움이 됐어요 누른 리뷰 아이디 목록을 일반상품 & 구독상품 나눠서 들고옵니다
  getHelpfulReviewIds({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/review/help`)
        .then(res => {
          if (!res.error && res.data) {
            commit('SET_REVIEW_IDS', res.data);
          } else {
            console.error('오류 발생');
          }
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  toggleThisReviewHelpful({ commit }, { reviewId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/review/${reviewId}/help`)
        .then(res => {
          if (!res.error && res.data && res.data.isHelpful !== undefined) {
            if (res.data.isHelpful) commit('ADD_REVIEW_ID', { reviewId });
            else commit('REMOVE_REVIEW_ID', { reviewId });
          } else {
            console.error('오류 발생');
          }

          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getReviewReward({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/review/receivable-reward`)
        .then(res => {
          commit('SET_REVIEW_REWARD', res.data?.estimateRewardMoney);
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};

export const mutations = {
  SET_REVIEW_IDS(state, reviews) {
    state.helpfulReviewIds = reviews;
  },
  ADD_REVIEW_ID(state, { reviewId }) {
    if (!state.helpfulReviewIds.includes(reviewId))
      state.helpfulReviewIds.push(reviewId);
  },
  REMOVE_REVIEW_ID(state, { reviewId }) {
    const currentIdx = state.helpfulReviewIds.findIndex(i => i === reviewId);
    if (currentIdx > -1) {
      state.helpfulReviewIds.splice(currentIdx, 1);
    }
  },
  RESET_HELPFUL_REVIEW_IDS(state) {
    state.helpfulReviewIds = [];
  },
  SET_REVIEW_REWARD(state, reward) {
    state.reviewReward = reward;
  },
};
