
import {
  CLICK_FOOTER,
  CLICK_GGUGEMALL,
} from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { GGUGE_MALL_COMPONENT } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { GGUGE_MALL_FOOTER_UTM, GGUGE_MALL_LINK } from '@/utils/ggugeMall';
import {
  GGUGE_EMAIL_ALLIANCE,
  GGUGE_EMAIL_COLLABORATE,
  GGUGE_HELP_CENTER_CANCEL,
  GGUGE_HELP_CENTER_EVENT,
  GGUGE_HELP_CENTER_FIRST,
  GGUGE_HELP_CENTER_PRIVACY,
  GGUGE_HELP_CENTER_SERVICE,
  GGUGE_HELP_CENTER_ZOOM,
  GGUGE_TEACHER_LINK,
} from '@/utils/linkPath.js';

export default {
  name: 'FooterService',
  data() {
    return {
      about: [
        {
          label: '꾸그가 처음이라면',
          to: GGUGE_HELP_CENTER_FIRST,
        },
        { label: '꾸그 소개', to: '/introduction', isNuxtLink: true },
        { label: '회사 소개', to: 'https://glorang.com' },
      ],
      support: [
        {
          label: '이벤트/프로모션',
          to: GGUGE_HELP_CENTER_EVENT,
        },
        {
          label: '서비스 이용약관',
          to: GGUGE_HELP_CENTER_SERVICE,
        },
        {
          label: '개인정보 처리방침',
          to: GGUGE_HELP_CENTER_PRIVACY,
        },
        {
          label: '취소 및 환불 규정',
          to: GGUGE_HELP_CENTER_CANCEL,
        },
        {
          label: 'Zoom 이용 가이드',
          to: GGUGE_HELP_CENTER_ZOOM,
        },
      ],
      partner: [
        {
          label: '선생님 지원하기',
          to: GGUGE_TEACHER_LINK,
        },
        {
          label: '교육기관 협력 문의',
          to: GGUGE_EMAIL_COLLABORATE,
        },
        {
          label: '기업 제휴 문의',
          to: GGUGE_EMAIL_ALLIANCE,
        },
        { label: '꾸그몰', to: `${GGUGE_MALL_LINK}${GGUGE_MALL_FOOTER_UTM}` },
      ],
    };
  },
  computed: {
    serviceList: ({ about, support, partner }) => [
      { title: 'About', list: about },
      { title: 'Support', list: support },
      { title: 'Partner', list: partner },
    ],
  },
  methods: {
    // 꾸그몰 클릭시에만 로그를 남깁니다
    logClickEvent(label) {
      this.logUserCustomEvent(CLICK_FOOTER, { label });

      if (label === '꾸그몰') {
        this.logUserCustomEvent(CLICK_GGUGEMALL, {
          component: GGUGE_MALL_COMPONENT.footer,
        });
      }
    },
  },
};
