export const state = () => ({
  chargedMoney: 0, // 충전된 머니
  rewardMoney: 0, // 구매 보상으로 지급된 머니 부분 (환불 시 소멸)
  isMoneyModalOpen: false,
});

export const getters = {
  totalMoney: state => state.chargedMoney + state.rewardMoney,
  chargedMoney: state => state.chargedMoney,
  rewardMoney: state => state.rewardMoney,
  isMoneyModalOpen: state => state.isMoneyModalOpen,
};

export const actions = {
  /**
   * @description
   * 유저의 보유 머니를 조회하는 api
   */
  selectUserMoneyStatus({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/money`)
        .then(res => {
          commit('SET_MONEY_STATUS', res.data);
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 혜택금액 표
   * /v3/user/money/reward-policy
   */
  getRewardPercentage() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/money/reward-policy`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 유저의 꾸그머니 히스토리
   * @param {number} page
   * @param {number} size
   * @param {string} listFilter all | charge | use | withdraw
   */
  selectMoneyHistory(_, { page, size, listFilter }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/money-history`, {
          params: {
            page,
            size,
            listFilter,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 해당 orderId를 단건 조회
   * @param {string} orderUniqueId 청약철회하려는 머니의 orderId
   */
  getWithdrawInfo(_, { orderUniqueId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/order/${orderUniqueId}/withdrawal-info`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 청약철회 가능한 꾸그머니 결제건에 대해
   * orderId로 청약철회를 하는 API
   * @param {string} orderUniqueId 청약철회하려는 머니의 orderId
   */
  withdrawMoneyOrder(_, { orderUniqueId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .post(`${process.env.API_V3}/v3/order/${orderUniqueId}/withdraw`)
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 머니 쿠폰 사용
   * @param {string} couponCode 사용하려는 쿠폰 코드
   */
  applyMoneyCoupon(_, { couponCode }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/etc/money-coupon/use`, {
          couponCode,
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          reject(e.response.data);
        });
    });
  },
};

export const mutations = {
  SET_MONEY_STATUS(state, { money, rewardMoney }) {
    state.chargedMoney = money;
    state.rewardMoney = rewardMoney;
  },
  OPEN_MONEY_MODAL(state) {
    state.isMoneyModalOpen = true;
  },
  CLOSE_MONEY_MODAL(state) {
    state.isMoneyModalOpen = false;
  },
};
