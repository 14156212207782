
import { mapActions, mapGetters } from 'vuex';

import CategoryItem from './CategoryItem.vue';

export default {
  name: 'CategoryListModal',
  components: { CategoryItem },
  data() {
    return {
      depthOpenCategoryId: -1,
    };
  },
  computed: {
    ...mapGetters('filter', ['categoryList']),
  },
  watch: {
    /**
     * @description 검색 쿼리가 변경되면 브라우저 탭 타이틀 변경
     */
    $route() {
      this.setActiveMobileCategoryListModal(false);
    },
  },
  // 모달 뒤에 요소 스크롤 막기
  mounted() {
    document.body.classList.add('no-scroll-body');

    if (this.categoryList.length === 0) {
      this.getProductCategoryList();
    }
  },
  destroyed() {
    this.setActiveMobileCategoryListModal(false);
    document.body.classList.remove('no-scroll-body');
  },
  methods: {
    ...mapActions(['setActiveMobileCategoryListModal']),
    ...mapActions('filter', ['getProductCategoryList']),
    /**
     * @description
     * 뎁스를 열어줘야 하는 카테고리 아이디
     */
    openCategoryDepth(categoryId) {
      this.depthOpenCategoryId =
        this.depthOpenCategoryId === categoryId ? -1 : categoryId;
    },
    handleClick(categoryId) {
      this.setActiveMobileCategoryListModal(false);
      this.$router.push({
        name: 'search',
        query: {
          order: 'POPULARITY',
          selectedCategory: categoryId,
        },
      });
    },
  },
};
