
import {
  YOUTUBE_LINK,
  GGUGE_BLOG_LINK,
  FACEBOOK_LINK,
  INSTARGRAM_LINK,
  NAVER_BLOG_LINK,
} from '@/utils/linkPath.js';

export default {
  name: 'SocialLink',
  data() {
    return {
      socialLink: [
        {
          label: 'Youtube',
          to: YOUTUBE_LINK,
          icon: require('@/assets/img/footer/youtube.png'),
        },
        {
          label: 'Blog',
          to: GGUGE_BLOG_LINK,
          icon: require('@/assets/img/footer/blog.png'),
        },
        {
          label: 'Facebook',
          to: FACEBOOK_LINK,
          icon: require('@/assets/img/footer/facebook.png'),
        },
        {
          label: 'Instagram',
          to: INSTARGRAM_LINK,
          icon: require('@/assets/img/footer/instagram.png'),
        },
        {
          label: 'NaverBlog',
          to: NAVER_BLOG_LINK,
          icon: require('@/assets/img/footer/naver.png'),
        },
      ],
    };
  },
};
