
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  setLastClickSection,
  removeLastClickSection,
  setRecommendCode,
} from '@/utils/localStorageUtils.js';

import PsycheNavigationBar from '@/components/psyche/layout/PsycheNavigationBar.vue';
import LoginModal from '@/components/common/modal/LoginModal';
import AppFooter from '@/components/layout/footer/AppFooter';

export default {
  components: { LoginModal, PsycheNavigationBar, AppFooter },
  data() {
    return {
      metaTitle: '꾸그의 심리와 진로 - 아이의 가능성 탐험하기',
      metaDescription:
        '심리 검사는 학생의 행동 특성을 파악하기 위해 실시됩니다. 해설 영상을 통해 각 검사가 파악하고자 하는 행동 특성을 알아 보고, 맞춤형 양육 및 교육 방향을 설정해보세요. 자녀의 밝은 미래를 위한 첫걸음, 꾸그의 전문가들이 함께 합니다.',
    };
  },
  head() {
    return {
      title: '꾸그의 심리와 진로',
      meta: [
        { hid: 'og:type', property: 'og:type', content: 'website' },
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.metaTitle,
          itemprop: 'name',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.metaDescription,
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: '/psyche-og.png',
          itemprop: 'image',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$route.fullPath,
          itemprop: 'url',
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.metaTitle,
        },
        {
          hid: 'twitter:url',
          name: 'twitter:url',
          content: this.$route.fullPath,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.metaDescription,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: '/psyche-og.png',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('user', ['userChildrenAgesArrayForFilter', 'children']),
  },
  created() {
    this.getProductCategoryList();
  },
  mounted() {
    if (this.$route.query.invite) {
      setRecommendCode(this.$route.query.invite);
    }

    // 로그인이 된 유저면,
    if (this.$auth.loggedIn && this.$auth.user) {
      // 앰플리튜드에 프로퍼티 세팅
      this.setAmplitudeProperty();
    }
  },
  beforeDestroy() {
    removeLastClickSection();
  },
  methods: {
    ...mapActions('filter', ['getProductCategoryList']),
    ...mapActions('user', ['selectChildren']),
    ...mapMutations('search/filter', ['SET_SELECTED_AGE']),
    clickLogEvent(e) {
      // dataset이 있고, 그 중에 apSection이 있다면 앰플에 잡고 싶은 section
      const sectionData = e
        .composedPath()
        .find(el => el.dataset && 'apSection' in el.dataset);
      if (sectionData) {
        const sectionName = sectionData.dataset.apSection;
        setLastClickSection(sectionName);
      }
    },
    async setAmplitudeProperty() {
      if (!this.$auth.loggedIn) return; // 수비코드

      const loginUserData = this.$auth.user;
      // 여러번 호출해도 앰플리튜드 내에서 한 번 만 저장되야하는 프로퍼티
      const ampOnceUserProperties = {
        first_favorite_date: loginUserData.meta?.firstFavoriteDate || undefined,
      };

      // 매 호출마다 최신 데이터로 갱신해도 상관없는 유저 데이터
      const ampUserProperties = {
        'user id': this.$auth.user.id,
        first_purchased_date:
          loginUserData.meta?.firstPurchaseDate || undefined,
        registered_date: loginUserData.createAt || undefined,
      };

      if (!this.userChildrenAgesArrayForFilter.length) {
        await this.selectChildren().then(res => {
          ampUserProperties.childrenBirthYear = res.map(
            child => child.birthYear,
          );
          ampUserProperties.childrenGender = res.map(child => child.gender);
        });
        this.SET_SELECTED_AGE([...this.userChildrenAgesArrayForFilter]);
      } else {
        ampUserProperties.childrenBirthYear = this.children.map(
          child => child.birthYear,
        );
        ampUserProperties.childrenGender = this.children.map(
          child => child.gender,
        );
      }

      this.setAmplitudeUserPropertiesOnce(ampOnceUserProperties);
      this.setUserProperties(ampUserProperties);
    },
  },
};
