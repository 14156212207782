
// import { mapGetters } from 'vuex';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'SearchBar',
  components: { Icon },
  props: {
    fromParameter: { type: String, default: null }, // 앰플리튜드에 쏠 from 파라미터값을 주고싶다면
  },
  computed: {
    // ...mapGetters('user', ['userChildrenAgesForQuery']),
    // 검색 페이지에선 숨김처리
    isSearchPage() {
      return this.$route.path === '/search';
    },
    // selectedAge() {
    //   return this.userChildrenAgesForQuery;
    // },
  },
};
