
import {
  GGUGE_HELP_CENTER_EVENT,
  GGUGE_HELP_CENTER_KIDS,
} from '@/utils/linkPath.js';

import DrawerReviewTooltip from './DrawerReviewTooltip';

export default {
  name: 'DrawerMenu',
  components: { DrawerReviewTooltip },
  computed: {
    helpCenterEventLink: () => GGUGE_HELP_CENTER_EVENT,
    helpCenterKidsLink: () => GGUGE_HELP_CENTER_KIDS,
  },
  methods: {
    // nuxtLink에 로그인이 필수라면, 비로그인인 경우 해당 경로를 ''로 막아 이동하지 못하게 합니다
    nuxtLinkRequiredLoggedIn({ ...restProperties }) {
      if (!this.$auth.loggedIn) {
        return '';
      } else {
        return { ...restProperties };
      }
    },
    onClickNavMenu({ ...rest }) {
      this.$emit('on-click-menu', { ...rest });
    },
  },
};
