
export default {
  name: 'TextTabBar',
  props: {
    initTabIdx: { type: Number, default: 0 },
    tabList: { type: Array, required: true, default: () => [] },
    activeTabList: { type: Array, default: () => [] },
    showIndicator: { type: Boolean, default: true },
    itemFontStyle: { type: String, default: 'h-b-md' },
    isFetching: { type: Boolean, default: false },
  },
  data() {
    return {
      indicatorWidth: 100,
      indicatorX: 0,
      selectedTabIdx: this.initTabIdx,
    };
  },
  computed: {
    selectedTab: ({ tabList, selectedTabIdx }) => tabList[selectedTabIdx],
  },
  watch: {
    // 위에서 내려준 initTabIdx 값이 변경되면 하단 인디케이터의 위치 이동을 위해 selectedTabIdx를 맞춰줍니다
    initTabIdx(v) {
      this.selectedTabIdx = Math.max(v, 0);
    },
    selectedTabIdx(v) {
      this.$nextTick(() => {
        this.setIndicatorStyle(v);
      });
    },
  },
  mounted() {
    this.setIndicatorStyle();
    window.addEventListener('resize', this.setIndicatorStyle);
  },
  destroyed() {
    window.removeEventListener('resize', this.setIndicatorStyle);
  },
  methods: {
    setIndicatorStyle(selectedTabIdx) {
      const tabIdx = selectedTabIdx || this.selectedTabIdx;
      if (tabIdx < 0) return;
      const textTabList = this.$refs.textTabList;
      if (textTabList && textTabList[tabIdx]) {
        this.indicatorX = textTabList[tabIdx].offsetLeft;
        this.indicatorWidth = textTabList[tabIdx].offsetWidth;
      }
    },
    onClickTab(tabIndex, tabValue) {
      this.selectedTabIdx = tabIndex;
      this.$emit('change', tabValue, tabIndex);
    },
  },
};
