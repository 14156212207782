
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  setLastClickSection,
  removeLastClickSection,
  getIsApp,
  setIsApp,
  setRecommendCode,
} from '@/utils/localStorageUtils.js';

import NavigationBar from '@/components/common/nav/NavigationBar.vue';
import AppFooter from '@/components/layout/footer/AppFooter';
import BottomSheet from '@/components/common/bottomSheet/BottomSheet.vue';
import Alert from '@/components/common/Alert';
import LoginModal from '@/components/common/modal/LoginModal';

export default {
  components: {
    NavigationBar,
    AppFooter,
    BottomSheet,
    Alert,
    LoginModal,
  },
  computed: {
    ...mapGetters(['isAlert', 'alert']),
    ...mapGetters('user', ['userChildrenAgesArrayForFilter', 'children']),
    pathAt() {
      return getPathAt(this.$route);
    },
    isApp() {
      if (process.client) {
        return this.$route.query.isApp || getIsApp() === true;
      } else {
        return this.$route.query.isApp;
      }
    },
  },
  created() {
    this.getProductCategoryList();

    // if (!this.slideList.length) {
    //   this.selectEventList();
    // }
  },
  async mounted() {
    if (this.$route.query.isApp) setIsApp();
    if (this.$route.query.invite) setRecommendCode(this.$route.query.invite);

    if (this.$auth.loggedIn && this.$auth.user) {
      const loginUserData = this.$auth.user;
      // 여러번 호출해도 앰플리튜드 내에서 한 번 만 저장되야하는 프로퍼티
      const ampOnceUserProperties = {
        first_favorite_date: loginUserData.meta?.firstFavoriteDate || undefined,
      };

      // 매 호출마다 최신 데이터로 갱신해도 상관없는 유저 데이터
      const ampUserProperties = {
        'user id': this.$auth.user.id,
        first_purchased_date:
          loginUserData.meta?.firstPurchaseDate || undefined,
        registered_date: loginUserData.createAt || undefined,
      };

      if (!this.userChildrenAgesArrayForFilter.length) {
        await this.selectChildren().then(res => {
          ampUserProperties.childrenBirthYear = res.map(
            child => child.birthYear,
          );
          ampUserProperties.childrenGender = res.map(child => child.gender);
        });
      } else {
        ampUserProperties.childrenBirthYear = this.children.map(
          child => child.birthYear,
        );
        ampUserProperties.childrenGender = this.children.map(
          child => child.gender,
        );
      }

      this.setAmplitudeUserPropertiesOnce(ampOnceUserProperties);
      this.setUserProperties(ampUserProperties);
    }
  },
  beforeDestroy() {
    removeLastClickSection();
  },
  methods: {
    ...mapActions(['openSimpleAlert']),
    // ...mapActions('event', ['selectEventList']),
    ...mapActions('user', ['selectChildren']),
    ...mapActions('filter', ['getProductCategoryList']),
    ...mapMutations({ openAlert: 'OPEN_ALERT' }),
    clickLogEvent(e) {
      // dataset이 있고, 그 중에 apSection이 있다면 앰플에 잡고 싶은 section
      const sectionData = e
        .composedPath()
        .find(el => el.dataset && 'apSection' in el.dataset);

      if (sectionData) {
        const sectionName = sectionData.dataset.apSection;

        setLastClickSection(sectionName);
      }
    },
  },
};
