import { contentsRenderer } from '@/utils/contentRender';
import qs from 'qs';

const initState = () => {
  return {
    id: 0,
    title: null,
    thumbnailImage: null,
    description: null,
    category: {
      id: 0,
      name: null,
      parentId: 0,
      parentName: null,
    },
    classCount: 0,
    favoriteCount: 0,
    classes: [
      // 예시
      // {
      //   id: 1,
      //   weight: 1,
      //   title: null,
      //   thumbnailImage: null,
      //   minAge: 0,
      //   maxAge: 0,
      //   price: 0,
      //   teacherName: null,
      //   teacherProfileImage: null,
      //   reviewRating: 0,
      //   reviewCount: 0,
      //   hasSchedule: true,
      //   isFull: false,
      //   isUse: true,
      //   isPublished: true,
      //   isPurchased: true,
      //   classType: 'SUBSCRIBE',
      // }
    ],
    courseProgress: 0,
    minAge: 0,
    maxAge: 0,
    teacherProfileImages: [],
  };
};

export const state = () => initState();

export const getters = {
  id: ({ id }) => id,
  title: ({ title }) => title,
  thumbnailImage: ({ thumbnailImage }) => thumbnailImage,
  description: ({ description }) => description,
  category: ({ category }) => category,
  classCount: ({ classCount }) => classCount,
  favoriteCount: ({ favoriteCount }) => favoriteCount,
  classes: ({ classes }) => classes,
  courseProgress: ({ courseProgress }) => courseProgress,
  minAge: ({ minAge }) => minAge,
  maxAge: ({ maxAge }) => maxAge,
  // 찜하기 할 때 필요한 오브젝트
  favoriteCourseObj: state => ({
    id: state.id,
    title: state.title,
    thumbnailImage: state.thumbnailImage,
    categoryName: state.category.name,
    totalClassCount: state.classCount,
    minAge: state.minAge,
    maxAge: state.maxAge,
    teacherImages: state.teacherProfileImages,
    progress: state.courseProgress,
  }),
};

export const actions = {
  /**
   * @description 스토어 초기화
   */
  resetCourseStore({ commit }) {
    commit('RESET_COURSE_DETAIL');
  },
  async getCourseDetail({ commit }, { courseId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/course/${courseId}`,
        {
          params: this.$auth.loggedIn ? { userId: this.$auth.user.id } : null,
        },
      );

      commit('SET_COURSE_DETAIL', data);

      return data;
    } catch (e) {
      console.error(e);
    }
  },
  /**
   * @description
   * 로드맵 리스트받아오는 api
   */
  async getCourseList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/course-curation`,
      );

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 클래스 id를 받아 해당 클래스가 포함된 로드맵 리스트 받아오는 api
   */
  async getCourseListByClassId(_, { classId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/course`,
        {
          params: { classId, size: 20, page: 1 },
        },
      );
      return data.items || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  /**
   * @description
   * 각 로드맵의 진행율을 받아오는 api
   * @param {Array<Number>} courseIds
   */
  async getCourseProgress(_, { courseIds }) {
    // 비로그인시 401 에러가 남. 수비코드로 빈 배열 리턴
    if (!this.$auth.loggedIn) return [];

    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/course/progress`,
        {
          params: { courseIds },
          paramsSerializer: params =>
            qs.stringify(params, { arrayFormat: 'repeat' }),
        },
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  /**
   * @description
   * 로드맵 큐레이션
   */
  async getCourseCuration() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/course-curation`,
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 내가 듣고 있는 수업이 포함된 로드맵
   */
  async getMyClassCourses() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/my/ongoing-course`,
        {
          params: { page: 1, size: 10 },
        },
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  RESET_COURSE_DETAIL(state) {
    Object.assign(state, initState());
  },
  SET_COURSE_DETAIL(state, course) {
    Object.assign(state, course);

    const renderContents = contentsRenderer(course.description);

    state.description = renderContents;
  },
  INCREASE_FAVORITE_COUNT(state) {
    state.favoriteCount++;
  },
  DECREASE_FAVORITE_COUNT(state) {
    state.favoriteCount--;
  },
};
