import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';
import { PRODUCT_STATUS } from '@/utils/enums.js';

export const actions = {
  async getDemandSurveyList(_, { age, page, limit }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/survey`,
        {
          params: {
            age,
            page,
            size: limit,
          },
        },
      );
      // 데이터 가공. productStatus를 수요조사로 세팅해줍니다.
      return {
        ...data,
        items: convertV3ClassToV2Product(data.items).map(p => ({
          ...p,
          productStatus: PRODUCT_STATUS.SURVEY,
        })),
      };
    } catch (e) {
      console.error(e);
    }
  },
};
