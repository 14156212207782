import { ArrowIconNames, ArrowIconSet } from './constants/arrow.js';
import { CommerceIconNames, CommerceSet } from './constants/commerce.js';
import {
  CommunicationNames,
  CommunicationSet,
} from './constants/communication.js';
import { EducationIconNames, EducationIconSet } from './constants/education.js';
import { HeartIconNames, HeartIconSet } from './constants/hearts.js';
import {
  MathFinanceIconSet,
  MathFinanceNames,
} from './constants/mathFinance.js';
import { PeopleIconNames, PeopleIconSet } from './constants/people.js';
import {
  SystemDeviceIconNames,
  SystemDeviceIconSet,
} from './constants/systemDevice.js';
import { TimeIconNames, TimeIconSet } from './constants/time.js';

const IconSet = {
  ...HeartIconSet,
  ...ArrowIconSet,
  ...EducationIconSet,
  ...PeopleIconSet,
  ...CommerceSet,
  ...TimeIconSet,
  ...SystemDeviceIconSet,
  ...CommunicationSet,
  ...MathFinanceIconSet,
};

const IconNames = [
  ...HeartIconNames,
  ...ArrowIconNames,
  ...EducationIconNames,
  ...PeopleIconNames,
  ...CommerceIconNames,
  ...TimeIconNames,
  ...SystemDeviceIconNames,
  ...CommunicationNames,
  ...MathFinanceNames,
];

export { IconNames, IconSet };
