import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import Vue from 'vue';

Vue.use(
  FloatingVue, // Config
  {
    themes: {
      'info-dropdown': {
        triggers: ['click'],
        autoHide: true,
        placement: 'bottom',
      },
      'filter-dropdown': {
        $extend: 'dropdown',
        $resetCss: true,
        triggers: ['click'],
        autoHide: true,
        placement: 'bottom',
        strategy: 'absolute',
      },
      // 'home-tooltip': {
      //   triggers: ['click'],
      //   autoHide: true,
      //   placement: 'bottom',
      // },
    },
  },
);
