
/**
 * @description
 * 로드맵 아이템 > 총 x개 & 진행도
 */
export default {
  name: 'CourseCurriculum',
  props: {
    totalClassCount: { type: Number, default: 0 },
    progress: { type: Number, default: -1 },
  },
  computed: {
    showProgress() {
      return this.progress > -1;
    },
  },
};
