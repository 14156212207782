const getDateISOString = date => {
  // Date 인스턴스를 받아서 yyyy-MM-dd 형태의 스트링을 만듦
  if (date instanceof Date) {
    const y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? `0${m}` : m;
    let d = date.getDate();
    d = d < 10 ? `0${d}` : d;
    return `${y}-${m}-${d}`;
  } else {
    return '';
  }
};

const getDateString = date => {
  // Date 인스턴스를 받아서 yyyy년 MM월 dd일 오전|오후 hh:mm 형태의 스트링을 만듦
  if (date instanceof Date) {
    const hour = date?.getHours() || 0;
    const minute = date?.getMinutes() || 0;
    const minutes = minute.toString().padStart(2, '0');
    const timeString =
      hour < 12
        ? `오전 ${hour}:${minutes}`
        : `오후 ${hour === 12 ? 12 : hour - 12}:${minutes}`;

    return `${date.getFullYear()}년 ${
      date.getMonth() + 1
    }월 ${date.getDate()}일 ${timeString}`;
  } else {
    return '';
  }
};

export { getDateISOString, getDateString };
