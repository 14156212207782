
/**
 * @description
 * Product Item의 타이틀 컴포넌트
 */
export default {
  name: 'ProductItemTitle',
  props: {
    title: { type: String, default: null },
  },
};
