export const actions = {
  // 시작시간이 가장 가까운 스케줄 하나를 받아옵니다
  getUpcomingSchedule() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/schedule/upcoming`)
        .then(res => {
          resolve(res.data);
        })
        .catch(reject);
    });
  },
};
