const WORDPRESS_API = process.env.WP_URL;

export const actions = {
  getCategories() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${WORDPRESS_API}/categories`, {
          params: {
            _fields: 'id,name,slug,description,parent',
            per_page: 100,
          },
        })
        .then(res => resolve(res))
        .catch(reject);
    });
  },
  getCategory(_, { slug }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${WORDPRESS_API}/categories?slug=${encodeURIComponent(slug)}`, {
          params: {
            _fields: 'id,name,slug,description,parent',
          },
          headers: {},
        })
        .then(res => {
          resolve(res[0]);
        })
        .catch(reject);
    });
  },
  getBlogs(_, { page, limit, categoryId }) {
    const categories =
      categoryId !== undefined ? { categories: categoryId } : {};
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`${WORDPRESS_API}/posts`, {
          params: {
            _fields:
              'id,title,slug,excerpt,date,featured_media,jetpack_featured_media_url,categories',
            page,
            per_page: limit,

            ...categories,
          },
          headers: {},
        })
        .then(({ data, headers }) => {
          resolve({
            data,
            total: headers['x-wp-total'],
            totalPages: headers['x-wp-totalpages'],
          });
        })
        .catch(reject);
    });
  },
  getBlog(_, { slug }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${WORDPRESS_API}/posts?slug=${encodeURIComponent(slug)}`, {
          params: {
            _fields:
              'id,title,content,yoast_head_json,date,_embedded,_links,jetpack_featured_media_url',
            _embed: 'wp:term',
          },
          headers: {},
        })
        .then(res => {
          resolve(res[0]);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 가격대별 BEST 아이템
   */
  async getBestByPriceClassList(_, { minPrice, maxPrice }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/best-by-price`,
        { params: { minPrice, maxPrice } },
      );
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
