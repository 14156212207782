import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';

// 홈 화면 > 학습 탭 스토어
const initState = () => {
  return {
    fixedCurationList: [],
  };
};

export const state = () => initState();

export const getters = {
  fixedCurationList: state => state.fixedCurationList,
};

export const actions = {
  /**
   * @description
   * 홈 > 학습 > 단기 | 중장기 학습 큐레이션 api
   * type curationType =  'SHORT' | 'LONG'
   * https://dev-api.gguge.com/v3/class/swagger-ui/index.html#/%EC%88%98%EC%97%85/GetClassCurationByCurriculumDurationControllerTest
   */
  async getCurriculumCuration(_, { curationType = 'SHORT' }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/curriculum-curation`,
        {
          params: { curationType },
        },
      );

      return convertV3ClassToV2Product(data);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 학습 > 카테고리 리스트
   * 학습 카테고리뿐 아니라 전체 카테고리를 가져옵니다.
   */
  async getCategoryList(_) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category`,
      );

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  SET_FIXED_CURATION(state, curationList) {
    state.fixedCurationList = curationList;
  },
};
