
export default {
  name: 'ProgressSpinner',
  props: {
    size: { type: Number, default: 48 },
    borderSize: { type: Number, default: 5 },
    borderColor: { type: String, default: '#e0e0e0' },
    spinnerColor: { type: String, default: '#4C82EE' },
  },
  computed: {
    style() {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        border: `${this.borderSize}px solid ${this.borderColor}`,
        'border-bottom': `${this.borderSize}px solid ${this.spinnerColor}`,
      };
    },
  },
};
