const initMeta = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};

function teacherSearchKeyMaker({ keyword }) {
  const query = {};
  if (keyword && keyword.length > 0) {
    query.keyword = keyword;
  }
  return query;
}

export const state = () => ({
  keyword: '',
  keywordInput: '',
  searchedTeacherList: [],
  searchedUTCHour: -1,
  sortSalt: 0,
  currentSearchedTeacherIndex: -1,
  loadState: {
    peding: false,
    error: false,
    errorMessage: '',
  },
});

export const getters = {
  keyword: state => state.keyword,
  keywordInput: state => state.keywordInput,
  teacherSearchQueryFromFilter: state => {
    const returnQuery = {};

    if (state.keyword && state.keyword.length > 0)
      returnQuery.keyword = state.keyword;

    return returnQuery;
  },
  searchedTeachers: state => {
    if (state.currentSearchedTeacherIndex > -1) {
      return state.searchedTeacherList[
        state.currentSearchedTeacherIndex
      ].teacherList.map((t, i) => {
        return {
          ...t,
          positionIdx: i,
        };
      });
    } else {
      return [];
    }
  },
  meta: state => {
    if (state.currentSearchedTeacherIndex > -1) {
      return state.searchedTeacherList[state.currentSearchedTeacherIndex].meta;
    } else {
      return Object.assign({}, initMeta);
    }
  },
  noResult: state => {
    if (state.currentSearchedTeacherIndex > -1) {
      return state.searchedTeacherList[state.currentSearchedTeacherIndex]
        .noResult;
    } else {
      return false;
    }
  },
  noMoreSearch: state => {
    if (state.currentSearchedTeacherIndex > -1) {
      return state.searchedTeacherList[state.currentSearchedTeacherIndex]
        .noMoreSearch;
    } else {
      return false;
    }
  },
  loadState: state => state.loadState,
};

export const actions = {
  // 페이지 진입 or 신규 검색 시 isStartSearch true로 들어옵니다
  async searchTeacher({ state, commit }, { isStartSearch }) {
    // 검색 후 목록 정렬과 검색 페이지 기준을 잡기 위한 기본 세팅
    if (state.searchedUTCHour < 0 || state.sortSalt === 0) {
      commit('SET_SEARCH_SORT_SALTS');
    }

    // 에러 상태라면 api 요청을 보내지 않음
    if (state.loadState.error) return;

    // loadState 생성 & 요청중 상태로 변경
    const loadState = { pending: true, error: false, errorMessage: '' };
    commit('SET_LOAD_STATE', loadState);

    let searchedListIndex = -1;
    // 페이지 진입 시 동작하는 api라면
    if (isStartSearch) {
      const teacherSearchKey = teacherSearchKeyMaker({
        keyword: state.keyword,
      });
      const teacherSearchKeyJSON = JSON.stringify(teacherSearchKey);

      // 쿼리키값으로 검색한 적이 있는지 판단
      searchedListIndex = state.searchedTeacherList.findIndex(
        t => t.teacherSearchKey === teacherSearchKeyJSON,
      );

      // 검색한 적이 있는 결과라면 해당 데이터를 바라보게 해주고 빠른 종료
      if (searchedListIndex > -1) {
        commit('SET_CURRENT_SEARCHED_INDEX', searchedListIndex);
        loadState.pending = false;
        commit('SET_LOAD_STATE', loadState);
        return;
      } else {
        // 키가 없다면(검색한 적이 없는 쿼리라면) 새 검색결과 오브젝트를 만들고 meta데이터를 받아와 기본 세팅을 해준 후 그 위치를 바라보게 해줍니다
        const firstRes = await this.$axios.$get(`/v2.1/search/teacher`, {
          params: {
            ...teacherSearchKey,
            page: 1,
            limit: 1,
          },
        });
        if (firstRes.error) {
          loadState.error = true;
          commit('SET_LOAD_STATE', loadState);
          return;
        } else {
          commit('ADD_NEW_TEACHER_LIST', {
            teacherSearchKey,
            meta: firstRes.data.meta,
          });
        }
      }
    }

    // 바라보고 있는 데이터
    const currentSearchedTeacher =
      state.searchedTeacherList[state.currentSearchedTeacherIndex];

    // 더 검색할 데이터가 없다면 리턴
    if (currentSearchedTeacher.noResult || currentSearchedTeacher.noMoreSearch)
      return;

    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2.1/search/teacher`, {
          params: {
            ...JSON.parse(currentSearchedTeacher.teacherSearchKey),
            page: currentSearchedTeacher.meta.currentPage + 1,
            limit: currentSearchedTeacher.meta.itemsPerPage,
          },
        })
        .then(res => {
          if (!res.error) {
            loadState.pending = false;
            commit('SET_LOAD_STATE', loadState);
            commit('SET_SEARCHED_TEACHER_LIST', {
              res,
              teacherSearchKey: currentSearchedTeacher.teacherSearchKey,
            });
            resolve(res);
          } else {
            loadState.pending = false;
            loadState.error = true;
            loadState.errorMessage = '오류가 발생했습니다.';
            commit('SET_LOAD_STATE', loadState);
          }
        })
        .catch(e => {
          loadState.pending = false;
          loadState.error = true;
          loadState.errorMessage = '오류가 발생했습니다.';
          commit('SET_LOAD_STATE', loadState);
          reject(e);
        });
    });
  },
};

export const mutations = {
  SET_TEACHER_FILTER_FROM_QUERY(state, query) {
    state.keyword = query.keyword || '';
  },
  SET_SEARCH_SORT_SALTS(state) {
    const todayDate = new Date();
    state.searchedUTCHour = todayDate.getUTCHours() + 1; // 0일 수 있으니 +1한 값으로
    state.sortSalt = todayDate.getUTCDate() % 2 === 0 ? -1 : 1;
  },
  SET_LOAD_STATE(state, { pending, error, errorMessage }) {
    state.loadState = {
      pending,
      error,
      errorMessage,
    };
  },
  SET_CURRENT_SEARCHED_INDEX(state, currentSearchedIndex) {
    state.currentSearchedTeacherIndex = currentSearchedIndex;
  },
  SET_SEARCHED_TEACHER_LIST(state, { res, teacherSearchKey }) {
    if (state.currentSearchedTeacherIndex < 0 || !res || !res.data) {
      state.loadState.error = true;
      return;
    }

    const items = res.data.items;
    const meta = res.data.meta;
    // 현재 바라보고있는 데이터의 쿼리키
    const currentTeacherSearchKey =
      state.searchedTeacherList[state.currentSearchedTeacherIndex]
        .teacherSearchKey;

    // 바라보고 있는 쿼리키와 찾고있는 쿼리키가 같다면 그 위치에, 만약 다르다면 해당 인덱스 값을 찾아서 그 위치에 넣어줍니다.
    const currentDataIndex =
      currentTeacherSearchKey === teacherSearchKey
        ? state.currentSearchedTeacherIndex
        : state.searchedTeacherList.findIndex(
            t => t.teacherSearchKey === JSON.stringify(teacherSearchKey),
          );

    if (currentDataIndex > -1) {
      const currentData = state.searchedTeacherList[currentDataIndex];
      if (!currentData) {
        return;
      }

      // 오늘이 짝수일이냐 홀수일이냐에 따라 역순, 정순를 번경해 정렬하고 그 데이터를 리스트에 넣어줍니다
      const sortedItems = items.sort(
        (a, b) =>
          (a.id % state.searchedUTCHour) * state.sortSalt -
          (b.id % state.searchedUTCHour) * state.sortSalt,
      );
      currentData.teacherList.push(...sortedItems);
      currentData.meta = meta;

      // 현재 불러온 페이지와 이 쿼리로 검색한 시작 페이지가 동일하다면 더 이상 검색할 아이템이 없다고 판단합니다
      if (meta.currentPage === currentData.startPage) {
        currentData.noMoreSearch = true;
        return;
      }

      // 마지막 페이지에 도달
      if (meta.currentPage === meta.totalPages) {
        // 아직 더 불러올 아이템이 남아있다면 currentPage를 0으로 돌려 첫 페이지부터 검색하게 합니다
        if (
          meta.totalItems >=
          currentData.teacherList.length + meta.itemsPerPage
        ) {
          currentData.meta.currentPage = 0;
        } else {
          // 불러올 아이템이 없다면 더 이상 검색할 수 없게 noMoreSearch를 true로 바꿉니다
          currentData.noMoreSearch = true;
        }
      }
    }
  },
  SET_NO_RESULT(state, flag) {
    state.searchedTeacherList[state.currentSearchedTeacherIndex].noResult =
      flag;
  },
  SET_NO_MORE_SEARCH(state, flag) {
    state.searchedTeacherList[state.currentSearchedTeacherIndex].noMoreSearch =
      flag;
  },
  // 새 리스트 추가, index를 추가한 리스트에 맞춰줍니다
  ADD_NEW_TEACHER_LIST(state, { teacherSearchKey, meta }) {
    const totalPages =
      meta.totalItems === 0
        ? 0
        : Math.floor(meta.totalItems / initMeta.itemsPerPage) || 1;
    const maxPage = Math.floor(meta.totalItems / initMeta.itemsPerPage);
    const startPage = maxPage <= 0 ? 0 : maxPage % (state.searchedUTCHour || 1);

    state.searchedTeacherList.push({
      teacherSearchKey: JSON.stringify(teacherSearchKey),
      teacherList: [],
      meta: {
        currentPage: startPage,
        itemCount: initMeta.itemsPerPage,
        itemsPerPage: initMeta.itemsPerPage,
        totalItems: meta.totalItems,
        totalPages,
      },
      startPage,
      noMoreSearch: false,
      noResult: meta.totalItems === 0,
    });

    // index를 마지막 추가한 리스트를 바라볼 수 있게 맞춰주기
    state.currentSearchedTeacherIndex = state.searchedTeacherList.length - 1;
  },
  CHANGE_KEYWORD_INPUT(state, keywordInput) {
    state.keywordInput = keywordInput;
  },
  RESET_KEYWORD_INPUT(state) {
    state.keywordInput = '';
  },
  SET_KEYWORD_TO_KEYWORD_INPUT(state) {
    state.keyword = state.keywordInput;
  },
};
