/**
 * @description
 * @param {*} str number로 바꿀 string 값
 * @param {*} defaultValue 빈 값을 보냈거나, 형 변환 오류 발생시 뱉을 기본값
 * @returns Number || defaultValue
 */
export const stringToNumber = (str, defaultValue = undefined) => {
  // 빈값이면 얼리리턴
  if (!str) return defaultValue;

  try {
    // str값이면 Number로 변환, 아니라면 그값 그대로
    const returnNumber = typeof str === 'string' ? parseInt(str) : str;

    // 형 변환 이후 isNaN으로 변환되었다면 디폴트값으로 반환
    return isNaN(returnNumber) ? defaultValue : returnNumber;
  } catch (e) {
    // 에러 발생시 디폴트값
    return defaultValue;
  }
};
