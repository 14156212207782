
/**
 * @description
 * 공용 화살표 버튼
 */

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'ArrowBtn',
  components: { Icon },
  props: {
    direction: { type: String, required: false, default: 'left' },
    disabled: { type: Boolean, required: false, default: false },
    btnSize: { type: Number, required: false, default: 22 },
  },
  data: () => {
    return {
      left: 'caretLeft',
      right: 'caretRight',
      up: 'caretUp',
      down: 'caretDown',
    };
  },
  computed: {
    arrowIcon() {
      switch (this.direction) {
        case 'left':
          return this.left;
        case 'right':
          return this.right;
        case 'up':
          return this.up;
        case 'down':
          return this.down;
        default:
          return this.left;
      }
    },
  },
  methods: {
    onClickArrow() {
      this.$emit('click-arrow');
    },
  },
};
