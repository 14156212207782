
import { mapGetters } from 'vuex';
import { numberToCommaString } from '@/utils/numberString';

export default {
  name: 'DrawerReviewTooltip',
  computed: {
    ...mapGetters('user/review', ['reviewReward']),
    rewardString() {
      return numberToCommaString(this.reviewReward);
    },
  },
};
