import { CLICK_SIGNUP } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import {
  removeInitiateEventSlug,
  setInitiateEventSlug,
  setLastPath,
} from '@/utils/localStorageUtils.js';
import Vue from 'vue';
import { mapMutations } from 'vuex';

/* eslint-disable */
export default ({ store, app, route }, inject) => {
  inject('callLogin', goTo => {
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.logSignUpEvent(null);
      store.commit('RESET_LOGIN_MODAL');
      store.commit('SET_LOGIN_MODAL', true);
      store.commit('SET_LOGIN_PROPERTIES', { currentPath: goTo });
      return;
    } else {
      if (goTo) {
        app.router.push(goTo);
      } else {
        app.router.push({ name: route.name, params: route.params });
      }
    }
  });

  if (!Vue.__kakao_mixin_) {
    Vue.__kakao_mixin__ = true;

    if (typeof Kakao !== 'undefined') Kakao.init(process.env.KAKAO_KEY);

    Vue.mixin({
      methods: {
        ...mapMutations({
          setLoginModal: 'SET_LOGIN_MODAL',
          setLoginProperties: 'SET_LOGIN_PROPERTIES',
          resetLoginModal: 'RESET_LOGIN_MODAL',
        }),
        /**
         * @description
         * 정의된 로그인 버튼을 누르는 액션을 잡는데 사용하는 이벤트
         * 모든 곳에서 사용하지 않고 따로 잡고싶은 버튼과 event페이지에서 쓰이는 callLogin에서 사용합니다.
         *
         * @param {string} section 로그인 버튼중 이름이 정의된 경우 section값으로 보내줍니다
         */
        logSignUpEvent(section) {
          // 지금 route가 event페이지라면 slug를 뽑아냅니다.
          const eventSlug =
            this.$route.name === 'event-slug'
              ? this.$route.params?.slug
              : undefined;

          // log param에 section이 우선순위입니다.
          const amplitudeLogObject = section
            ? { section }
            : { event_slug: eventSlug };

          // 섹션이 있으면 이벤트슬러그를 지우고, 그 외라면 localStorage에 넣어 회원가입 로깅에 쓸 수 있게 합니다.
          !section && eventSlug
            ? setInitiateEventSlug(eventSlug)
            : removeInitiateEventSlug();

          const utmQuery = this.getUtmQuery();
          Object.assign(amplitudeLogObject, utmQuery);

          this.logUserCustomEvent(CLICK_SIGNUP, amplitudeLogObject);
        },
        // 로그인이 필요하면 호출되는 메소드. 로그인 모달을 띄웁니다
        requestToken({ section, ...restProperties }) {
          // signup 페이지로 Redirect 될 때 NuxtPath 값이 사라져서 따로 저장
          setLastPath(this.$route?.matched[0].path || '/');
          this.resetLoginModal();
          this.setLoginModal(true);
          this.setLoginProperties({ section, ...restProperties });
        },
        callKakaoAPI(callback) {
          // kakaoSDK가 붙어있고, KEY가 정상적으로 세팅되어있다면 callback을 실행하고 종료
          if (window.Kakao?.isInitialized()) {
            // 주의할점: 카카오 sdk가 붙기 전에는 Kakao를 찾으면 not defined에러 발생해서 window에서 찾음
            callback();
            return;
          }

          // 그 외 케이스
          // 1. 카카오 sdk자체가 없음
          if (typeof Kakao === 'undefined') {
            const kakaoSDKScript = document.createElement('script');
            kakaoSDKScript.src =
              'https://t1.kakaocdn.net/kakao_js_sdk/2.3.0/kakao.min.js';
            kakaoSDKScript.type = 'text/javascript';
            kakaoSDKScript.addEventListener('load', () => {
              Kakao.init(process.env.KAKAO_KEY);
              callback();
            });

            // document에 붙이고 콜백으로 실행하게.
            document.head.appendChild(kakaoSDKScript);
          } else {
            // 2. sdk는 붙어있으나 키를 넣지 않음. 아마 발생하지 않을 케이스.
            Kakao.init(process.env.KAKAO_KEY);
            callback();
          }
        },
        requestKakaoToken({ currentPath }) {
          this.callKakaoAPI(() =>
            Kakao.Auth.authorize({
              redirectUri: `${window.location.origin}/api/kakaoAuth`,
              state: JSON.stringify({
                origin: window.location.origin,
                currentPath: currentPath ? currentPath : this.$route.path,
              }),
            }),
          );
        },
        requestKakaoTokenOtherAccount({ currentPath }) {
          this.callKakaoAPI(() =>
            Kakao.Auth.authorize({
              redirectUri: `${window.location.origin}/api/kakaoAuth`,
              prompt: 'login', // 기존 로그인 여부와 상관없이 로그인 요청
              state: JSON.stringify({
                origin: window.location.origin,
                currentPath: currentPath ? currentPath : this.$route.path,
              }),
            }),
          );
        },
        requestKakaoUser() {
          return Kakao.API.request({
            url: '/v2/user/me',
            data: {
              property_keys: [
                'kakao_account.profile',
                'kakao_account.email',
                'kakao_account.phone_number',
              ],
            },
          });
        },
        requestKakaoUserTerms() {
          return Kakao.API.request({
            url: '/v2/user/service_terms',
          });
        },
        addKakaoChannel() {
          return new Promise(resolve => {
            Kakao.Channel.addChannel({
              channelPublicId: '_suNnK',
            });
            resolve();
          });
        },
      },
    });
  }
};
