export const actions = {
  changeChatSetting(_, bool) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`${process.env.API_V3}/v3/teacher/chat-settings`, {
          data: {
            onlyCustomer: bool,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
};
