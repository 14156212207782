
import {
  GGUGE_CHANNEL_LINK,
  GGUGE_HELP_CENTER_KIDS,
} from '@/utils/linkPath.js';

export default {
  name: 'CustomerService',
  data() {
    return {
      customerCenterOpenTime:
        '평일 10:00~19:00 (점심 시간 12:30~13:30, 주말/공휴일 미운영)',
      customerCenterPhones: '상담문의 1544-9917',
      customerService: [
        { label: '채팅상담', to: GGUGE_CHANNEL_LINK },
        { label: '고객지원센터', to: GGUGE_HELP_CENTER_KIDS },
      ],
    };
  },
};
