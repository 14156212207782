function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// function validatePhoneNumber(phoneNumber) {
//   // const regExp = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
//   // 11자리 미만 입력의 경우에는 false
//   if (phoneNumber.length === 11) {
//     const regExp = /^01([0|1|6|7|8|9]?)-?([0-9]{4})-?([0-9]{4})$/;
//     return regExp.test(phoneNumber);
//   } else {
//     return false;
//   }
// }

// 10자리 연락처를 받아야 할 경우 [0-9]{3,4} 를 사용
function validatePhoneNumber(phoneNumber) {
  const ph1 = /^\+82\s1([0|1|6|7|8|9]?)-?([0-9]{4})-?([0-9]{4})$/;
  const ph2 = /^01([0|1|6|7|8|9]?)-?([0-9]{4})-?([0-9]{4})$/;
  return ph1.test(phoneNumber) || ph2.test(phoneNumber);
}

function removeSpecialChars(string) {
  // eslint-disable-next-line
  const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
  return string.replace(regExp, ' ');
}

/**
 * @description 내가 보낸 메시지 중복 체크
 */
function isDuplicatedMessage(oldTodayMessageList, newMessage) {
  if (!newMessage) return false;

  // 오늘 보낸 메시지 중에 중복된 메시지가 있고, 1초 이내에 보낸 메시지면 중복으로 판단
  const dupMessage = oldTodayMessageList.find(
    ({ message, createdAt }) =>
      message === newMessage.message &&
      newMessage.createdAt <= createdAt + 1000,
  );
  if (dupMessage) return true;
  return false;
}

export {
  validateEmail,
  validatePhoneNumber,
  removeSpecialChars,
  isDuplicatedMessage,
};
