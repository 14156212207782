const _BRIDGE_METHOD = {
  ORDER_COMPLETED: 'orderCompleted', // 완료 페이지에서 웹뷰를 닫고 스케줄 페이지 이동
  SCHEDULE_DETAIL: 'moveToScheduleDetail', // 스케줄 상세로 이동
  DISMISS: 'dismiss', // 에러페이지 등에서 단순히 웹뷰를 닫아야할 때
};

/**
 * @description
 * ios앱뷰 브릿지를 찾아 postMessage에 jsonObject를 넘겨줍니다
 * @param {string} jsonObject
 */
const _sendIOSBridge = jsonObject => {
  try {
    window.webkit?.messageHandlers?.GgugeAppiOS?.postMessage(jsonObject);
  } catch (e) {
    return false;
  }
};

/**
 * @description
 * aos앱뷰 브릿지를 찾아 postMessage에 jsonObject를 넘겨줍니다
 * @param {string} jsonObject
 */
const _sendAOSBridge = jsonObject => {
  try {
    window.GgugeAppAOS?.postMessage(jsonObject);
  } catch (e) {
    return false;
  }
};

// ios와 aos 각각 필요한 데이터로 보냄
const _sendAppBridge = ({ jsonObject }) => {
  _sendIOSBridge(jsonObject);
  _sendAOSBridge(jsonObject);
};

/**
 * @description
 * 결제 완료 페이지 > 확인버튼 클릭시
 * 앱은 스케줄 보러가기 페이지로 이동해야함
 */
export const sendBridgeOrderCompleted = () => {
  const method = _BRIDGE_METHOD.ORDER_COMPLETED;

  const sendObj = {
    method,
  };

  _sendAppBridge({ jsonObject: JSON.stringify(sendObj) });
};

/**
 * @description
 * 결제 완료 페이지 > 스케줄 보러가기
 * @param {string} scheduleCode 결제승인 후 오는 앱에서 쓰이는 scheduleCode ex) SUBSCRIBE-5966-194357-16461
 * @param {string} childrenId 결제승인 후 오는 결제한 아이 ID
 * @param {string} productId 결제승인 후 오는 결제한 수업 ID
 */
export const sendBridgeScheduleDetail = ({
  scheduleCode,
  childrenId,
  productId,
}) => {
  const method = _BRIDGE_METHOD.SCHEDULE_DETAIL;

  const sendObj = {
    method,
    scheduleCode,
    childrenId,
    productId,
  };

  _sendAppBridge({ jsonObject: JSON.stringify(sendObj) });
};

/**
 * @description
 * 웹뷰 브릿지 탈출용 메소드. 단순 웹뷰 창 닫기 기능만
 * 오류페이지 확인 버튼 등에 쓰임
 */
export const sendBridgeDismiss = () => {
  const method = _BRIDGE_METHOD.DISMISS;

  const sendObj = {
    method,
  };

  _sendAppBridge({ jsonObject: JSON.stringify(sendObj) });
};
