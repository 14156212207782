/**
 * @description
 * 앰플리튜드에 찍히는 프로퍼티중 일부
 */

const SUBS_CANCEL_OPTION = {
  keep: 'keep',
  wait: 'wait',
  refund: 'spot-refund',
  cancel: 'spot',
};

const SUBS_STATUS = {
  subscribe: 'subscribe',
  cancelWait: 'cancel_wait',
  cancel: 'cancel',
};

const GGUGE_MALL_COMPONENT = {
  product: 'product',
  paymentForm: 'payment_form',
  footer: 'footer',
};

const SHARE_TYPE = {
  KAKAO: 'kakao',
  LINK: 'link',
};

const SHARE_CONTENT = {
  TEACHER: 'teacher',
  FRIEND_INVITE: 'friend_invite',
  PRODUCT: 'product',
};

const NAV_MENU = {
  CALENDAR: 'calendar',
  ALARM: 'alarm',
  CHAT: 'chat',
  DRAWER: 'drawer',
  CLOSE: 'close',
  LOGIN: 'navigation_login',
  SIGN_UP: 'navigation_signup',
};

const FOOTER_TABBAR = {
  HOME: 'home',
  SEARCH: 'search',
  CATEGORY: 'category',
  SCHEDULE: 'schedule',
  MY_INFO: 'myinfo',
  TABBAR_SCHEDULE: 'tabbar_schedule',
  TABBAR_MY_INFO: 'tabbar_myinfo',
};

const ATTEND_CLASS = {
  HOME: 'home',
  MY_SCHEDULE: 'myschedule',
};

const APP_DOWNLOAD_STORE = {
  PLAY_STORE: 'google_play',
  APP_STORE: 'app_store',
};

const HOME_TYPE = {
  TALENT: 'talent',
  EDUCATION: 'education',
};

Object.freeze(GGUGE_MALL_COMPONENT);
Object.freeze(SUBS_CANCEL_OPTION);
Object.freeze(SUBS_STATUS);
Object.freeze(SHARE_TYPE);
Object.freeze(SHARE_CONTENT);
Object.freeze(NAV_MENU);
Object.freeze(FOOTER_TABBAR);
Object.freeze(ATTEND_CLASS);
Object.freeze(APP_DOWNLOAD_STORE);
Object.freeze(HOME_TYPE);

export {
  APP_DOWNLOAD_STORE,
  ATTEND_CLASS,
  FOOTER_TABBAR,
  GGUGE_MALL_COMPONENT,
  HOME_TYPE,
  NAV_MENU,
  SHARE_CONTENT,
  SHARE_TYPE,
  SUBS_CANCEL_OPTION,
  SUBS_STATUS,
};
