
/**
 * @description
 * 로드맵 썸네일 위에 올라가는 찜하기(좋아요) 버튼
 */

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'CourseFavoriteButton',
  components: { Icon },
  props: {
    isFavoriteCourse: { type: Boolean, default: false },
  },
};
