
export default {
  name: 'FooterPartner',
  data() {
    return {
      customerCenterOpenTime: [
        '2017 카이스트 R&D 사업 선정',
        '2019 Start-up Awards 대표 창업팀 선정',
        '2021 대표이사 포브스 30인 선정',
        '2019 혁신 기술 창업팀 TIPS 선정',
        '2022 소비자브랜드대상 1위',
      ],
    };
  },
};
