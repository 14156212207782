
/**
 * @description
 * 상품 컴포넌트 > xxx명이 선택
 */

import { numberToCommaString } from '@/utils/numberString';

export default {
  name: 'CumulativeCount',
  props: {
    cumulativeCount: { type: Number, default: 0 },
  },
  data() {
    return {
      certificationIcon: require('@/assets/img/product-item/certification.svg'),
    };
  },
  computed: {
    cumulativeCountString: ({ cumulativeCount }) =>
      numberToCommaString(cumulativeCount),
  },
};
