
/**
 * @description
 * svg의 circle부분
 */
export default {
  name: 'SvgCircle',
  props: {
    circle: { type: Object, required: true },
  },
};
