
export default {
  name: 'Thumbnail',
  props: {
    thumbnailImg: { type: String, default: null },
    width: { type: Number, default: 300 },
    alt: { type: String, default: null },
  },
  data() {
    return {
      defaultImgUrl: '/images/development/default-thumbnail.jpg',
    };
  },
  computed: {
    thumbnailImgSrc: ({ thumbnailImg, defaultImgUrl, resizedImgUrl }) =>
      resizedImgUrl(thumbnailImg || defaultImgUrl),
  },
  methods: {
    onImageLoadFail(event) {
      event.target.src = this.resizedImgUrl(this.defaultImgUrl);
    },
    resizedImgUrl(imgUrl) {
      return this.$imgWrapper(imgUrl, this.width);
    },
  },
};
