export const state = () => ({
  eventList: [], // ongoing events
  eventPageDetail: {},
});

export const getters = {
  eventList: state => state.eventList,
  kakaoShareEventObject: state => {
    return {
      eventTitle: state.eventPageDetail.title,
      eventSlug: state.eventPageDetail.slug,
      thumbnailImg: state.eventPageDetail.thumbnailImg,
    };
  },
  eventSlug: state => state.eventPageDetail.slug,
};

export const actions = {
  // select ongoing events
  selectEventList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/event`)
        .then(res => {
          commit('SET_EVENT_LIST', res);
          const bannerList = [];
          const nowTime = new Date();

          for (const e of res) {
            // 사파리 new Date 이슈로 yyyy-mm-dd hh:mm:dd-> yyyy/mm/dd hh:mm:dd 로 replace
            const endDate = new Date(e.endDate.replace(/-/g, '/'));
            if (nowTime <= endDate) {
              if (e.banner && e.banner.imgBg)
                bannerList.push({ ...e.banner, slug: e.slug });
            }
          }

          bannerList.sort((a, b) => {
            const aw = a.weight ? parseInt(a.weight) : 0;
            const bw = b.weight ? parseInt(b.weight) : 0;
            if (aw < bw) return 1;
            if (aw > bw) return -1;
            return 0;
          });

          commit('SET_SLIDE_LIST', bannerList, { root: true });
          resolve(res);
        })
        .catch(reject);
    });
  },
  // select a event
  selectEvent(_, { slug }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/event/${slug}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  // 기존 Google AppScript (external/createAlarm) 대체 알림신청 메서드
  createAlarm(_, { slug, opt }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/event/${slug}/sign_up`, {
          optional_inputs: opt.toString(),
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_EVENT_LIST(state, arr) {
    state.eventList = arr;
  },
  SET_EVENT_DETAIL(state, event) {
    state.eventPageDetail = event;
  },
  RESET_EVENT_DETAIL(state) {
    state.eventPageDetail = {};
  },
};
