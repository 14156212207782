
/**
 * @description
 * 로드맵 > 로드맵에 포함된 클래스의 선생님들 프로필 사진 리스트
 */

import RoundAvatar from '@/components/common/img/RoundAvatar';
import DefaultAvatar from './DefaultAvatar.vue';

export default {
  name: 'TeacherImageList',
  components: { RoundAvatar, DefaultAvatar },
  props: {
    teacherImages: { type: Array, default: () => [] },
  },
};
