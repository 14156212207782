import SendbirdChat from '@sendbird/chat';
import {
  GroupChannelModule,
  GroupChannelHandler,
} from '@sendbird/chat/groupChannel';

export default (_, inject) => {
  const sb = SendbirdChat.init({
    appId: process.env.SENDBIRD_APP_ID,
    modules: [new GroupChannelModule()],
  });
  inject('sb', sb);

  /**
   * @description 센드버드 이벤트 리스너
   */
  inject('initSyncWithSendbird', async (sendbirdUserId, handler) => {
    const groupChannelHandler = new GroupChannelHandler(handler);
    await sb.groupChannel.addGroupChannelHandler(
      sendbirdUserId,
      groupChannelHandler,
    );
  });
};
