import { endOfMonth, startOfDay, startOfMonth } from 'date-fns';

const initState = {
  scheduleListByDates: [],
  startDate: null, // 현재 state.scheduleList 의 시작 시점. yyyy-MM-dd
  endDate: null, // 현재 state.scheduleList 의 종료 시점. yyyy-MM-dd
  todayDate: null,
  isPastCalendar: false,
  selectedTextTab: 'upcoming',
  initSelectedTabIndex: 0,
  scheduleFetchStatus: {
    isFetching: false,
    isError: false,
  },
  mainEventDates: new Map(),
  lastVisitedDate: null,
  tooltipText: null,
};

export const state = () => Object.assign({}, initState);

export const getters = {
  scheduleListByDates: state => state.scheduleListByDates,
  filteredScheduleList: state => {
    if (
      !state.scheduleListByDates ||
      typeof state.scheduleListByDates !== 'object'
    )
      return {};

    if (state.selectedTextTab === 'all') {
      return state.scheduleListByDates;
    } else {
      const startOfToday = startOfDay(state.todayDate);

      return Object.keys(state.scheduleListByDates)
        .filter(classDate => new Date(classDate) > startOfToday)
        .reduce(
          (res, key) =>
            Object.assign(res, {
              [key]: state.scheduleListByDates[key],
            }),
          {},
        );
    }
  },
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  todayDate: state => state.todayDate,
  isPastCalendar: state => state.isPastCalendar,
  selectedTextTab: state => state.selectedTextTab,
  initSelectedTabIndex: state => state.initSelectedTabIndex,
  scheduleFetchStatus: state => state.scheduleFetchStatus,
  mainEventDates: state => state.mainEventDates,
  lastVisitedDate: state => state.lastVisitedDate,
  tooltipText: state => state.tooltipText,
};

export const actions = {
  // startDate와 endDate 사이의 유저 스케줄을 날짜별로 묶어서 받아오는 api
  getUserScheduleByDates({ state, commit }, { startDate, endDate }) {
    // fetching  상태라면 호출하지 않게
    if (state.scheduleFetchStatus.isFetching) return;

    commit('SET_SCHEDULE_FETCH_STATUS', {
      isFetching: true,
      isError: false,
    });
    commit('RESET_MAIN_EVENT_DATES');
    commit('RESET_SCHEDULE_LIST');

    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/schedule`, {
          params: {
            startDate,
            endDate,
          },
        })
        .then(res => {
          commit('SET_SCHEDULE', {
            scheduleListByDates: res,
            startDate,
            endDate,
          });
          commit('SET_TODAY_DATE');
          commit('SET_CALENDAR_DATA');
          commit('SET_MAIN_EVENT_DATES');
          commit('SET_SCHEDULE_FETCH_STATUS', {
            isFetching: false,
          });
          resolve(res);
        })
        .catch(e => {
          commit('SET_SCHEDULE_FETCH_STATUS', {
            isFetching: false,
            isError: true,
          });
          console.error(e);
          reject(e);
        });
    });
  },
};

export const mutations = {
  RESET_CALENDAR(state) {
    Object.assign(state, initState);
  },
  RESET_SCHEDULE_LIST(state) {
    state.scheduleListByDates = [];
  },
  SET_SCHEDULE_FETCH_STATUS(state, fetchStatus) {
    state.scheduleFetchStatus = {
      ...state.scheduleFetchStatus,
      ...fetchStatus,
    };
  },
  SET_SCHEDULE(state, { scheduleListByDates, startDate, endDate }) {
    state.scheduleListByDates = scheduleListByDates;
    state.startDate = startDate;
    state.endDate = endDate;
  },
  SET_TODAY_DATE(state) {
    state.todayDate = new Date();
  },
  RESET_MAIN_EVENT_DATES(state) {
    state.mainEventDates = new Map();
  },
  SET_MAIN_EVENT_DATES(state) {
    const mainEventDates = new Map();

    Object.entries(state.scheduleListByDates).forEach(scheduleList => {
      const [key, schedules] = scheduleList;

      mainEventDates.set(key, {
        isMainEvent: true,
        eventText: `총 ${schedules.length}개의 수업`,
      });
    });

    state.mainEventDates = mainEventDates;
  },
  SET_CALENDAR_DATA(state) {
    const monthStartDate = startOfMonth(new Date(state.startDate));
    const monthEndDate = endOfMonth(new Date(state.endDate));

    if (state.todayDate < monthStartDate) {
      state.selectedTextTab = 'upcoming';
      state.initSelectedTabIndex = 0;
      state.isPastCalendar = false;
    } else if (state.todayDate > monthEndDate) {
      state.selectedTextTab = 'all';
      state.initSelectedTabIndex = 1;
      state.isPastCalendar = true;
    } else {
      state.selectedTextTab = 'upcoming';
      state.initSelectedTabIndex = 0;
      state.isPastCalendar = false;
    }
  },
  SELECT_TAB(state, { tabValue, tabIndex }) {
    state.selectedTextTab = tabValue;
    state.initSelectedTabIndex = tabIndex;
  },
  SET_LAST_VISITED_DATE(state, { lastVisitedDate }) {
    state.lastVisitedDate = lastVisitedDate;
  },
  SET_EVENT_TOOLTIP_TEXT(state, { tooltipText }) {
    state.tooltipText = tooltipText;
  },
};
