/**
 * @description
 * 유저의 카드에 관련된 스토어
 */
export const state = () => ({
  cardList: [],
  selectedCard: null,
  subsCard: null, // 구독 스케줄 카드 세팅
  subscription: null,
});

export const getters = {
  cardList: state => state.cardList,
  // 사용 가능한 카드가 있는지 체크
  hasUsableCard: state => {
    if (state.cardList.length <= 0) return false;

    return state.cardList.filter(c => c.usable === true).length > 0;
  },
  hasInicisCard: state => {
    if (state.cardList.length <= 0) return false;

    return state.cardList.findIndex(c => c.pgType === 'INICIS') > -1;
  },
  // 선택된 카드
  selectedCard: state => state.selectedCard,
  // 선택된 카드 아이디
  selectedCardId: state => state.selectedCard?.id,
  // 구독페이지 카드
  subsCard: state => state.subsCard,
  subscription: state => state.subscription,
};

export const actions = {
  /**
   * @description
   * 토스: 빌링 카드 등록
   * 토스에서 받아온 authKey로 꾸그 서버에 빌링 카드를 등록합니다
   * @param {string} authKey 토스에서 받아온 인증키
   * @param {string} customerKey 꾸그 유저 ID
   * @returns
   */
  addBillingKey(_, { authKey, customerKey }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/payment/toss/billing/key`, {
          authKey,
          customerKey,
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 유저 카드 리스트를 받아와 스토어에 등록
   */
  getUserCardList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/card`)
        .then(res => {
          commit('SET_USER_CARD_LIST', res?.data);
          resolve(res?.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 유저가 자주 사용하는 카드 변경 API
   * @param {number} cardId
   */
  changeFavoriteCard(_, { cardId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`${process.env.API_V3}/v3/user/card/${cardId}/use`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 등록되어있는 유저의 카드를 삭제하는 API
   * @param {number} cardId
   */
  deleteUserCard(_, { cardId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`${process.env.API_V3}/v3/user/card/${cardId}`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 구독중인 클래스의 갱신용 빌링카드를 변경하는 API
   * @param {number} subscribeId 변경하려는 구독 ID
   * @param {number} cardId 변경하려는 카드 ID
   */
  changeBillingCard(_, { subscribeId, cardId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`${process.env.API_V3}/v3/order/subscribe/${subscribeId}/card`, {
          cardId,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_USER_CARD_LIST(state, cardList) {
    state.cardList = cardList;

    if (cardList.length > 0) {
      state.selectedCard =
        cardList.find(c => c.usable === true && c.isUse === true) || null;
    } else {
      state.selectedCard = null;
    }
  },
  SELECT_CARD(state, card) {
    state.selectedCard = card;
  },
  // 구독중인 카드 세팅
  SET_SUB_CARD(state, card) {
    if (card) state.selectedCard = { id: card.id };
    else state.selectedCard = null;
  },
  SET_SUBS(state, subscription) {
    state.subscription = subscription;
  },
  SELECT_SUB_CARD(state, card) {
    state.subsCard = card;
  },
};
