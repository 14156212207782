import getApiQuery from '@/utils/filter/getApiQuery';

import { convertV3SearchClassToV2Product } from '@/utils/convertV3ToV2';

export const initMeta = {
  currentPage: 0,
  itemCount: 0,
  itemsPerPage: 20,
  totalItems: 0,
  totalPages: 0,
};
const initState = {
  searchList: [],
  searchListMeta: null,
  isLoading: false,
  buttonTypeFromProduct: undefined, // 'set_schedule' || 'same_schedule' || undefined
  activeBottomDrawerFilter: '', // 'schedule' || 'age' || 'multi'
  selectedAgeFilter: 'grade', // 'age' || 'grade'
  hasViewFilter: false, // 검색 필터에 보이는 버튼들이 있는지. <searchListSection /> 컴포넌트 margin-top 판단용.
};
export const state = () => Object.assign({}, initState);

export const getters = {
  // 검색 결과
  searchList(state) {
    return state.searchList;
  },
  // 총 검색 결과 갯수
  totalSearchCount(state) {
    return state.searchListMeta?.totalItems || 0;
  },
  // 현재 페이지
  currentPage(state) {
    return state.searchListMeta?.currentPage || 0;
  },
  // 총 페이지 수, 무한 스크롤 용
  totalPages(state) {
    return state.searchListMeta?.totalPages || 0;
  },
  // 검색 결과 없음
  isEmptySearchResult(state) {
    return state.searchListMeta?.totalItems === 0;
  },
  isLoading: state => {
    return state.isLoading;
  },
  buttonTypeFromProduct: state => state.buttonTypeFromProduct,
  hasViewFilter: state => state.hasViewFilter,
  activeBottomDrawerFilter: state => state.activeBottomDrawerFilter,
  selectedAgeFilter: state => state.selectedAgeFilter,
  isDateNAgeFilterActive: state => {
    return (
      state.activeBottomDrawerFilter === 'schedule' ||
      state.activeBottomDrawerFilter === 'age'
    );
  },
};

export const actions = {
  /**
   * @description
   * 검색 결과 없을 때 사용.
   */
  async fetchNofilterSearchList(store, meta) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/search/class`,
        {
          params: {
            listType: 'POPULARITY',
            page: meta?.currentPage + 1 || 1,
            size: meta.itemsPerPage,
          },
        },
      );

      return {
        meta: {
          totalItems: data.totalItems,
          totalPages: data.totalPages,
          currentPage: data.currentPage,
          itemsPerPage: data.itemsPerPage,
        },
        items: convertV3SearchClassToV2Product(data.items) || [],
      };
      // return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchSearchList({ state, commit }, query) {
    if (state.isLoading) return;
    commit('SET_IS_LOADING', true);

    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/search/class`,
        {
          params: {
            ...getApiQuery(query),
            page: state.searchListMeta?.currentPage + 1 || 1,
            size: initMeta.itemsPerPage,
          },
        },
      );
      commit('SET_SEARCH_LIST_META', {
        totalItems: data.totalItems,
        totalPages: data.totalPages,
        currentPage: data.currentPage,
        itemsPerPage: data.itemsPerPage,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  /**
   * @description
   * 구독만 취소 했을때 보여주는 큐레이션용 간단한 검색 api
   */
  async searchSubsProduct() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/search/class`,
        {
          params: {
            classType: 'SUBSCRIBE',
            order: `POPULARITY`,
            page: 1,
            size: 10,
          },
        },
      );
      return convertV3SearchClassToV2Product(data.items) || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};

export const mutations = {
  SET_SEARCH_LIST(state, list) {
    state.searchList = list;
  },
  SET_SEARCH_LIST_META(state, meta) {
    state.searchListMeta = meta;
  },
  // state 모두 초기화
  RESET_SEARCH(state) {
    Object.assign(state, { ...initState });
  },
  // flag = 'set_schedule' || 'same_schedule' || undefined
  SET_BUTTON_TYPE_FROM_PRODUCT(state, flag) {
    state.buttonTypeFromProduct = flag;
  },
  SET_HAS_ViEW_FIlTER(state, bool) {
    state.hasViewFilter = bool;
  },
  SET_IS_LOADING(state, bool) {
    state.isLoading = bool;
  },
  // activeFilter = 'schedule' || 'age' || 'multi'
  SET_ACTIVE_BOTTOM_DRAWER_FILTER(state, activeFilter) {
    state.activeBottomDrawerFilter = activeFilter;
  },
  // selectedAgeFilter = 'age' || 'grade'
  SET_SELECTED_AGE_FILTER(state, selectedAgeFilter) {
    state.selectedAgeFilter = selectedAgeFilter;
  },
};
