export const actions = {
  getUsableProductListForThisCoupon(_, { couponId, classType, page, limit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/coupon/usable-product`, {
          params: {
            couponId,
            classType,
            page,
            limit,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
};
