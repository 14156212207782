
/**
 * @description
 * 신규 홈 화면 기본 레이아웃
 */

import {
  getIsApp,
  setIsApp,
  setLastClickSection,
  setRecommendCode,
} from '@/utils/localStorageUtils.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import FloatButton from '@/components/common/btn/FloatButton';
import LoginModal from '@/components/common/modal/LoginModal.vue';
import AppNavFooter from '@/components/layout/AppNavFooter.vue';
import AppFooter from '@/components/layout/footer/AppFooter.vue';
import HeroBanner from '@/components/new-home/common/hero-banner/HeroBanner.vue';
import Upcoming from '@/components/new-home/common/upcoming/Upcoming.vue';
import HomeHeader from '@/components/new-home/layout/HomeHeader.vue';
import SignupNudge from '@/components/new-home/layout/SignupNudge.vue';
// import SignupSuccessEventPopup from '../components/common/SignupSuccessEventPopup.vue';

export default {
  components: {
    HomeHeader,
    LoginModal,
    // SignupSuccessEventPopup,
    AppNavFooter,
    AppFooter,
    HeroBanner,
    Upcoming,
    SignupNudge,
    FloatButton,
  },
  computed: {
    ...mapGetters(['openSignupSuccessEventPopup']),
    ...mapGetters('user', ['userChildrenAgesArrayForFilter', 'children']),
    isApp() {
      if (process.client) {
        return this.$route.query.isApp || getIsApp() === true;
      } else {
        return this.$route.query.isApp;
      }
    },
  },
  created() {
    // if (!this.slideList.length) {
    //   this.selectEventList();
    // }
  },
  mounted() {
    // 초기 remoteConfig 호출
    this.initConfig();

    if (this.$route.query.isApp) setIsApp();
    if (this.$route.query.invite) {
      setRecommendCode(this.$route.query.invite);
      this.setUserProperties({ invite: this.$route.query.invite }); // amplitude user property에도 저장
    }

    // 로그인이 된 유저면,
    if (this.$auth.loggedIn && this.$auth.user) {
      // 앰플리튜드에 프로퍼티 세팅
      this.setAmplitudeProperty();
    }
  },
  methods: {
    ...mapActions('config', ['initConfig']),
    // ...mapActions('event', ['selectEventList']),
    ...mapActions('user', ['selectChildren']),
    ...mapMutations('search/filter', ['SET_SELECTED_AGE']),
    clickLogEvent(e) {
      // dataset이 있고, 그 중에 apSection이 있다면 앰플에 잡고 싶은 section
      const sectionData = e
        .composedPath()
        .find(el => el.dataset && 'apSection' in el.dataset);
      if (sectionData) {
        const sectionName = sectionData.dataset.apSection;
        setLastClickSection(sectionName);
      }
    },
    async setAmplitudeProperty() {
      if (!this.$auth.loggedIn) return; // 수비코드

      const loginUserData = this.$auth.user;
      // 여러번 호출해도 앰플리튜드 내에서 한 번 만 저장되야하는 프로퍼티
      const ampOnceUserProperties = {
        first_favorite_date: loginUserData.meta?.firstFavoriteDate || undefined,
      };

      // 매 호출마다 최신 데이터로 갱신해도 상관없는 유저 데이터
      const ampUserProperties = {
        'user id': this.$auth.user.id,
        first_purchased_date:
          loginUserData.meta?.firstPurchaseDate || undefined,
        registered_date: loginUserData.createAt || undefined,
      };

      if (!this.userChildrenAgesArrayForFilter.length) {
        await this.selectChildren().then(res => {
          ampUserProperties.childrenBirthYear = res.map(
            child => child.birthYear,
          );
          ampUserProperties.childrenGender = res.map(child => child.gender);
        });
        // this.SET_SELECTED_AGE([...this.userChildrenAgesArrayForFilter]);
      } else {
        ampUserProperties.childrenBirthYear = this.children.map(
          child => child.birthYear,
        );
        ampUserProperties.childrenGender = this.children.map(
          child => child.gender,
        );
      }

      this.setAmplitudeUserPropertiesOnce(ampOnceUserProperties);
      this.setUserProperties(ampUserProperties);
    },
    goToTop() {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    },
  },
};
