// Date 를 받아서 한국 시간대로 표기
// [
//   ...,
//   { type: 'weekday', value: 'Fri' or '금' },
//   { type: 'month', value: '03' },
//   { type: 'day', value: '19' },
//   { type: 'year', value: '2021' },
//   { type: 'hour', value: '00' },
//   { type: 'minute', value: '00' }
//   ...,
// ]
// 이를 기반으로 parse, 모두 string 으로 리턴
// c.f. 서버 Node.js 환경에서 locale 이 제대로 적용되지 않는 문제가 있음 (timeZone 은 반영됨)

const locales = 'ko-KR';
const options = {
  formatMatcher: 'basic',
  hour12: false,
  weekday: 'short',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'Asia/Seoul',
};

export default {
  getKoreanDate: date => {
    const d = new Date(date);
    const utc = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    return new Date(utc + KR_TIME_DIFF);
  },
  getKoreaToday: () => {
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

    return new Date(utc + KR_TIME_DIFF);
  },
  getISOString: date => {
    const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
    const year = d.find(el => el.type === 'year').value;
    const month = d.find(el => el.type === 'month').value;
    const day = d.find(el => el.type === 'day').value;
    return `${year}-${month}-${day}`;
  },
  getYear: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'year');
      return result.value;
    }
    return '';
  },
  getMonth: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'month');
      return result.value;
    }
    return '';
  },
  getDate: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'day');
      return result.value;
    }
    return '';
  },
  getWeekday: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'weekday');
      return result.value;
    }
    return '';
  },
  getMeridiem: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'hour');
      return parseInt(result.value) < 12 ? '오전' : '오후';
    }
    return '';
  },
  getHour12: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'hour');
      const hour12 = parseInt(result.value) % 12;
      return hour12 === 0 ? '12' : `${hour12}`;
    }
    return '';
  },
  getHour24: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'hour');
      return result.value;
    }
    return '';
  },
  getMinutes: date => {
    if (date instanceof Date) {
      const d = new Intl.DateTimeFormat(locales, options).formatToParts(date);
      const result = d.find(el => el.type === 'minute');
      return result.value;
    }
    return '';
  },
};
