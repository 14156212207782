
import { mapGetters, mapMutations } from 'vuex';
import { removeLastClickSection } from '@/utils/localStorageUtils.js';

import ProgressSpinner from '@/components/common/ProgressSpinner';

export default {
  name: 'LoginModal',
  components: { ProgressSpinner },
  data() {
    return {
      kakaoWrapper: require('@/assets/img/auth/kakao_wrapper.png'),
      kakaoOther: require('@/assets/img/auth/kakao_other.png'),
      // 카카오로그인을 하고 리디렉션되기 때문에 isPending을 false로 바꾸는 로직은 따로 없습니다.
      isPending: false,
      isOtherLogin: false,
    };
  },
  computed: {
    ...mapGetters([
      'isLoginModalOpen',
      'loginModalProperties',
      'loginModalCurrentPath',
    ]),
    isKakaoBrowser() {
      return window.navigator?.userAgent?.includes('KAKAOTALK');
    },
  },
  watch: {
    '$route.query'() {
      // 페이지 이동 시 로그인모달 닫기
      this.setLoginModal(false);
    },
  },
  methods: {
    ...mapMutations({ setLoginModal: 'SET_LOGIN_MODAL' }),
    onClickEasyLogin() {
      if (this.isPending) return;
      this.isPending = true;
      this.isOtherLogin = false;

      this.requestKakaoToken({
        currentPath: this.loginModalCurrentPath || null,
      });
    },
    onClickOtherAccountLogin() {
      if (this.isPending) return;
      this.isPending = true;
      this.isOtherLogin = true;

      this.requestKakaoTokenOtherAccount({
        currentPath: this.loginModalCurrentPath || null,
      });
    },
    onClickCloseLoginModal() {
      removeLastClickSection();
      this.setLoginModal(false);
    },
  },
};
