import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';

export const actions = {
  async getGuidanceIntroVOD() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/vod/guidance/intro`,
      );
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /**
   * @description
   * 회사명 / slug로 vod를 들고오는 api
   */
  async getPsycheVOD(_, { company, slug }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/vod/${company}/${slug}`,
      );
      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  async getHunoRecommendCuration(_, { exam, category }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/etc/huno/category/recommend`,
        {
          params: {
            exam,
            category,
          },
        },
      );

      return convertV3ClassToV2Product(data);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 구독 BEST 수업
   */
  async getBestSubsClassList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/best-subscribe`,
      );
      // 데이터 가공
      const convertClassList = convertV3ClassToV2Product(data);

      return convertClassList || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
