
/**
 * @description
 * 로그인 한 유저용 GNB 네비
 * 샌드버드 로직도 같이 들어가있음
 * @author Greg
 */

import { CLICK_NAVIGATION } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { NAV_MENU } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters } from 'vuex';

import sendbirdConnection from '@/plugins/sendbirdConnection.client';

export default {
  name: 'AuthUserNavMenu',
  mixins: [sendbirdConnection],
  props: {
    isDrawer: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(['alarmList']),
    ...mapGetters('chat', ['getUnreadCount', 'hasSendbirdAccount']),
    hasUnReadAlarm() {
      return (
        Object.keys(this.alarmList)?.length > 0 &&
        this.alarmList?.isRead === false
      );
    },
    NAV_MENU: () => NAV_MENU,
  },
  mounted() {
    // 센드버드 실행
    this.$auth.loggedIn && this.callSendbird();
  },
  async destroyed() {
    await this.disconnectSendbird();
  },
  methods: {
    ...mapActions(['setActiveMobileCategoryListModal']),
    ...mapActions('chat', [
      'fetchHasSendbirdAccount',
      'fetchUnreadMessageCount',
    ]),
    onClickNavMenu(navId) {
      this.logNavEvent(navId);
      this.$emit('close-drawer');
    },
    onCloseDrawer() {
      this.logNavEvent(NAV_MENU.CLOSE);
      this.$emit('close-drawer');
    },
    onClickToggleDrawer() {
      this.logNavEvent(this.isDrawer ? NAV_MENU.CLOSE : NAV_MENU.DRAWER);
      this.$emit('toggle-drawer');
      this.setActiveMobileCategoryListModal(false);
    },
    logNavEvent(iconName) {
      this.logUserCustomEvent(CLICK_NAVIGATION, {
        at: getPathAt(this.$route),
        nav_icon: iconName,
      });
    },
    async callSendbird() {
      if (!this.$auth.loggedIn) return; // 수비코드

      await this.fetchHasSendbirdAccount();
      if (!this.hasSendbirdAccount) {
        this.startSendbirdConnection(); // 센드버드 토큰이 없어도, 인터발은 계속 돌면서, 확인.
        return;
      }
      if (this.$route.name === 'chat') return;
      this.initChatStore();
      // 동접자수가 초과되면, 인터발 중지함. 센드버드 연결 안 함. 처리됨. 별도의 에러처리 안 함.
      try {
        // 센드버드 연결
        await this.connectSendbird();
      } catch (error) {
        console.error(error);
        return;
      }
      try {
        // 처음 페이지 렌더링: 대화 메시지 안 읽은 갯수 조회
        await this.fetchUnreadMessageCount();
        // 센드버드 연결 종료
        await this.disconnectSendbird();
      } catch (error) {
        console.error(error);
        return;
      }
      this.startSendbirdConnection();
    },
  },
};
