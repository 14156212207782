import { render, staticRenderFns } from "./newResult.vue?vue&type=template&id=9e155c28&scoped=true"
import script from "./newResult.vue?vue&type=script&lang=js"
export * from "./newResult.vue?vue&type=script&lang=js"
import style0 from "./newResult.vue?vue&type=style&index=0&id=9e155c28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e155c28",
  null
  
)

export default component.exports