/* eslint-disable */
export default function ({ route, redirect, req }) {
  if (typeof route.query.utm_aligo !== 'undefined') {
    return redirect(
      `${
        route.path
      }?utm_source=lms&utm_medium=message&utm_campaign=op-notice&utm_term=registered&utm_content=${
        route.params.id || 'home'
      }`,
    );
  }

  // fullPath 사이에 utm_aligo가 붙으면 다른 utm으로 replace한 주소로 리다이렉트
  if (route.fullPath.includes('utm_aligo')) {
    return redirect(
      `${route.fullPath.replace(
        '%2F%3Futm_aligo',
        `&utm_source=lms&utm_medium=message&utm_campaign=op-notice&utm_term=registered&utm_content=${
          route.params.id || 'home'
        }`,
      )}`,
    );
  }

  /**
   * @description
   * url query에 ?가 중복으로 들어간 경우 utm 등이 이상하게 잡히는 경우가 있어 해당 경우를 방지하기 위한 미들웨어 redirection 기능입니다.
   * ex) /search?selectedAge=15?utm_xxx=1 -> /search?selectedAge=15&utm_xxx=1
   * ? 를 & 로 변환
   */
  // 1. 서버에서 호출한 것만 잡아줍니다.
  if (process.server) {
    try {
      // 2. origin값을 env에 정의된 DOMAIN값으로 잡아줍니다.
      const origin =
        process.env.DOMAIN ||
        (process.env.NODE_ENV === production
          ? 'https://gguge.com'
          : 'https://dev.gguge.com');

      // 3. baseUrl부분과 queryString부분을 발라낸 후
      const [baseUrl, queryString] = `${origin}${route.fullPath}`.split(
        /\?(.+)/,
      );

      // 3-1. baseUrl은 새 url을 만들어주고
      const urlObj = new URL(baseUrl);
      // 3-2. 쿼리 부분은 decode해줍니다.
      const decodeURI = decodeURIComponent(queryString);

      // 4. ?가 포함되지 않았다면 리턴합니다
      if (!hasMultipleQuestionMarks(decodeURI)) return;

      // 5. ?를 &로 변환하고 새 query를 만들어줍니다.
      const modifiedQueryString = decodeURI.replace(/\?/g, '&');
      modifiedQueryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        if (key) urlObj.searchParams.set(key, value);
      });

      // 6. 만들어둔 새 URL에 파라미터를 추가
      Object.entries(route.params).forEach(([key, value]) => {
        urlObj.searchParams.set(key, value);
      });

      // 7. newQueryString를 발라내서 해당 값으로 리다이렉트 해줍니다. ex) /search?selectedAge=15&test=1
      const [_, newQueryString] = urlObj
        .toString()
        ?.replace(/^https?:\/\//, '')
        .split(/\/(.+)/);

      return redirect(newQueryString);
    } catch (e) {
      console.error(e);
    }
  }
}

// query에 ?가 포함되어 있는지 체크합니다
const hasMultipleQuestionMarks = query => (query.match(/\?/g) || []).length > 0;
