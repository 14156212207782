
/**
 * @description
 * 홈 화면 > 재능 / 학습 스위칭 버튼
 */

import { CLICK_HOMETYPE } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { getLastHomeType, setLastHomeType } from '@/utils/cookieUtils';

export default {
  name: 'PageSwitch',
  data: () => {
    return {
      homeButtonList: [
        {
          text: '재능',
          value: 'talent',
          icon: require('@/assets/img/home/talent.svg'),
        },
        {
          text: '학습',
          value: 'education',
          icon: require('@/assets/img/home/education.svg'),
        },
      ],
      selectedHomeType: {},
      isMoving: false,

      // 타이머 변수 저장
      timer: null,
    };
  },
  computed: {
    selectedValue: ({ selectedHomeType }) => selectedHomeType.value,
  },
  mounted() {
    const lastHomeType = getLastHomeType();
    const selectedHome =
      this.$route?.query?.tab === undefined
        ? lastHomeType || 'talent'
        : this.$route?.query?.tab;

    this.selectedHomeType = this.homeButtonList.find(
      v => v.value === selectedHome,
    );
  },
  methods: {
    handleClickSwitch(value) {
      // 움직이는 중이라면 얼리리턴
      if (this.isMoving) return;

      this.isMoving = true;

      this.logSwitchEvent();

      setLastHomeType(value);

      this.selectedHomeType = this.homeButtonList.find(v => v.value === value);

      this.$router.push({
        name: this.$route.name,
        query: { tab: value },
      });

      // animation 완료 시점(0.3초) 후에 다시 동작 가능하게 해줍니다.
      setTimeout(() => {
        this.isMoving = false;
      }, 300);
    },
    logSwitchEvent() {
      this.logUserCustomEvent(CLICK_HOMETYPE);
    },
  },
};
