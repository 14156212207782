export const state = () => ({
  myProductList: [],
});

export const getters = {
  myProductList: state => state.myProductList,
};

export const actions = {
  // 결제된 상품 정보 조회
  selectUserProduct(_, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/product/${id}`)
        .then(res => {
          if (res.userProductReview) {
            res.schedule.product.id = res.schedule.productId;
            if (res.userProductReview && res.userProductReview.images) {
              res.userProductReview.images =
                res.userProductReview.images.split(',');
            }
          }
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 내가 구매한 상품 목록 조회 (참여한 수업이에요 표기용)
  selectUserMyProductList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/product/my`)
        .then(res => {
          commit('SET_MY_PRODUCT_LIST', res);
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 유저가 구매하려고 선택한 스케줄의 겹치는 스케줄이 있는지 체크하는 api
   * @params groupNo
   */
  async checkOverlapSchedule(_, { groupNo }) {
    try {
      return await this.$axios.$get(`/v2/user/product/check-duplicate`, {
        params: {
          groupNo,
        },
      });
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 파라미터로 넘기는 스케줄과 유저 childrenId로 아이가 이미 듣고있는 스케줄인지 체크하는 api
   * @params groupNo, childrenId
   */
  checkUserChildrenScheduleExist(_, { groupNo, childrenId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/product/is-exist`, {
          params: {
            groupNo,
            childrenId,
          },
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_MY_PRODUCT_LIST(state, productList) {
    state.myProductList = productList;
  },
  RESET_ALL_USER_PRODUCT(state) {
    state.myProductList = [];
  },
};
