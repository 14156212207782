
/**
 * @description
 * 홈 화면 > 히어로 배너 스와이퍼
 */

import { Swiper } from 'vue-awesome-swiper';

import Icon from '@/components/common/icon/Icon.vue';
import HeroBannerSlide from './HeroBannerSlide.vue';

export default {
  name: 'HeroBannerSwiper',
  components: { Swiper, Icon, HeroBannerSlide },
  props: {
    bannerList: { type: Array, required: true },
  },
  data() {
    return {
      bannerSwiper: null,
      currentIndex: 1,
      swiperOption: {
        parallax: true,
        slidesPerView: 'auto',
        grabCursor: true,
        loop: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false, // 인터렉션 후 자동재생 다시 실행하게
        },
        mousewheel: false,
        effect: 'fade',
        navigation: {},
        pagination: {
          el: '.banner-pagination',
          type: 'fraction',
          renderFraction(currentClass, totalClass) {
            return (
              '<p><span class="text-white ' +
              currentClass +
              '"></span>' +
              ' / ' +
              '<span class="' +
              totalClass +
              '"></span></p>'
            );
          },
        },
      },
    };
  },
  mounted() {
    this.bannerSwiper = this.$refs.bannerSwiper?.$swiper;
  },
  methods: {
    // swiper의 오토플레이 이벤트 잠시 멈춤
    pauseEvent() {
      this.bannerSwiper.autoplay.stop();
    },
    // swiper의 오토플레이 이벤트 다시 시작
    restartEvent() {
      this.bannerSwiper.autoplay.start();
    },
    slidePrev() {
      this.bannerSwiper.slidePrev();
    },
    slideNext() {
      this.bannerSwiper.slideNext();
    },
  },
};
