const TimeIconSet = {
  clock: {
    drawings: [
      {
        type: 'path',
        d: 'M24 16.8889V25.7762C24 25.7767 24.0002 25.7773 24.0006 25.7776L27.5556 29.3333M40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24Z',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const TimeIconNames = Object.keys(TimeIconSet);

export { TimeIconNames, TimeIconSet };
