
import { mapGetters } from 'vuex';

export default {
  name: 'Notice',
  computed: {
    ...mapGetters('notice', ['noticeList']),
    newNotice() {
      return this.noticeList.find(notice => notice.isNew);
    },
  },
};
