
/**
 * @description
 * 공용 태그 아이템
 */

import { TagNames, TagSet } from '@/utils/tag/constants.js';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'Tag',
  components: { Icon },
  props: {
    tagType: { type: String, default: null },
    tagText: { type: String, default: null },
    icon: { type: String, default: null },
  },
  computed: {
    tagStyle() {
      if (TagNames.includes(this.tagType)) {
        return TagSet[this.tagType];
      } else {
        return null;
      }
    },
  },
};
