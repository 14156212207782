
/**
 * @description
 * 비 로그인 유저를 위한 랜딩페이지 GNB 네비 메뉴
 */

import { NAV_MENU } from '@/utils/amplitudeEvent/amplitudeParams.js';

export default {
  name: 'LandingNavMenu',
  computed: {
    loginProp: () => NAV_MENU.LOGIN,
    signupProp: () => NAV_MENU.SIGN_UP,
  },
  methods: {
    onClickLogin() {
      this.logSignUpEvent(this.loginProp);
      this.requestToken({});
    },
    onClickSignup() {
      this.logSignUpEvent(this.signupProp);
      this.requestToken({});
    },
  },
};
