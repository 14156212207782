import { makeChatDateText } from '@/utils/dateFnsUtils.js';
import { requested } from '@/utils/strings';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isDuplicatedMessage } from '@/utils/validation';

export default {
  computed: {
    ...mapGetters('chat', [
      'getCurChannel',
      'getSendbirdUserId',
      'getSendbirdUserToken',
      'getCollection',
      'getChannelList',
      'hasSendbirdAccount',
      'getTodayMessageList',
    ]),
  },
  methods: {
    ...mapActions('chat', [
      'fetchChannelList',
      'fetchSendbirdToken',
      'fetchHasSendbirdAccount',
      'fetchUnreadMessageCount',
    ]),
    ...mapMutations('chat', [
      'initChatStore',
      'stopConnectionSendbirdInterval',
      'updateChannel',
      'setChannelList',
      'setCurChannel',
      'setCollection',
      'setMessageMap',
      'checkUnReadMessage',
      'setConnectionInterval',
      'setHasNewMessage',
    ]),
    /**
     * @description 센드버드 연결 종료
     */
    async disconnectSendbird() {
      if (!this.hasSendbirdAccount) return;
      try {
        await this.$sb.disconnect();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * 5분 주기로 연결. 채널 페이지에서는 계속 유지
     * [동작 순서]
     * 1. 센드버드 store 초기화.
     * 2. store/teacher에 정보 없으면, 티쳐 정보 패치
     * 3. 선생님 센드버드 토큰 우리서버로 조회 요청
     * 4. 센드버드 연결
     * 5. 센드버드 API - collection 조회
     * 6. 30개 channel 리스트 패치
     * @returns
     */
    async connectSendbird() {
      if (!this.$sb) return;

      try {
        await this.fetchSendbirdToken();

        // 서버에서 센드버드 로그인용 토큰 발급에 실패한 경우,
        if (!this.getSendbirdUserId || !this.getSendbirdUserToken) {
          this.$refs.modal.showAlert({
            title: '오류가 발생했습니다.',
            message: '토큰 요청에 실패했습니다. 관리자에게 문의해주세요.',
            titleClass: 'h-r-md font-333',
            messageClass: 'b-r-md font-333',
            confirmBtnText: '확인',
          });
          return;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }

      try {
        await this.$sb.connect(
          this.getSendbirdUserId,
          this.getSendbirdUserToken,
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * @description 센드버드 콜렉션을 통해서 채널 리스트 패치
     */
    async getChannelListFromCollection() {
      try {
        // 채널 리스트를 받기 위한 collection 호출
        //  무한 스크롤을 사용하기 위해서 collection을 호출함.
        if (!this.getCollection) {
          const collection = this.$sb.groupChannel.createGroupChannelCollection(
            {
              limit: 30,
            },
          );
          const handler = {
            onChannelsAdded: (context, channels) => {
              // Add new channels to your data source.

              const isSent = 'EVENT_MESSAGE_SENT';
              const isReceived = 'EVENT_MESSAGE_RECEIVED';
              switch (context.source) {
                case isSent: {
                  const updatedChannelUrlList = channels.map(({ url }) => url);
                  const newChannelList = this.getChannelList.filter(
                    ({ url }) => {
                      return !updatedChannelUrlList.includes(url);
                    },
                  );

                  this.setChannelList([...channels, ...newChannelList]);
                  return '';
                }
                case isReceived: {
                  const updatedChannelUrlList = channels.map(({ url }) => url);
                  const newChannelList = this.getChannelList.filter(
                    ({ url }) => {
                      return !updatedChannelUrlList.includes(url);
                    },
                  );

                  this.setChannelList([...channels, ...newChannelList]);
                  return '';
                }
                default:
                  return '';
              }
            },
            onChannelsUpdated: async (context, channels) => {
              const isRead = 'EVENT_CHANNEL_READ';
              const isSent = 'EVENT_MESSAGE_SENT';
              const isReceived = 'EVENT_MESSAGE_RECEIVED';

              // Update the existing channels in your data source.
              switch (context.source) {
                case isRead:
                  // 읽었을 땐, 안 읽은 대화 메시지 카운트 다시 조회
                  await this.fetchUnreadMessageCount();
                  return '';
                case isSent: {
                  const updatedChannelUrlList = channels.map(({ url }) => url);
                  const newChannelList = this.getChannelList.filter(
                    ({ url }) => {
                      return !updatedChannelUrlList.includes(url);
                    },
                  );

                  this.setChannelList([...channels, ...newChannelList]);
                  return '';
                }
                case isReceived: {
                  // 읽었을 땐, 안 읽은 대화 메시지 카운트 다시 조회
                  await this.fetchUnreadMessageCount();
                  const updatedChannelUrlList = channels.map(({ url }) => url);
                  const newChannelList = this.getChannelList.filter(
                    ({ url }) => {
                      return !updatedChannelUrlList.includes(url);
                    },
                  );

                  this.setChannelList([...channels, ...newChannelList]);
                  // 현재 채널 보고 있으면, 채널 상태를 업데이트해야 한다.
                  if (!this.getCurChannel) return;
                  const nowUpdateChannelIndex = channels?.findIndex(
                    ({ url }) => url === this.getCurChannel?.url,
                  );
                  if (nowUpdateChannelIndex > -1) {
                    this.setCurChannel(channels[nowUpdateChannelIndex]);
                  }
                  return '';
                }
                default:
                  return '';
              }
            },
            onChannelsDeleted: (context, channelUrls) => {
              // Delete the channels with the matching channelUrls from your data source.
            },
          };
          collection.setGroupChannelCollectionHandler(handler);
          this.setCollection(collection);
        }
        await this.fetchChannelList();
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * @description 센드버드 연결 종료 인터발 실행
     * 5분 주기 인터발 실행
     * 인터발 인스턴스는 store에서 관리한다.
     * 채널 페이지에 들어가면, 종료된다. -> 채널 페이지 created에서 다시 연결한다.
     * 채널 페이지가 destroyed되고부터, 5분 인터발로 다시 실행한다.
     * 웹 페이지 닫으면, 인터발 끄고, 연결되어 있으면, 종료한다.
     */
    startSendbirdConnection() {
      this.stopConnectionSendbirdInterval(); // 센드버드 연결 인터발 중지

      const fiveMinute = 1000 * 60 * 1; // 2분 텀
      const interval = setInterval(async () => {
        await this.fetchHasSendbirdAccount();
        if (!this.hasSendbirdAccount) return;
        this.initChatStore();
        try {
          await this.connectSendbird();
        } catch (error) {
          console.error(error);
          return;
        }

        // 인터발. 안 읽은 메시지 카운트 조회
        try {
          await this.fetchUnreadMessageCount();
        } catch (error) {
          console.error(error);
        }
        this.disconnectSendbird();
      }, fiveMinute);
      this.setConnectionInterval(interval);
    },
    /**
     * @description 센드버드 이벤트 리스너
     * 센드버드 연결이 종료되었다가, 다시 연결되었을 때는 동작 안 함.
     * 계속 연결 중일 때, 리스너가 실행됨.
     */
    initSyncWithSendbird() {
      const groupChannelHandler = {
        // 상대방이 메시지를 보냈을 때
        onMessageReceived: (channel, message) => {
          if (channel?.url !== this.getCurChannel?.url) return;
          // 내가 보낸 메시지가 아니면 패스. 메시지 중복 방지
          if (
            message?.sender?.userId === this.getSendbirdUserId &&
            isDuplicatedMessage(this.getTodayMessageList, message)
          )
            return;
          const newMessageMap = new Map(this.getMessageMap.entries());
          const dateKey = makeChatDateText(new Date(message.createdAt));
          newMessageMap.set(
            dateKey,
            newMessageMap.has(dateKey)
              ? [...newMessageMap.get(dateKey), message]
              : [message],
          );
          this.setMessageMap(newMessageMap);
          this.getCurChannel.markAsRead();

          // REQUESTED인 채널인데, 선생님이 보낸 메시지인 거면, 상태만 바꿔준다.
          if (
            this.getCurChannel?.state === requested &&
            message?.sender?.userId?.includes('teacher-')
          ) {
            const curChannel = this.getCurChannel;
            curChannel.state = message?.messageId;

            this.setCurChannel(null);
            this.setCurChannel(curChannel);
          }

          // 새로운 메시지가 온 걸 채널 컴포넌트에 알려줘야 함.
          this.setHasNewMessage(true);
        },
        // 메시지를 삭제하거나 신고했을 때 실행되는 이벤트
        onMessageUpdated: (channel, message) => {
          // 채널 열어보고 있으면, 메시지 갱신해야 한다.
          if (this.getCurChannel?.url !== channel?.url) return;

          // 메시지 중복 방지
          if (
            message?.sender?.userId === this.getSendbirdUserId &&
            isDuplicatedMessage(this.getTodayMessageList, message)
          )
            return;
          const newMessageMap = new Map(this.getMessageMap.entries());
          const dateKey = makeChatDateText(new Date());
          if (!newMessageMap.has(dateKey)) return;

          newMessageMap.set(
            dateKey,
            newMessageMap.has(dateKey)
              ? newMessageMap.get(dateKey).map(messageItem => {
                  if (messageItem.messageId === message.messageId) {
                    return message;
                  }
                  return messageItem;
                })
              : [message],
          );
          this.setMessageMap(newMessageMap);
        },
        onChannelDeleted: channelUrl => {
          const newChannel = this.getChannelList.filter(
            ({ url }) => url !== channelUrl,
          );
          this.setChannelList(newChannel);
          this.setCurChannel(null);
          this.setMessageMap(null);
        },
        /**
         * @description 사용자가 보낸 메시지 읽으면 호출됨
         * @params channel
         */
        onUnreadMemberStatusUpdated: () => {
          this.checkUnReadMessage();
        },
      };
      this.$initSyncWithSendbird(this.getSendbirdUserId, groupChannelHandler);
    },
  },
};
