
import DepthList from './DepthList.vue';

export default {
  name: 'CategoryItem',
  components: { DepthList },
  props: {
    categoryId: { type: Number, required: true },
    name: { type: String, default: null },
    image: { type: String, default: null },
    categoryDepthList: { type: Array, default: () => [] },
    depthOpenCategoryId: { type: Number, default: -1 },
  },
  computed: {
    isOpenDepth: ({ categoryId, depthOpenCategoryId }) =>
      categoryId === depthOpenCategoryId,
  },
  methods: {
    openCategoryDepth() {
      this.$emit('open-category-depth', this.categoryId);
    },
  },
};
