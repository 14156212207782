
/**
 * @description
 * 로드맵 > 썸네일
 */

import Thumbnail from '@/components/common/product/Thumbnail.vue';
import CourseFavoriteButton from './CourseFavoriteButton.vue';
import CourseTag from './CourseTag.vue';

export default {
  name: 'ThumbnailItem',
  components: { Thumbnail, CourseFavoriteButton, CourseTag },
  props: {
    isFavoriteCourse: { type: Boolean, default: false },
    thumbnailImage: { type: String, default: null },
    isCard: { type: Boolean, default: false },
  },
};
