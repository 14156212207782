const MathFinanceIconSet = {
  x: {
    drawings: [
      {
        type: 'path',
        d: 'M18 6L6 18',
        stroke: 'currentColor',
        'stroke-width': '1.5',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M6 6L18 18',
        stroke: 'currentColor',
        'stroke-width': '1.5',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 24 24',
  },
  xCircle: {
    drawings: [
      {
        type: 'path',
        d: 'M24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6Z',
        fill: 'currentColor',
      },
      {
        type: 'path',
        d: 'M30 18L18 30',
        stroke: 'white',
        'stroke-width': '2',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M18 18L30 30',
        stroke: 'white',
        'stroke-width': '2',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const MathFinanceNames = Object.keys(MathFinanceIconSet);

export { MathFinanceIconSet, MathFinanceNames };
