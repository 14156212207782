export const state = () => ({
  isBottomSheet: false,
  bottomSheetComponent: '', // name of component
  bottomSheetComponentProps: {}, // component specific props for v-bind
  bottomSheetStyle: {
    bgColor: 'white',
    initHeight: 0, // undefined when <= 0
    closeHeight: 200,
  },
});

export const getters = {
  isBottomSheet: state => state.isBottomSheet,
  bottomSheetComponent: state => state.bottomSheetComponent,
  bottomSheetComponentProps: state => state.bottomSheetComponentProps,
  bottomSheetStyle: state => state.bottomSheetStyle,
};

export const mutations = {
  OPEN_BOTTOM_SHEET(state, flag) {
    state.isBottomSheet = flag;
  },
  SET_BOTTOM_SHEET_PROPS(state, { initHeight, closeHeight }) {
    Object.assign(state.bottomSheetStyle, {
      initHeight, // undefined when <= 0
      closeHeight,
    });
  },
  RESET_BOTTOM_SHEET_PROPS(state) {
    Object.assign(state.bottomSheetStyle, {
      initHeight: 0, // undefined when <= 0
      closeHeight: 200,
    });
    state.bottomSheetComponentProps = {};
  },
  SET_BOTTOM_SHEET_COMPONENT(state, componentName) {
    state.bottomSheetComponent = componentName;
  },
  SET_BOTTOM_SHEET_COMPONENT_PROPS(state, props) {
    state.bottomSheetComponentProps = props;
  },
};
