
import { mapActions } from 'vuex';

export default {
  name: 'NavLogo',
  data() {
    return {
      ggugeLogoText: require('@/assets/img/layout/logo_text.svg'),
      ggugeLogoBlack: require('@/assets/img/layout/logo_black.svg'),
      doodleImg: '',
      doodleLink: '/',
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const doodle = await this.getDoodles();

      this.doodleLink = doodle?.link || '/';
      this.doodleImg = doodle?.img_src || '';
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    isHome: ({ $route }) => $route.path === '/',
    isSearchPage: ({ $route }) => $route.path === '/search',
  },
  methods: {
    ...mapActions('config', ['getDoodles']),
    // 로고 클릭 시 appDrawer는 반드시 닫히게
    onClickLogo() {
      this.$emit('force-close');
    },
  },
};
