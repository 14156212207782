
export default {
  name: 'RoundAvatar',
  props: {
    profileImg: { type: String, default: null },
    noResizeImg: { type: Boolean, default: false }, // 리사이즈를 안하거나, 로컬 이미지를 사용하는 경우
    imgResizeSize: { type: Number, default: 40 }, // 몇 px로 리사이즈 할 것인지. x2 ~ x3정도 권장
    onDeleteImg: { type: Function, default: null },
  },
  data() {
    return {
      profileImgPlaceholder: '/img/placeholder/user-profile.png',
    };
  },
  computed: {
    profileImgSrc() {
      if (!this.profileImg || this.profileImg.length === 0)
        return this.profileImgPlaceholder;

      // 리사이저를 사용하지 않는다면 imgWrapper로 싸지 않고 그대로 사용
      if (this.noResizeImg) return this.profileImg;

      return this.$imgWrapper(this.profileImg, this.imgResizeSize);
    },
  },
  methods: {
    onImageLoadFail(event) {
      event.target.src = this.profileImgPlaceholder;
    },
  },
};
