
import RoundAvatar from '@/components/common/img/RoundAvatar';

export default {
  name: 'CommonProfile',
  components: { RoundAvatar },
  props: {
    profileName: { type: String, default: '' },
    profileImg: { type: String, default: '' },
    profileImgQuality: { type: Number, default: 32 }, // 이미지 리사이즈 해상도 조절
    profileNameFontStyle: { type: String, default: 'font-caption' },
    profileNameColor: { type: String, default: 'text-gray-60' },
    isEllipsis: { type: Boolean, default: false },
    isResponsible: { type: Boolean, default: true },
  },
  data() {
    return {};
  },
  computed: {
    nameClass() {
      if (!this.isResponsible)
        return `un-responsible-font ${this.profileNameColor}`;
      return `${this.profileNameFontStyle} ${this.profileNameColor}`;
    },
    hasBottomSection() {
      return !!this.$slots.bottom;
    },
  },
};
