/* AppEnums */

export const ROW_TABLE_TYPE = {
  divider: 'DIVIDER',
  text: 'TEXT',
  button: 'BUTTON',
  link: 'LINK',
  toggle: 'TOGGLE',
  ul: 'UL',
};

export const CLASS_TYPE = {
  oneDay: '원데이 클래스',
  group: '꾸러미 클래스',
  rolling: '롤링 클래스',
};

export const SUBS_STATUS = {
  SUBSCRIBE: 'SUBSCRIBE', // 구독중
  CANCEL_WAIT: 'CANCEL_WAIT', // 취소 대기중 (마지막으로 들을 수 있는 수업 시작 시간이 지나면 CANCEL로 변경)
  CANCEL: 'CANCEL', // 취소 상태의 구독
};

export const TOSS_ORDER_TYPE = {
  CLASS: 'CLASS',
  MONEY: 'MONEY',
  TARGET_CLASS: 'TARGET_CLASS',
};

export const TOSS_PAYMENT_TYPE = {
  BRANDPAY: 'GGUGE_PAY',
  NORMAL: 'TOSS',
  COUPON: 'COUPON',
  FREE: 'FREE',
};

export const PAYMENT_METHOD = {
  MONEY: 'MONEY', // 머니로만 구매
  COUPON: 'COUPON', // 0원 결제시 쿠폰이 있다면
  FREE: 'FREE', // 0원 결제 + 쿠폰 사용안함
  UNDEFINED: 'UNDEFINED', // 결제타입 미정인 상태
};

/**
 * @description
 * https://www.notion.so/glorang/2d3343947b434d3b8854069ec01671f4
 */
export const MONEY_HISTORY_TYPE = {
  CHARGE: 'CHARGE', // 충전
  USE: 'USE', // 사용
  WITHDRAW: 'WITHDRAW', // 충전 즉시 환불/청약 철회
  FORCE_USE: 'FORCE_USE', // 강제 징수
  CANCEL: 'CANCEL', // 수업 결제를 환불(로 적립이 된거)
  REFUND: 'REFUND', // 충전 수동 환불 (대기)
  REFUND_COMPLETE: 'REFUND_COMPLETE', // 충전 수동 환불 완료
  ADMIN: 'ADMIN', // 관리자 지급/회수
  REWARD: 'REWARD', // 보상
  REVIEW_REWARD: 'REVIEW_REWARD', // 리뷰 보상
  REWARD_BY_ADMIN: 'REWARD_BY_ADMIN', // 관리자 지급
  USE_BY_ADMIN: 'USE_BY_ADMIN', // 관리자 차감
};

export const PAY_METHOD = {
  GGUGE_PAY: '꾸그페이',
  TOSS: '일반결제',
  TOSS_BILLING: '정기 결제 카드',
  INICIS: '일반결제',
  INICIS_BILLING: '정기 결제 카드',
  APPLE: '인앱결제',
  MONEY: '꾸그머니',
  COUPON: '쿠폰',
  FREE: '무료결제',
};

/**
 * 상품의 상태
 * DRAFT : 초안
 * REJECT : 반려
 * APPROVAL: 승인대기
 * SURVEY : 수요조사
 * SALE : 판매가능
 * ARCHIVE : 보관
 */
export const PRODUCT_STATUS = {
  DRAFT: 'DRAFT',
  REJECT: 'REJECT',
  APPROVAL: 'APPROVAL',
  SURVEY: 'SURVEY',
  SALE: 'SALE',
  ARCHIVE: 'ARCHIVE',
};

// 특정 이유로 class가 아닌 id값을 줄 때
export const DOM_ID_LIST = {
  NAV: 'navigation-header',
};

// 선생님 계약 상태
export const TEACHER_CONTRACT_STATUS = {
  NONE: 'NONE',
  DISABLE: 'DISABLE', // 계약 해지
  ENABLE: 'ENABLE',
  WAITING: 'WAITING',
};

export const NEW_PRODUCT_NAVIGATOR_ID = {
  DESCRIPTION: 'product-description-section',
  SCHEDULE: 'product-schedule-section',
  TEACHER_INFO: 'product-teacher-info-section',
  REVIEW: 'product-review-section',
  ROADMAP: 'product-roadmap',
};

// 로드맵 상세페이지 네비게이션
export const ROADMAP_NAVIGATOR_ID = {
  DESCRIPTION: 'roadmap-description-section',
  RELATED_CLASS: 'roadmap-related-class-section',
};
