
import { INITIATE_MONEY_CHARGE } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { numberToCommaString } from '@/utils/numberString';
import { savePrevPathSessionStorage } from '@/utils/sessionStorageUtils.js';
import { mapGetters } from 'vuex';

import Icon from '@/components/common/icon/Icon.vue';
import RoundAvatar from '@/components/common/img/RoundAvatar.vue';
import Nudge from '../../common/2024GgugeDay/nudge.vue';

export default {
  name: 'AuthProfile',
  components: { RoundAvatar, Icon, Nudge },
  computed: {
    ...mapGetters('user/money', ['totalMoney']),
    ...mapGetters('payment-event', ['onEvent']),
    totalMoneyString() {
      if (!this.totalMoney) return '0';
      return numberToCommaString(this.totalMoney);
    },
  },
  methods: {
    logInitiateChargeMoney() {
      // 어느 페이지에서 충전버튼을 눌러도 prevPath는 유저 머니 리스트 페이지로 이동하게 변경
      savePrevPathSessionStorage('/my/money');

      this.logUserCustomEvent(INITIATE_MONEY_CHARGE, {
        at: 'drawer',
      });
    },
    onClickNavMenu({ type }) {
      this.$emit('click-nav-menu', { type });
    },
  },
};
