const PeopleIconSet = {
  baby: {
    drawings: [
      {
        type: 'path',
        d: 'M24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M30 23C30 23.5523 29.5523 24 29 24C28.4477 24 28 23.5523 28 23C28 22.4477 28.4477 22 29 22C29.5523 22 30 22.4477 30 23Z',
        fill: 'currentColor',
        stroke: 'currentColor',
        'stroke-width': '2',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M20 23C20 23.5523 19.5523 24 19 24C18.4477 24 18 23.5523 18 23C18 22.4477 18.4477 22 19 22C19.5523 22 20 22.4477 20 23Z',
        fill: 'currentColor',
        stroke: 'currentColor',
        'stroke-width': '2',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M20 30C20.3333 30.6667 21.6 32 24 32C26.4 32 27.6667 30.6667 28 30',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M26 14C25 16 21 16 21 12C21 10.636 22 9 23 8.39844',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const PeopleIconNames = Object.keys(PeopleIconSet);

export { PeopleIconNames, PeopleIconSet };
