const SystemDeviceIconSet = {
  check: {
    drawings: [
      {
        type: 'path',
        d: 'M12 23L20 33L37 16',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  magnifier: {
    drawings: [
      {
        type: 'path',
        d: 'M38.9393 41.0607C39.5251 41.6465 40.4749 41.6465 41.0607 41.0607C41.6465 40.4749 41.6465 39.5251 41.0607 38.9393L38.9393 41.0607ZM32.8529 21.1765C32.8529 27.6252 27.6252 32.8529 21.1765 32.8529V35.8529C29.2821 35.8529 35.8529 29.2821 35.8529 21.1765H32.8529ZM21.1765 32.8529C14.7277 32.8529 9.5 27.6252 9.5 21.1765H6.5C6.5 29.2821 13.0709 35.8529 21.1765 35.8529V32.8529ZM9.5 21.1765C9.5 14.7277 14.7277 9.5 21.1765 9.5V6.5C13.0709 6.5 6.5 13.0709 6.5 21.1765H9.5ZM21.1765 9.5C27.6252 9.5 32.8529 14.7277 32.8529 21.1765H35.8529C35.8529 13.0709 29.2821 6.5 21.1765 6.5V9.5ZM29.5276 31.6489L38.9393 41.0607L41.0607 38.9393L31.6489 29.5276L29.5276 31.6489Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
  doubleCheck: {
    drawings: [
      {
        type: 'path',
        d: 'M3 12.75L6.75 16.5L15.75 7.5M12 15L13.5 16.5L22.5 7.5',
        stroke: 'currentColor',
        'stroke-width': '1.5',
        'stroke-linecap': 'round',
        'stroke-linejoin': ' round',
      },
    ],
    viewBox: '0 0 24 24',
  },
};

const SystemDeviceIconNames = Object.keys(SystemDeviceIconSet);

export { SystemDeviceIconNames, SystemDeviceIconSet };
