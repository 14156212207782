
import { mapGetters, mapMutations } from 'vuex';

import BottomSheetContents from '@/components/common/bottomSheet/BottomSheetContents.vue';

export default {
  name: 'BottomSheet',
  components: { BottomSheetContents },
  computed: {
    ...mapGetters('bottom-sheet', [
      'isBottomSheet',
      'bottomSheetComponent',
      'bottomSheetComponentProps',
    ]),
  },
  methods: {
    ...mapMutations({
      openBottomSheet: 'bottom-sheet/OPEN_BOTTOM_SHEET',
      resetBottomSheetProps: 'bottom-sheet/RESET_BOTTOM_SHEET_PROPS',
    }),
    onCloseBottomSheet() {
      this.openBottomSheet(false);
      this.resetBottomSheetProps();
    },
  },
};
