
import { CLICK_APP_DOWNLOAD } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { APP_DOWNLOAD_STORE } from '@/utils/amplitudeEvent/amplitudeParams.js';
import { PLAY_STORE_LINK } from '@/utils/linkPath.js';

export default {
  name: 'DownloadApp',
  data() {
    return {
      appList: [
        {
          href: PLAY_STORE_LINK,
          icon: require('@/assets/img/footer/google_play.svg'),
          alt: 'google play store',
          type: APP_DOWNLOAD_STORE.PLAY_STORE,
        },
      ],
    };
  },
  methods: {
    logClickAppDownload(storeName) {
      this.logUserCustomEvent(CLICK_APP_DOWNLOAD, { type: storeName });
    },
  },
};
