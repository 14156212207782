// 꾸그 앱 다운로드 링크
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.glorang.ggugecalendar';
export const APP_STORE_LINK =
  'https://apps.apple.com/kr/app/%EA%BE%B8%EA%B7%B8-%EC%98%A8%EB%9D%BC%EC%9D%B8-%EB%9D%BC%EC%9D%B4%EB%B8%8C-%ED%82%A4%EC%A6%88-%EC%8A%A4%EC%BF%A8/id1575300311';
export const DYNAMIC_APP_LINK = 'https://gguge.page.link/download';

// 꾸끄 공식 페이지들 링크
export const NAVER_BLOG_LINK = 'https://blog.naver.com/gguge_official';
export const YOUTUBE_LINK = 'https://www.youtube.com/@ggugetv';
export const INSTARGRAM_LINK = 'https://www.instagram.com/gguge_official/';
export const FACEBOOK_LINK = 'https://www.facebook.com/ggugeofficial/';

export const GGUGE_MAIN_LINK = 'https://www.gguge.com';
// 꾸그 사이트 내 블로그 페이지 링크
export const GGUGE_BLOG_LINK = 'https://www.gguge.com/blog';
// 꾸그 채널톡 링크
export const GGUGE_CHANNEL_LINK = 'https://gguge.channel.io';
// 꾸그 선생님 링크
export const GGUGE_TEACHER_LINK = 'https://teacher.gguge.com';

// 헬프센터 메인 주소
export const GGUGE_HELP_CENTER = 'https://help.gguge.com';
// 키즈 헬프센터 주소
export const GGUGE_HELP_CENTER_KIDS = `${GGUGE_HELP_CENTER}/kids`;
// 키즈 공지사항
export const GGUGE_HELP_CENTER_NOTICES = `${GGUGE_HELP_CENTER_KIDS}/notices`;
// 키즈 서비스 이용약관
export const GGUGE_HELP_CENTER_SERVICE = `${GGUGE_HELP_CENTER_KIDS}/terms-of-service`;
// 키즈 개인정보 처리 방침
export const GGUGE_HELP_CENTER_PRIVACY = `${GGUGE_HELP_CENTER_KIDS}/privacy-policy`;

// 키즈 이벤트 목록
export const GGUGE_HELP_CENTER_EVENT = `${GGUGE_HELP_CENTER}/d4df7cc3-6d86-48dd-8b5b-8fc291ea4492`;
// 키즈 취소 및 환불 규정
export const GGUGE_HELP_CENTER_CANCEL = `${GGUGE_HELP_CENTER}/2bf09bd8-57c3-4f81-9f38-43f299187034`;
// 키즈 Zoom 이용 가이드
export const GGUGE_HELP_CENTER_ZOOM = `${GGUGE_HELP_CENTER}/88561095-3a26-489a-b90e-a7774764bc3c`;
// 키즈 Zoom 매너 가이드
export const GGUGE_HELP_CENTER_ZOOM_MANNER = `${GGUGE_HELP_CENTER}/7483b104-0c5a-4377-ac7b-3e94265992a7`;
// 키즈 정보 제공 동의 문서
export const GGUGE_HELP_CENTER_AGREE = `${GGUGE_HELP_CENTER}/e53e078c-cd0b-4a5d-8d8d-2eb609453191`;
// 꾸그가 처음이라면 설명 페이지
export const GGUGE_HELP_CENTER_FIRST = `${GGUGE_HELP_CENTER}/4425121a-e0c6-4daa-a40f-2a6787c859e5`;
// 꾸그 대화 정책 페이지
export const GGUGE_HELP_CENTER_CHAT_POLICY = `${GGUGE_HELP_CENTER}/3825eee2-3179-4dd6-95ef-399c062faa4f`;
export const GGUGE_HELP_CENTER_MARKETING = `${GGUGE_HELP_CENTER}/c753e8af-b667-41bf-afeb-9e12754c832d`;

// 교육기관 협력 문의용 메일주소
export const GGUGE_EMAIL_COLLABORATE =
  'mailto:op@gguge.com?subject=%EA%B5%90%EC%9C%A1%EA%B8%B0%EA%B4%80%20%ED%98%91%EB%A0%A5%20%EB%AC%B8%EC%9D%98';
// 기업 제휴 문의 주소
export const GGUGE_EMAIL_ALLIANCE =
  'mailto:op@gguge.com?subject=%EA%B8%B0%EC%97%85%20%EC%A0%9C%ED%9C%B4%20%EB%AC%B8%EC%9D%98';

// 회원가입 완료 후, 팝업 링크
export const GGUGE_POPUP_LINK = `${GGUGE_MAIN_LINK}/event/temperament-class?utm_source=crm&utm_medium=alimtalk&utm_campaign=signed&utm_content=temperatment-class&utm_term=jj`;
