import { makeChatDateText } from '@/utils/dateFnsUtils.js';
const state = () => ({
  hasNewMessage: false, // 채널에 신규 메시지 알럿을 노출해주는 플래그. 신규 수신 메시지. false가 되는 조건: 가장 밑에 스크롤되었을 때
  sendbirdUserId: '',
  sendbirdUserToken: '',
  collection: null, // 채널 리스트를 반환하기 위한 객체
  curChannel: null,
  channelList: [],
  messageMap: null,
  unreadCount: 0,
  userClassList: [], // [{}]
  unreadMessageIdList: [], // 보낸 메시지를 수신자가 읽지 않은 메시지 Id 리스트
  hasUnReadMessageCount: false, // 대화 안 읽은 메시지가 있음
  connectionInterval: null, // 5분 인터발 변수
  hasSendbirdAccount: false,
  isUploadingFile: false, // 모바일에서 첨부파일 올릴 때, 기기 파일 셀렉터 창이 떠서, visibilitychange 이벤트가 실행됨.(=센드버드 연결 종료) 그래서, 모바일에서 파일 전송이 안 되는 문제가 발생함.
  isSendbirdLoading: false,
  messageData: null,
  isSending: false, // [질문 보내기] 버튼 비활성화 목적. chatStart 컴포넌트에서 상태관리를 하지 않은 것은 sendMessage 메서드가 다른 컴포넌트에서도 사용되고, 센드버드 API 전송 완료가 내부 함수를 사용해서 비동기가 처리되기 때문.
});

const getters = {
  hasUnReadMessageCount(state) {
    return state.hasUnReadMessageCount;
  },
  getUserClassList(state) {
    return state.userClassList;
  },
  getUnreadCount(state) {
    return state.unreadCount;
  },
  getMessageData(state) {
    return state.messageData;
  },
  getChannelList(state) {
    return state.channelList;
  },
  getCollection(state) {
    return state.collection;
  },
  hasNewMessage(state) {
    return state.hasNewMessage;
  },
  hasNextChannel(state) {
    return state.collection?.hasMore;
  },
  getMessageMap(state) {
    return state.messageMap;
  },
  getCurChannel(state) {
    return state.curChannel;
  },
  getUnreadMessageIdList(state) {
    return state.unreadMessageIdList;
  },
  getSendbirdUserId(state) {
    return state.sendbirdUserId;
  },
  getSendbirdUserToken(state) {
    return state.sendbirdUserToken;
  },
  hasSendbirdAccount(state) {
    return state.hasSendbirdAccount;
  },
  isUploadingFile(state) {
    return state.isUploadingFile;
  },
  isSendbirdLoading(state) {
    return state.isSendbirdLoading;
  },
  isSending(state) {
    return state.isSending;
  },
  getTodayMessageList(state) {
    return state.messageMap?.get(makeChatDateText(new Date())) || [];
  },
};

const mutations = {
  initChatStore(state) {
    // state.collection = null; // 채널 리스트를 반환하기 위한 객체
    // state.channelList = [];
    state.curChannel = null;
    state.messageMap = null;
    // state.unreadCount = 0;
    state.unreadMessageIdList = []; // 보낸 메시지를 수신자가 읽지 않은 메시지 Id 리스트
  },
  setConnectionInterval(state, interval) {
    state.connectionInterval = interval;
  },
  setChannelList(state, newList) {
    state.channelList = newList;
  },
  setUnreadCount(state, count) {
    state.unreadCount = count;
  },
  setMessageMap(state, newMap) {
    state.messageMap = newMap;
  },
  setHasNewMessage(state, bool) {
    state.hasNewMessage = bool;
  },
  setHasSendbirdAccount(state, token) {
    return (state.hasSendbirdAccount = token);
  },
  setUnreadMessageIdList(state, newList) {
    state.unreadMessageIdList = newList;
  },
  setMessageData(state, data) {
    state.messageData = { ...data };
  },
  resetMessageData(state) {
    state.messageData = null;
  },
  /**
   * @description 채널방 열기
   * @param {*} channel
   * @returns
   */
  setCurChannel(state, channel) {
    return (state.curChannel = channel);
  },
  /**
   * @description 채널 업데이트
   */
  updateChannel(state, newChannel) {
    const removedList = state.channelList.filter(
      ({ url }) => url !== newChannel.url,
    );
    state.channelList = [newChannel, ...removedList];
  },
  /**
   * @description 읽지 않은 메시지 리스트 만듬
   * !!! 메시지 수가 너무 많을까봐, 가장 아래 20개의 메시지만 확인함.
   *
   * [실행 케이스]
   * 메시지 보낼 때
   * 채널방 킬 때
   * 상대방이 채널에 들어와 있을 때,
   *
   * @params senderId: 서비스 본인, 보낸 유저(티쳐 또는 보호자) 아이디
   */
  checkUnReadMessage(state) {
    // state.messageMap가 null이면, 상대방 쪽에서 첫 메시지를 보낸 것이므로, return 처리한다.
    if (!state.messageMap) return;
    let messageList = Array.from(state.messageMap.values()).flat(); // map을 list로 푼다.
    if (messageList?.length > 20) messageList = messageList.slice(-20);

    state.unreadMessageIdList = messageList.reduce((acc, message) => {
      if (message.sender?.userId !== state.sendbirdUserId) return acc; // 서비스 본인이 아니면, 확인 안 함.

      const thisMessageReadMemberList =
        state.curChannel?.getReadMembers(message) || [];
      // thisMessageReadMemberList가 빈 배열이면, 이 메시지를 아무도 읽지 않았다는 거임.
      // 보낸 사람의 메시지면서, 상대방이 읽지 않은 것이므로, 해당 메시지 아이디를 추가한다.
      if (!thisMessageReadMemberList?.length) {
        acc = [...acc, message.messageId];
      }
      return acc;
    }, []);
  },
  setCollection(state, collection) {
    state.collection = collection;
  },
  setSendbirdUserId(state, key) {
    return (state.sendbirdUserId = key);
  },
  setSendbirdUserToken(state, token) {
    return (state.sendbirdUserToken = token);
  },
  setUserClassList(state, newList) {
    state.userClassList = newList;
  },
  setHasUnReadMessageCount(state, newList) {
    state.hasUnReadMessageCount = newList;
  },

  // 센드버드 연결 인터발 중지
  stopConnectionSendbirdInterval(state) {
    if (state.connectionInterval === null) return;
    clearInterval(state.connectionInterval);
    state.connectionInterval = null;
  },
  setIsUploadingFile(state, isUploadingFile) {
    state.isUploadingFile = isUploadingFile;
  },
  setIsSendbirdLoading(state, bool) {
    state.isSendbirdLoading = bool;
  },
  setIsSending(state, bool) {
    state.isSending = bool;
  },
};
const actions = {
  // 안 읽은 카운트 호출
  async fetchUnReadMessageCount(store, sb) {
    try {
      const count = await sb.groupChannel.getTotalUnreadMessageCount();
      store.commit('setHasUnReadMessageCount', count > 0);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchChannelList(store) {
    try {
      // 채널 리스트가 더 있으면, hasMore는 true임.
      if (store.state.collection.hasMore) {
        const channelList = await store.state.collection.loadMore();
        store.commit('setChannelList', [
          ...store.state.channelList,
          ...channelList,
        ]);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // 안 읽은 메시지 카운트 조회
  async fetchUnreadMessageCount(state) {
    try {
      const count = await this.$sb.groupChannel.getTotalUnreadMessageCount();
      state.commit('setUnreadCount', count);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  // 센드버드 연결용 토큰 조회
  async fetchSendbirdToken(state) {
    try {
      const res = await this.$axios.$get(
        `${process.env.API_V3}/v3/communication/chat/token/user`,
      );
      state.commit('setSendbirdUserId', res?.data.key || '');
      state.commit('setSendbirdUserToken', res?.data.token || '');
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  /**
   * @description 대화하는 상대방과 연관된 수업 및 스케줄 조회 API
   * @param {*} teacherId  대화 상대방 아이디 2343 숫자
   * @returns
   */
  async fetchSchedulesByClassList(state, teacherId) {
    try {
      const res = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/user-schedules-by-class?teacherId=${teacherId}`,
      );
      state.commit('setUserClassList', res?.data?.items || []);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

  /**
   * @description 상대방에게 채널을 새로 열 때 호출함. 센드버드에 유저 계정이 등록되지 않는 경우가 있어서 그럼.
   * 센드버드에 유저 계정이 안 만들어져 있는데, 채널을 생성하고, 채널에 없는 센드버드 유저 아이디를 추가해도, 그 채널에 초대되지 않음.
   * @param {*} userId  대화 상대방 아이디 2343 숫자
   * @returns { sendbirdId: "teacher-1001", teacherId: 1001 }
   */
  async fetchSendbirdUserId(_, teacherId) {
    try {
      const res = await this.$axios.$get(
        `${process.env.API_V3}/v3/communication/chat/teacher/${teacherId}`,
      );
      return res?.data || null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchHasSendbirdAccount({ state, commit }) {
    if (state.hasSendbirdAccount) return;
    try {
      const res = await this.$axios.$get(
        `${process.env.API_V3}/v3/communication/chat/user/me`,
      );
      commit('setHasSendbirdAccount', res?.data.hasSendbirdAccount);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
