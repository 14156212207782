
import { numberToCommaString } from '@/utils/numberString.js';

export default {
  name: 'PriceInfo',
  props: {
    classType: { type: String, default: null },
    classPrice: { type: Number, default: 0 },
    discountRate: { type: Number, default: 0 },
  },
  computed: {
    isDiscountProduct: ({ discountRate }) =>
      discountRate && parseInt(discountRate) > 0,
    priceType: ({ classType }) =>
      classType === '원데이 클래스' ? '가격' : '회차별',
    originPriceString: ({ classPrice }) =>
      `${numberToCommaString(classPrice)}원`,
    classPriceString: ({ discountRate, classPrice }) => {
      const rate = 100 - parseInt(discountRate);
      const discountedPrice = Math.floor((classPrice * rate) / 100);

      return `${numberToCommaString(discountedPrice)}원`;
    },
  },
};
