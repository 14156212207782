import { getDateISOString } from '@/utils/dateString';
import {
  FILTER_TIME_OPTIONS,
  PRICE_OPTIONS,
} from '@/utils/filter/filterOptions.js';
import { queryEndTimeIdx, queryStartTimeIdx } from '@/utils/filter/filterUtils';
import intl from '@/utils/intl';
import { addDays, getISODay } from 'date-fns';

const _getKeywordQuery = keyword => (keyword ? { keyword } : undefined);

const _getAgeQuery = age => (age ? { age } : undefined);

// classDay가 없으면 선택한 기간 범위만큼의 요일정보 배열값을 쿼리에 넣어서 보내야합니다.
const _getCurrentClassDay = (classStartDate, classEndDate) => {
  const startDate = new Date(classStartDate);
  const endDate = new Date(classEndDate);

  const addTimeZone = date =>
    new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const startDateTemp = addTimeZone(startDate);
  const endDateTemp = addTimeZone(endDate);

  // 시작일과 종료일 사이의 일자 수와 7 중 최소값으로 배열 크기를 만들고 숫자를 채워넣어 classDay를 만들어줍니다.
  const daysBetween = Array.from(
    {
      length: Math.min(
        7,
        Math.ceil((endDateTemp - startDateTemp) / (1000 * 60 * 60 * 24)),
      ),
    },
    // 빈 배열에 값을 체워줍니다
    (_, i) => getISODay(addDays(startDateTemp, i)),
  );

  return daysBetween.sort((a, b) => a - b).join(',');
};

const _getPeriodQuery = ({
  selectedDayOfWeek,
  selectedPeriodOption,
  classStartDate,
  classEndDate,
}) => {
  // 기간 옵션이 선택되지 않았다면 요일 정보만 넘겨줍니다.
  if (!selectedPeriodOption) return { classDay: selectedDayOfWeek };

  const d = new Date();
  const todayISOString = intl.getISOString(new Date());
  const today = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
  const defaultDays = '1,2,3,4,5,6,7';

  const periodOptions = {
    TODAY: {
      classDateStart: todayISOString,
      classDateEnd: todayISOString,
      classDay: getISODay(today),
    },
    SEVEN_DAYS: {
      classDateStart: todayISOString,
      classDateEnd: getDateISOString(addDays(today, 7)),
      classDay: selectedDayOfWeek ?? defaultDays,
    },
    THIRTY_DAYS: {
      classDateStart: todayISOString,
      classDateEnd: getDateISOString(addDays(today, 30)),
      classDay: selectedDayOfWeek ?? defaultDays,
    },
    SELECT_CALENDAR:
      classStartDate && classEndDate
        ? {
            classDateStart: classStartDate,
            classDateEnd: classEndDate,
            classDay:
              selectedDayOfWeek ??
              _getCurrentClassDay(classStartDate, classEndDate),
          }
        : undefined,
  };

  // 선택된 기간 옵션에 따른 값 반환
  return periodOptions[selectedPeriodOption] ?? undefined;
};

const _getTimeQuery = ({ startTimeIdx, endTimeIdx }) => {
  if (startTimeIdx > 0 || endTimeIdx < FILTER_TIME_OPTIONS.length - 1) {
    const startTime =
      FILTER_TIME_OPTIONS[queryStartTimeIdx(startTimeIdx)].value.toString();
    const endTime =
      FILTER_TIME_OPTIONS[queryEndTimeIdx(endTimeIdx)].value.toString();

    return {
      classTimeStart: `${startTime.padStart(2, 0)}:00`,
      classTimeEnd: `${endTime.padStart(2, 0)}:00`,
    };
  } else {
    return undefined;
  }
};

const _getClassTypeQuery = classType => {
  if (!classType) return undefined;
  try {
    const classTypeMap = ['ONEDAY', 'BUNDLE', 'SUBSCRIBE', 'ONETOONE'];
    const classTypeListString = classType
      .split(',')
      .map(a => classTypeMap[parseInt(a, 10)])
      .filter(Boolean) // 유효하지 않은 값 제거
      .join(',');

    return classTypeListString && classTypeListString.length
      ? { classType: classTypeListString }
      : undefined;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

// subCategory가 있으면 우선합니다
const _getCategoryQuery = ({ selectedCategory, selectedSubCategory }) => {
  if (selectedCategory === undefined) return undefined;

  return {
    categories: selectedSubCategory ?? selectedCategory,
  };
};

const _getPriceQuery = selectedPrice => {
  if (!selectedPrice) return undefined;

  const price = PRICE_OPTIONS[selectedPrice];

  return {
    minPrice: price?.minPrice,
    maxPrice: price?.maxPrice,
  };
};

const _getOnlyDiscountQuery = onlyDiscounted =>
  onlyDiscounted ? { onlyDiscounted: true } : undefined;

const _getListType = listType => ({ listType });

const _getDifficulty = difficulty => (difficulty ? { difficulty } : undefined);

/**
 * @description
 * 필터 쿼리 만드는 유틸 함수
 */
const getApiQuery = ({
  keyword,
  selectedAge,
  selectedDayOfWeek,
  selectedPeriodOption,
  selectedSubCategory,
  selectedCategory,
  selectedClassType,
  selectedPrice,
  order,
  listType,
  startTimeIdx,
  endTimeIdx,
  difficulty,
  onlyDiscounted,
  classStartDate, // 2023-01-01
  classEndDate, // 2023-01-01
}) => ({
  ..._getKeywordQuery(keyword),
  ..._getAgeQuery(selectedAge),
  ..._getPeriodQuery({
    selectedDayOfWeek,
    selectedPeriodOption,
    classStartDate,
    classEndDate,
  }),
  ..._getTimeQuery({ startTimeIdx, endTimeIdx }),
  ..._getClassTypeQuery(selectedClassType),
  ..._getCategoryQuery({ selectedCategory, selectedSubCategory }),
  ..._getPriceQuery(selectedPrice),
  ..._getOnlyDiscountQuery(onlyDiscounted),
  ..._getListType(order || listType),
  ..._getDifficulty(selectedCategory ? difficulty : undefined), // 카테고리 선택시에만 들어갈 수 있게
});

export default getApiQuery;
