/**
 * @param {*} somePath
 * @description
 * CloudFront URL : https://d2xeet26kttn3w.cloudfront.net
 * 케이스
 * [카카오 프로필 사진] https://k.kakaocdn.net/dn/dfLXp4/btrD0rLoVCy/maenIGeB1LLwn2tTPjceP1/img_640x640.jpg
 * [완성된 URL] https://gguge-teacher-web-dev.s3.amazonaws.com/v1.7.8/img/common_pattern-bg.ec152fb.png || https://d2xeet26kttn3w.cloudfront.net/feedback/13523/fewfwefwe.txt
 * [http:// || https:// 없이 도메인부터 넘어오는 경우] gguge-images.s3.amazonaws.com/teacher/development/558/cba43df2-a91f-44a3-a612-70a4f799bb08.jpeg
 * [//도메인 으로 시작] //gguge-images.s3.amazonaws.com/{path}
 * [상대경로만 넘어오는 경우] /feedback/13523/fewfwefwe.txt
 */

export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('imgWrapper', (somePath, w = 300) => {
    // string값 말고 다른 값이 들어올 때를 대비
    if (!somePath) return '';

    // [클라우드 프론트 경로 && 카카오 프로필 사진]
    if (
      // somePath.startsWith('https://d2xeet26kttn3w.cloudfront.net') ||
      somePath.includes('k.kakaocdn.net')
    ) {
      return somePath;
    }

    // [완성된 URL]
    if (somePath.startsWith('https://') || somePath.startsWith('http://')) {
      return makeCloudFrontUrlResize(somePath, w);
    }

    // [http:// || https:// 없이 도메인부터 넘어오는 경우]
    if (
      somePath.startsWith('gguge-images.s3') ||
      somePath.startsWith('d2xeet26kttn3w.cloudfront.net')
    ) {
      return makeCloudFrontUrlResize('https://' + somePath, w);
    }
    // [//도메인 으로 시작]
    if (somePath.startsWith('//'))
      return makeCloudFrontUrlResize('http:' + somePath, w);
    // [상대경로만 넘어오는 경우]
    if (!somePath.startsWith('/'))
      return makeCloudFrontUrlResize('http://dummy.com/' + somePath, w);
    return makeCloudFrontUrlResize('http://dummy.com' + somePath, w);
  });
};

const makeCloudFrontUrlResize = (somePath, w) => {
  const { pathname } = new URL(somePath);

  const resizePrefix = pathname.startsWith('/r/') ? '' : '/r';

  const webpSuffix = pathname.endsWith('undefined') ? '.webp' : '';

  return `${process.env.CLOUD_FRONT}${resizePrefix}${pathname}${webpSuffix}?w=${w}`;
};
