import { FILTER_TIME_OPTIONS } from '@/utils/filter/filterOptions.js';

/**
 * @param {*} endTimeIdx  '23'
 * @returns 0 ~ [FILTER_TIME_OPTIONS.length - 2]
 */
export function queryStartTimeIdx(startTimeIdx) {
  const maxIndex = FILTER_TIME_OPTIONS.length - 2;
  const numberIdx = Number(startTimeIdx);
  return isNaN(startTimeIdx) || numberIdx < 0
    ? 0
    : numberIdx > maxIndex
    ? maxIndex
    : numberIdx;
}
/**
 * @param {*} endTimeIdx  '23'
 * @returns 1 ~ 14
 */
export function queryEndTimeIdx(endTimeIdx) {
  const maxIndex = FILTER_TIME_OPTIONS.length - 1;
  const numberIdx = Number(endTimeIdx);
  return isNaN(numberIdx) || numberIdx <= 0
    ? maxIndex
    : numberIdx > maxIndex
    ? maxIndex
    : numberIdx;
}

/**
 * @description
 * "9,8" -> [9,8]
 * @return
 */
export function queryNumberList(numberString) {
  return (
    numberString
      ?.split(',')
      .map(a => parseInt(a))
      .filter(a => !isNaN(a)) || []
  );
}
