export const actions = {
  /**
   * @description
   * teacher 유저의 데이터를 받아오는 api
   */
  selectTeacher(_, { teacherId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/teacher/${teacherId}`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * teacher 유저의 클래스 목록을 받아오는 api
   */
  async selectTeacherClass(
    _,
    { teacherId, page = 1, limit = 10, isPrivate = false },
  ) {
    try {
      const { items, meta } = await this.$axios.$get(
        `/v2/product/teacher/${teacherId}`,
        {
          params: {
            page,
            limit,
            isPrivate,
          },
        },
      );

      return { items: items || [], meta };
    } catch (e) {
      console.error(e);
      return { items: [] };
    }
  },
  /**
   * @description
   * teacher 유저의 리뷰 목록을 받아오는 api
   */
  selectTeacherReview(_, { teacherId, page, limit, listType }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/review/teacher/${teacherId}`, {
          params: { page, limit, listType },
        })
        .then(res => resolve(res))
        .catch(e => reject(e));
    });
  },
};
