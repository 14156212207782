function getPathAt(route) {
  return (route.matched && route.matched[0] && route.matched[0].path) || '/';
}

// from의 경우 새로고침하면 사라지므로 오류방지 & 새로고침한 로그는 from이 안잡히게
function getPathFrom(nuxtContext) {
  const fromPath =
    nuxtContext &&
    nuxtContext.from &&
    nuxtContext.from.matched &&
    nuxtContext.from.matched[0] &&
    nuxtContext.from.matched[0].path;

  // index(home)에서 온 경우 최종 fromPath가 '' 으로 잡혀서 후 처리를 해줍니다
  if (fromPath === '') {
    return '/';
  } else {
    return fromPath || '';
  }
}

export { getPathAt, getPathFrom };
