export const actions = {
  selectCuration(_, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/product/curation/${id}`)
        .then(res => {
          if (!res || res.length <= 0) resolve(res);
          const result = res.map(el => {
            return {
              ...el.product,
              isFull: el.isFull,
              hasSchedule: el.hasSchedule,
            };
          });
          resolve({
            title: res[0]?.curation?.title || '',
            summary: res[0]?.curation?.summary || '',
            items: result,
            meta: {
              currentPage: 1,
              totalPages: 1,
              itemsPerPage: res.length,
              totalItems: res.length,
            },
          });
        })
        .catch(reject);
    });
  },
};
