
import { getIsApp, setLastClickSection } from '@/utils/localStorageUtils.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import Alert from '@/components/common/Alert';
import BottomSheet from '@/components/common/bottomSheet/BottomSheet.vue';
import NavigationBar from '@/components/common/nav/NavigationBar.vue';

export default {
  components: { NavigationBar, Alert, BottomSheet },
  computed: {
    ...mapGetters(['resultBgImg', 'isAlert', 'alert']),
    bgImg() {
      return {
        backgroundImage: this.resultBgImg,
      };
    },
    isApp() {
      if (process.client) {
        return this.$route.query.isApp || getIsApp() === true;
      } else {
        return this.$route.query.isApp;
      }
    },
  },
  created() {
    this.getProductCategoryList();
  },
  methods: {
    ...mapActions(['openSimpleAlert']),
    ...mapActions('filter', ['getProductCategoryList']),
    ...mapMutations({ openAlert: 'OPEN_ALERT' }),
    clickLogEvent(e) {
      // dataset이 있고, 그 중에 apSection이 있다면 앰플에 잡고 싶은 section
      const sectionData = e
        .composedPath()
        .find(el => el.dataset && 'apSection' in el.dataset);
      if (sectionData) {
        const sectionName = sectionData.dataset.apSection;
        setLastClickSection(sectionName);
      }
    },
  },
};
