
/**
 * @description
 * svg의 path부분
 */
export default {
  name: 'SvgPath',
  props: {
    path: { type: Object, required: true },
  },
};
