const CommunicationSet = {
  star: {
    drawings: [
      {
        type: 'path',
        d: 'M21.4039 6.48281C22.5595 4.4874 25.4405 4.48741 26.5961 6.48282L30.8145 13.7671C31.2373 14.4971 31.9479 15.0154 32.7722 15.1949L40.9906 16.9848C43.2319 17.4729 44.1184 20.1937 42.5949 21.9086L36.974 28.2355C36.4169 28.8625 36.1478 29.694 36.2318 30.5285L37.0799 38.9531C37.3104 41.2428 34.9834 42.9287 32.8794 41.9963L25.2156 38.5996C24.4415 38.2566 23.5585 38.2566 22.7844 38.5996L15.1206 41.9963C13.0166 42.9287 10.6896 41.2428 10.9201 38.9531L11.7682 30.5285C11.8522 29.694 11.5831 28.8625 11.026 28.2355L5.40509 21.9086C3.88158 20.1937 4.76811 17.4729 7.00944 16.9848L15.2278 15.1949C16.0521 15.0154 16.7627 14.4971 17.1855 13.7671L21.4039 6.48281Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
  starLeft: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M23.9973 5.19165C22.9707 5.19165 21.9441 5.70709 21.373 6.73796L17.1692 14.3269C16.7555 15.0737 16.0433 15.6089 15.211 15.7984L6.82068 17.7088C4.6246 18.2088 3.74217 20.8603 5.20065 22.5766L11.0755 29.4899C11.6009 30.1081 11.8534 30.9127 11.7757 31.7201L10.9023 40.7905C10.6801 43.0976 13.0467 44.7745 15.1497 43.8L22.7361 40.2849C23.1361 40.0996 23.5667 40.0069 23.9973 40.0069V5.19165Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
  starRight: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M24 40.0062C24.4306 40.0062 24.8612 40.0989 25.2612 40.2842L32.8476 43.7993C34.9507 44.7737 37.3172 43.0969 37.0951 40.7897L36.2216 31.7194C36.1439 30.9119 36.3965 30.1074 36.9218 29.4892L42.7967 22.5758C44.2551 20.8596 43.3727 18.2081 41.1766 17.708L32.7864 15.7977C31.954 15.6081 31.2418 15.073 30.8281 14.3262L26.6243 6.73723C26.0532 5.70635 25.0266 5.19092 24 5.19092V40.0062Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
  share: {
    drawings: [
      {
        type: 'circle',
        cx: '33',
        cy: '13',
        r: '5',
        stroke: 'currentColor',
        'stroke-width': '3',
      },
      {
        type: 'circle',
        cx: '15',
        cy: '24',
        r: '5',
        stroke: 'currentColor',
        'stroke-width': '3',
      },
      {
        type: 'circle',
        cx: '33',
        cy: '35',
        r: '5',
        stroke: 'currentColor',
        'stroke-width': '3',
      },
      {
        type: 'path',
        d: 'M28 16L20 21',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M28 32L20 27',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const CommunicationNames = Object.keys(CommunicationSet);

export { CommunicationNames, CommunicationSet };
