import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';

export const actions = {
  /**
   * @description
   * b-ai로 추천받은 해당 상품과 관련된 수업을 받아옵니다
   */
  async getProductRelativeProductList(_, { userId, productId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/${productId}/related`,
        { params: { userId } },
      );

      // 데이터 가공
      const convertClassList = convertV3ClassToV2Product(data);

      return convertClassList || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
