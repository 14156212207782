
export default {
  name: 'PsycheLogo',
  data() {
    return {
      ggugeLogo: require('@/assets/img/layout/logo_full.svg'),
      ggugeXHuno: require('@/assets/img/psyche/huno_x.svg'),
      hunoLogo: require('@/assets/img/psyche/huno_logo.svg'),
    };
  },
  computed: {
    isHunoPage() {
      return this.$route.fullPath.startsWith('/psyche/huno');
    },
  },
};
