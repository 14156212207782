
/**
 * @description
 * 상품 컴포넌트 썸네일 위에 덮는 딤 필터
 */
export default {
  name: 'DimFilter',
  props: {
    text: { type: String, default: null },
  },
};
