
/**
 * @description
 * 큐레이션 아이템에 쓰이는 태그 리스트
 */
import { convertClassType } from '@/utils/classType.js';
import { TagEnums } from '@/utils/tag/constants.js';

import Tag from '@/components/common/tag/Tag.vue';

export default {
  name: 'TagList',
  components: { Tag },
  props: {
    classType: { type: String, default: null },
    classAge: { type: String, default: null },
    classCount: { type: Number, default: 0 },
    useClassCountTag: { type: Boolean, default: false },
  },
  computed: {
    // 우선순위 =  클래스타입 / 나이 / 회차
    tagList() {
      const returnList = [];
      const classTypeText = convertClassType(this.classType).replace(
        ' 클래스',
        '',
      );
      classTypeText &&
        returnList.push({
          tagText: classTypeText,
          tagType: TagEnums.INFO,
        });

      if (this.classAge) {
        returnList.push({
          tagText: this.classAge,
          tagType: TagEnums.INFO,
        });
      }

      if (this.useClassCountTag && this.classCount > 0) {
        const tagText =
          this.classCount >= 20 ? `20회 이상` : `${this.classCount}회`;
        returnList.push({
          tagText,
          tagType: this.classCount > 4 ? TagEnums.PRIMARY : TagEnums.SUCCESS,
        });
      }

      return returnList;
    },
  },
};
