/**
 * @description
 * 숫자값을 받아서 시간, 분, 초로 쪼개주는 유틸 함수
 * @return hh, mm, ss
 */
export const getHHMMSS = timer => {
  if (timer < 0) return { hh: '00', mm: '00', ss: '00' };

  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;

  const dd = Math.floor(timer / _day);
  const hh = Math.floor((timer % _day) / _hour)
    .toString()
    .padStart(2, '0');
  const mm = Math.floor(((timer % _day) % _hour) / _minute)
    .toString()
    .padStart(2, '0');
  const ss = Math.floor((((timer % _day) % _hour) % _minute) / _second)
    .toString()
    .padStart(2, '0');

  return { dd, hh, mm, ss };
};
