
import ProgressSpinner from '@/components/common/ProgressSpinner';

/**
 * box button 컴포넌트에 직접 class를 입히는 방식
 **/
export default {
  name: 'BoxButton',
  components: { ProgressSpinner },
  props: {
    type: { type: String, default: 'button' },
    text: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    useLeftIcon: { type: Boolean, default: false },
    useRightIcon: { type: Boolean, default: false },
    leftIcon: { type: String, default: 'gi-small_down_caret' },
    rightIcon: { type: String, default: 'gi-small_down_caret' },
    isPending: { type: Boolean, default: false },
  },
  computed: {
    // body slot에 값이 있는지 판별
    hasBodySlot: ({ $slots }) => !!$slots.body,
  },
  methods: {
    onClick($event) {
      if (this.isPending) return;
      this.$emit('click', $event);
    },
  },
};
