/**
 * @description
 * 상품 가격 계산용 유틸 함수
 */

import { classTypeEnum, convertClassType } from '@/utils/classType.js';

/**
 * @description
 * - classPrice 가격
 * - discountRate 할인율
 * - classType 상품 유형
 * - classCount 상품 수
 * @returns {{ basePrice: number, discountedPrice: number, calculatedPrice: number }}
 */
function getCalculatedPrice({
  classPrice,
  discountRate,
  classType,
  classCount,
}) {
  // 필수값이 없으면 0원으로 얼리리턴
  if (classPrice === undefined || !classType)
    return {
      basePrice: 0, // 상품 기본 가격
      discountedPrice: 0, // 할인 적용된 가격
      calculatedPrice: 0, // 할인 적용 + 다회차의 경우 회차별로 나눠져 계산된 별개의 가격
    };

  const convertedClassType = convertClassType(classType);

  const discountedPrice = getDiscountPrice({ classPrice, discountRate });
  const calculatedPrice =
    convertedClassType === classTypeEnum.BUNDLE
      ? getBundlePrice({ classPrice, discountRate, classCount })
      : discountedPrice;

  return {
    basePrice: classPrice, // 상품 기본 가격
    discountedPrice, // 할인 적용된 가격
    calculatedPrice, // 할인 적용 + 다회차의 경우 회차별로 나눠져 계산된 별개의 가격
  };
}

// 할인율 적용된 가격
const getDiscountPrice = ({ classPrice, discountRate }) => {
  if (!discountRate) return classPrice;

  const rate = 100 - parseInt(discountRate);
  return Math.floor((classPrice * rate) / 100);
};

// 다회차 수업 계산
const getBundlePrice = ({ classPrice, discountRate, classCount }) => {
  const totalPrice = classPrice * classCount;
  if (!discountRate) return totalPrice;

  const rate = 100 - parseInt(discountRate);
  const discountPrice = Math.floor((classPrice * rate) / 100); // 회차당 할인된 가격
  return discountPrice * classCount;
};

export { getCalculatedPrice };
