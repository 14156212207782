export const actions = {
  /**
   * @description
   * 홈 화면에 seo에 쓸 검색어, 메타태그 리스틀 받아오는 api
   */
  async getMetaTagList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category/tag`,
      );
      return data || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 화면 하단 소셜 프루프 영역의 데이터를 받아오는 api
   */
  async getSocialProofData() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/etc/sign-up-social-proof`,
      );

      return data;
    } catch (e) {
      console.error(e);
      // 에러시 기존 디폴트 데이터를 내려줍니다.
      return {
        yearMonth: '2024-08',
        userCount: 112197,
        teacherCount: 714,
        classCount: 4366,
      };
    }
  },
};
