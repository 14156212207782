/* AppStrings */

export const ORDER_INVALID = {
  productOption: '수업 유형을 선택해 주세요.',
  children: '수업 참여 아이를 선택해 주세요.',
  schedule: '수업 날짜 및 시간을 선택해 주세요.',
  capacityOver:
    '정원이 초과된 스케줄입니다. 수업 날짜 및 시간을 다시 선택해 주세요.',
  allCapacityOver: '모든 스케줄의 정원이 초과되었습니다.',
  endDateOver: '종강된 스케줄입니다. 수업 날짜 및 시간을 다시 산턱해 주세요.',
  shipping: '준비물 배송 정보를 확인해 주세요.',
  paymentOption: '결제 방법을 선택해 주세요.',
  billingPaymentCard: '카드를 등록해 주세요.',
  subsPayment: '꾸그 머니 충전 또는 결제 카드를 등록해주세요.',
  moneyValue: '금액을 선택해 주세요.',
  lack: '꾸그머니를 구매해 주세요.',
  terms: '필수 약관에 동의해 주세요.',
  duplicated:
    '동일한 스케줄을 이미 구매하셨습니다. 다른 아이 또는 다른 날짜를 선택해주세요.',
  notiError:
    '결제 정보 생성 중 오류가 발생했습니다. 잠시 후 다시 시도해 보세요.',
  ggugeMall: '수업 교구재 내용 확인을 선택해 주세요',
  noUse: '현재 판매중인 상품이 아닙니다.',
};

export const INVALID_ACCESS = '잘못된 접근입니다.';
export const AUTH_NEEDED = '로그인이 필요합니다.';

export const SITE_TITLE = '온라인 라이브 스쿨';
export const SITE_DESCRIPTION =
  '첫 수업은 무료 체험! 부모님들이 선택한 BEST 수업을 집에서 만나세요.';
export const SITE_KEYWORDS =
  '온라인,교육,선행학습,라이브,초등학생,중학생,고등학생,유치원,어린이집,줌수업,부모교육,홈스쿨링';

// 대화 신고
export const REASON_OPTION = {
  abusive: '욕설, 비방, 차별, 혐오',
  personal: '개인 정보 노출, 유포, 거래',
  operate: '운영 / 서비스 관련',
  mediate: '중재 요청',
  spam: '도배, 스팸',
  etc: '기타',
};

// 대화
export const reported = 'REPORTED'; // 대화 기능 신고된 메시지
export const complete = 'COMPLETE'; // 대화 기능 신고된 메시지 -> 어드민에서 아무 처리도 안하고, 처리 완료함.
export const deleted = 'DELETED'; // 대화 기능 삭제된 메시지
export const expired = 'EXPIRED'; // 대화 기능 삭제된 메시지
export const requested = 'REQUESTED';
// export const disable = 'DISABLE'; // 선생님 계약 종료 상태
export const expiredHelpMessage = '유효기간이 만료된 파일입니다.';

export const initiateChatPath = {
  'product-id': 'PRODUCT_DETAIL',
  'teacher-id': 'TEACHER_DETAIL',
  'schedule-hash': 'SCHEDULE_DETAIL',
};

export const ampLoggingInitiateChatPath = {
  'product-id': 'product_detail',
  'teacher-id': 'teacher_detail',
  'schedule-hash': 'schedule_detail',
  chat: 'chat_list',
};

export const displayPaymentStatusText = status => {
  switch (status) {
    case 'COMPLETE':
      return '결제 완료';
    case 'PART_CANCEL':
      return '부분 환불';
    case 'CANCEL':
      return '전액 환불';
    default:
      return '';
  }
};
