import { contentsRenderer } from '@/utils/contentRender';

export const actions = {
  async getClassNoticeList(_, { teacherId, productIds }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/teacher/product-notice`,
        {
          params: {
            teacherId,
            productIds,
            page: 1,
            size: 99, // 공지 노출 갯수 제한 없음
            isPublic: true,
          },
        },
      );
      return data.items;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  async getClassNoticeDetail(_, _id) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/teacher/product-notice/${_id}`,
      );

      return {
        ...data,
        content: contentsRenderer(data.content),
        createdAt: new Date(data.createdAt),
        updatedAt: new Date(data.updatedAt),
      };
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
};
