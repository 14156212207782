import {
  AGE_OPTIONS,
  FILTER_ORDER_OPTIONS,
} from '@/utils/filter/filterOptions.js';

export const initState = {
  // '검색어'
  keyword: '',
  // 일자 & 수업 시간 필터
  startTimeIdx: undefined,
  endTimeIdx: undefined,
  selectedPeriodOption: undefined, // '날짜'
  selectedDayOfWeek: [], // '요일', 중복 선택 됨.
  selectedWeekType: undefined, // '주차', 중복 선택 안 됨.
  classStartDate: undefined,
  classEndDate: undefined,

  // '연령'
  selectedAge: [],

  // 이 외의 필터
  order: FILTER_ORDER_OPTIONS[0].value, // '정렬'
  selectedClassType: [], // '수업 유형'
  selectedCategory: undefined, // '카테고리'
  selectedSubCategory: undefined, // '서브 카테고리'
  difficulty: undefined, // '난이도'
  selectedPrice: undefined, // '가격'
  onlyDiscounted: undefined, // '할인된 수업만 보기'
};

// 단순 반영용으로 사용
// '적용' 버튼 누르기 전까지 데이터를 들고 있는다.
// '적용' 버튼을 누르면 초기화됨.
export const state = () => Object.assign({}, initState);

export const getters = {
  keyword: state => state.keyword,
  startTimeIdx: state => state.startTimeIdx,
  endTimeIdx: state => state.endTimeIdx,
  selectedPeriodOption: state => state.selectedPeriodOption,
  selectedDayOfWeek: state => state.selectedDayOfWeek,
  selectedWeekType: state => state.selectedWeekType,
  classDateRange: state => {
    return {
      classStartDate: state.classStartDate,
      classEndDate: state.classEndDate,
    };
  },
  selectedAge: state => state.selectedAge,
  order: state => state.order,
  selectedClassType: state => state.selectedClassType,
  selectedCategory: state => state.selectedCategory,
  selectedSubCategory: state => state.selectedSubCategory,
  selectedPrice: state => state.selectedPrice,
  onlyDiscounted: state => state.onlyDiscounted,
  selectedDifficulty: state => state.difficulty,
  // '적용' 버튼 클릭 시 필터링에 사용될 데이터
  queryFilter: state => {
    return {
      ...state,
      selectedDayOfWeek: state.selectedDayOfWeek?.length
        ? state.selectedDayOfWeek.join(',')
        : undefined,
      selectedAge: state.selectedAge.length
        ? state.selectedAge.length === AGE_OPTIONS.length
          ? undefined
          : state.selectedAge.join(',')
        : undefined,
      selectedClassType: state.selectedClassType.length
        ? state.selectedClassType.join(',')
        : undefined,
      onlyDiscounted: state?.onlyDiscounted ? true : undefined,
    };
  },
};

export const mutations = {
  SET_KEYWORD(state, keyword) {
    state.keyword = keyword;
  },
  SET_START_TIME_IDX(state, time) {
    state.startTimeIdx = time;
  },
  SET_END_TIME_IDX(state, time) {
    state.endTimeIdx = time;
  },
  SET_SELECTED_PERIOD_OPTION(state, period) {
    state.selectedPeriodOption = period;
  },
  SET_SELECTED_DAY_OF_WEEK(state, day) {
    state.selectedDayOfWeek = day;
  },
  SET_SELECTED_WEEK_TYPE(state, weekType) {
    state.selectedWeekType = weekType;
  },
  SET_SELECTED_AGE(state, age) {
    state.selectedAge = age;
  },
  SET_CLASS_DATE(state, classDateRange) {
    state.classStartDate = classDateRange.classStartDate;
    state.classEndDate = classDateRange.classEndDate;
  },

  // 초기화
  ALL_RESET_SEARCH_FILTER(state) {
    Object.assign(state, { ...initState });
  },
  RESET_CLASS_DATE(state) {
    state.classStartDate = null;
    state.classEndDate = null;
  },
  // 초기화
  RESET_AGE(state) {
    state.selectedAge = [];
  },
  // 초기화
  RESET_SCHEDULE(state) {
    state.startTimeIdx = undefined;
    state.endTimeIdx = undefined;
    state.selectedPeriodOption = undefined; // '날짜', 중복 선택이 안 됨.
    state.selectedDayOfWeek = [];
    state.selectedWeekType = undefined;
    state.classStartDate = undefined;
    state.classEndDate = undefined;
  },
  // 초기화
  RESET_MULTI_FILTER(state) {
    state.order = FILTER_ORDER_OPTIONS[0].value; // '정렬'
    state.selectedClassType = []; // '수업 유형'
    state.selectedCategory = undefined; // '카테고리'
    state.selectedSubCategory = undefined; // '카테고리'
    state.selectedPrice = undefined; // '가격'
    state.difficulty = undefined; // '난이도'
    state.onlyDiscounted = undefined; // '할인된 수업만 보기'
  },
  // '정렬'
  SET_ORDER(state, order) {
    state.order = order;
  },
  // '수업 유형'
  SET_CLASS_TYPE(state, selectedClassType) {
    state.selectedClassType = selectedClassType;
  },
  // '카테고리'
  SET_CATEGORY(state, selectedCategory) {
    state.selectedCategory = selectedCategory;
  },
  // '카테고리'
  SET_SUB_CATEGORY(state, selectedSubCategory) {
    state.selectedSubCategory = selectedSubCategory;
  },
  // '가격'
  SET_PRICE(state, selectedPrice) {
    state.selectedPrice = selectedPrice;
  },
  // 할인된 수업만 보기
  SET_ONLY_DISCOUNTED(state, onlyDiscounted) {
    state.onlyDiscounted = onlyDiscounted;
  },
  // 난이도
  SET_DIFFICULTY(state, selectedDifficulty) {
    state.difficulty = selectedDifficulty;
  },
  // 필터 모달이 화면에 created 될 때, route.query를 store에 반영
  SET_QUERY_TO_STORE(state, queryFilter) {
    Object.assign(state, queryFilter);
  },
};
