export const state = () => ({
  teacher: null,
  channelId: null,
});

export const actions = {
  createPrivateRequest(
    { state, commit },
    { teacherId, subject, classCount, memberCount, childrenAges, childNote },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/class/private-class/request`, {
          teacherId,
          subject,
          classCount,
          memberCount,
          childrenAges,
          childNote,
        })
        .then(res => {
          commit('SET_CHANNEL_ID', res.data.channelId);
          resolve(res);
        })
        .catch(reject);
    });
  },
  setTeacher({ commit }, teacher) {
    commit('SET_TEACHER', teacher);
  },
};

export const getters = {
  teacherId: ({ teacher }) => teacher?.id,
  teacherName: ({ teacher }) => teacher?.name,
  teacherProfileImg: ({ teacher }) => teacher?.profileImg,
  privateClassDescription: ({ teacher }) =>
    teacher?.privateClassDescription || '',
  channelId: ({ channelId }) => channelId,
};

export const mutations = {
  SET_TEACHER(state, teacher) {
    state.teacher = teacher;
  },
  SET_CHANNEL_ID(state, channelId) {
    state.channelId = channelId;
  },
};
