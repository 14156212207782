export const FILTER_ORDER_OPTIONS = [
  { value: 'RELEVANCE', text: '관련순' },
  { value: 'POPULARITY', text: '인기순' },
  { value: 'RELEASE_DATE', text: '신규순' },
  { value: 'RATING', text: '평점순' },
  { value: 'LOW_PRICE', text: '낮은 가격순' },
  { value: 'HIGH_PRICE', text: '높은 가격순' },
];

export const FILTER_PERIOD_OPTIONS = [
  { value: 'TODAY', text: '오늘', useCalendar: false },
  // { value: 'ALL_DATES', text: '모든 일정', useCalendar: false },
  { value: 'SEVEN_DAYS', text: '오늘부터 7일', useCalendar: false },
  { value: 'THIRTY_DAYS', text: '오늘부터 30일', useCalendar: false },
  { value: 'SELECT_CALENDAR', text: '직접 선택', useCalendar: true },
];

export const DAY_OF_WEEK = [
  { value: 1, text: '월' },
  { value: 2, text: '화' },
  { value: 3, text: '수' },
  { value: 4, text: '목' },
  { value: 5, text: '금' },
  { value: 6, text: '토' },
  { value: 7, text: '일' },
];

export const WEEK_TYPE = [
  { value: 'WEEK_DAY', text: '평일', dayOfWeek: [1, 2, 3, 4, 5] },
  { value: 'WEEK_END', text: '주말', dayOfWeek: [6, 7] },
  { value: 'ALL_DAYS', text: '매일', dayOfWeek: [1, 2, 3, 4, 5, 6, 7] },
];

const TIME_RANGE_MIN = 1;
const TIME_RANGE_MAX = 23;

const _timeToText = time => {
  if (time === 12) return '오후 12시';
  if (time === 24) return '오전 12시';

  if (time > 12) {
    return `오후 ${time - 12}시`;
  } else {
    return `오전 ${time}시`;
  }
};

export const timeOptionsMaker = (minTime, maxTime) => {
  const result = [];
  let j = 0;
  for (let i = minTime; i < maxTime + 1; i++) {
    result.push({ text: _timeToText(i), value: i, timeIdx: j++ });
  }
  return result;
};

/**
 * @returns
 * [
      {text: '오전 7시', value: 7, timeIdx: 0}
      {text: '오전 8시', value: 8, timeIdx: 1}
      {text: '오전 9시', value: 9, timeIdx: 2}
      {text: '오전 10시', value: 10, timeIdx: 3}
      {text: '오전 11시', value: 11, timeIdx: 4}
      {text: '오후 12시', value: 12, timeIdx: 5}
      {text: '오후 1시', value: 13, timeIdx: 6}
      {text: '오후 2시', value: 14, timeIdx: 7}
      {text: '오후 3시', value: 15, timeIdx: 8}
      {text: '오후 4시', value: 16, timeIdx: 9}
      {text: '오후 5시', value: 17, timeIdx: 10}
      {text: '오후 6시', value: 18, timeIdx: 11}
      {text: '오후 7시', value: 19, timeIdx: 12}
      {text: '오후 8시', value: 20, timeIdx: 13}
      {text: '오후 9시', value: 21, timeIdx: 14}
 * ]
 */
export const FILTER_TIME_OPTIONS = timeOptionsMaker(
  TIME_RANGE_MIN,
  TIME_RANGE_MAX,
);

export const AGE_OPTIONS = [
  { value: 5, text: '5세' },
  { value: 6, text: '6세' },
  { value: 7, text: '7세' },
  { value: 8, text: '8세' },
  { value: 9, text: '9세' },
  { value: 10, text: '10세' },
  { value: 11, text: '11세' },
  { value: 12, text: '12세' },
  { value: 13, text: '13세' },
  { value: 14, text: '14세' },
  { value: 15, text: '15세' },
  { value: 16, text: '16세' },
  { value: 17, text: '17세' },
  { value: 18, text: '18세' },
  { value: 19, text: '19세' },
  { value: 20, text: '20세+' },
];

export const GRADE_OPTIONS = [
  { value: 5, text: '예비초(5세)' },
  { value: 6, text: '예비초(6세)' },
  { value: 7, text: '예비초(7세)' },
  { value: 8, text: '초1' },
  { value: 9, text: '초2' },
  { value: 10, text: '초3' },
  { value: 11, text: '초4' },
  { value: 12, text: '초5' },
  { value: 13, text: '초6' },
  { value: 14, text: '중1' },
  { value: 15, text: '중2' },
  { value: 16, text: '중3' },
  { value: 17, text: '고1' },
  { value: 18, text: '고2' },
  { value: 19, text: '고3' },
  { value: 20, text: '성인' },
];

export const CLASS_TYPE = [
  {
    value: 0,
    text: '원데이',
    classType: '원데이 클래스',
    help: '1회로 완성되는 수업',
  },
  {
    value: 1,
    text: '다회차',
    classType: '꾸러미 클래스',
    help: '큰 주제를 단계별로 배우는 여러회차 수업',
  },
  {
    value: 2,
    text: '구독',
    classType: '롤링 클래스',
    help: '매회 다른 내용으로 만나는 정기 수업',
  },
  { value: 3, text: '1:1', classType: '1:1', help: '선생님과 단둘이 수업' },
];

export const PRICE_OPTIONS = [
  { value: '0', text: '10,000원 이하', minPrice: null, maxPrice: 10000 },
  { value: '1', text: '15,000원 이하', minPrice: null, maxPrice: 15000 },
  { value: '2', text: '20,000원 이하', minPrice: null, maxPrice: 20000 },
  { value: '3', text: '30,000원 이하', minPrice: null, maxPrice: 30000 },
  { value: '4', text: '30,000원 초과', minPrice: 30001, maxPrice: null },
];

export const INIT_CATEGORY = {
  selectedCategory: null,
  categoryOptions: null,
};
