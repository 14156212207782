
/**
 * @description
 * 로드맵 > 태그 리스트
 */

import { TagEnums } from '@/utils/tag/constants.js';

import Tag from '@/components/common/tag/Tag.vue';

export default {
  name: 'TagList',
  components: { Tag },
  props: {
    categoryName: { type: String, default: null },
    minAge: { type: Number, default: -1 },
    maxAge: { type: Number, default: -1 },
  },
  computed: {
    // 우선순위 = 카테고리 / 나이
    tagList() {
      const returnList = [];

      if (this.categoryName)
        returnList.push({
          tagText: this.categoryName,
          tagType: TagEnums.INFO,
        });

      if (this.minAge > -1 && this.maxAge > -1) {
        returnList.push({
          tagText: `${this.minAge}~${this.maxAge}세`,
          tagType: TagEnums.INFO,
        });
      }

      return returnList;
    },
  },
};
