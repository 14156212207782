
import { COMPONENT_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';

/**
 * @description
 * 홈 화면 하단에 뜨는 회원가입 유도 버튼
 */
export default {
  name: 'SignupNudge',
  data: () => {
    return {
      sectionName: COMPONENT_NAME.homeBenefit,
    };
  },
  methods: {
    onClickSignup() {
      this.logSignUpEvent(this.sectionName);
      this.requestToken({ section: this.sectionName });
    },
  },
};
