import { makeDateTextFormatKo } from '@/utils/dateFnsUtils';
import { differenceInHours, getISODay } from 'date-fns';

/**
 * @description
 * 원데이 스케줄 리스트를 받아서
 * 시작시간이 현재 시간을 지나 들을 수 없는 스케줄을 거르고,
 * 스케줄 선택부에 사용하는 추가 데이터들을 주입시켜 내려주는 함수
 * @params schedules
 */
export const makeOnedayScheduleData = schedules => {
  if (!schedules) return [];
  // 시작시간이 현재 시간을 지나 들을 수 없는 스케줄 걸러내기
  const _filterPastSchedule = onedaySchedules => {
    return onedaySchedules.reduce((sList, s) => {
      const classDateKoreanTime = new Date(
        `${s.classDate}T${s.classTimeStart}:00+09:00`,
      );
      if (new Date() <= classDateKoreanTime) {
        return sList.concat({ ...s, classDateKoreanTime });
      } else {
        return sList;
      }
    }, []);
  };

  const filteredSchedule = _filterPastSchedule(schedules);

  const scheduleList = filteredSchedule.map(schedule => {
    const returnScheduleObj = {
      scheduleId: schedule.id,
      scheduleGroupId: schedule.groupNo,
      joinedCount: schedule.joinedCount,
      maxUser: schedule.maxCount,
      isCapacityOver: false,
      scheduleList: [{ ...schedule }],
      headText: null,
      weekDayList: [],
      classTimeText: null,
    };

    const scheduleStartDate = new Date(
      `${schedule.classDate}T${schedule.classTimeStart}`,
    );

    returnScheduleObj.scheduleStartDate = scheduleStartDate;

    // 데이터 가공
    returnScheduleObj.weekDayList = [getISODay(scheduleStartDate)];

    const { maxCount, joinedCount } = schedule;

    if (maxCount <= joinedCount) returnScheduleObj.isCapacityOver = true;

    returnScheduleObj.headText = makeDateTextFormatKo(
      scheduleStartDate,
      'M월 d일',
    );
    returnScheduleObj.classTimeText = makeDateTextFormatKo(
      scheduleStartDate,
      'aa h시 m분',
    ).replace(' 0분', '');

    return returnScheduleObj;
  });

  return scheduleList;
};

/**
 * @description
 * 다회차(꾸러미) 스케줄 리스트를 받아 스케줄 선택부에서
 * 사용하는 추가 데이터를 가공해서 내려주는 함수
 * @param {@} schedules
 * @returns
 * [{
 *  ...schedules data
 *  headText // 스케줄 시작과 끝일자를 합쳐서 만든 텍스트
 *  weekDayList // 스케줄이 있는 요일 리스트 ex) 월, 화, 토
 *  isCapacityOver // 스케줄 정원 초과 유무
 *  groupScheduleClassDateList // 스케줄 전체 일자 데이터 ex) {"month":"6월","date":"12일","day":"(월)","time":"오후 4시 20분","isFirstMonthSchedule":true}
 * }, {...}]
 */
export const makeGroupScheduleData = schedules => {
  if (!schedules) return [];

  const scheduleList = schedules.map(schedule => {
    const returnScheduleObj = {
      scheduleId: schedule.id,
      scheduleGroupId: schedule.groupNo,
      joinedCount: schedule.joinedCount,
      maxUser: schedule.maxCount,
      isCapacityOver: false,
      scheduleList: schedule.groupSchedules,
      headText: null,
      weekDayList: [],
    };

    // 그룹 스케줄
    const groupScheduleClassDateList = [];
    // 그룹 스케줄 데이터 가공중 같은 달에 스케줄이 있는걸 체크하기 위한 배열
    const monthOverlappedCheckList = [];
    let firstScheduleText = '';
    let lastScheduleText = '';

    schedule.groupSchedules.forEach((gs, i) => {
      const scheduleStartDate = new Date(
        `${gs.classDate}T${gs.classTimeStart}`,
      );

      // 해당 다회차 스케줄의 묶음 스케줄 데이터를 가공해 맵 만들기
      const month = makeDateTextFormatKo(scheduleStartDate, 'M월');
      const scheduleDateString = makeDateTextFormatKo(
        scheduleStartDate,
        'd일,(EE),aa h시 mm분',
      );
      const [date, day, time] = scheduleDateString.split(',');

      const scheduleData = {
        month,
        date,
        day,
        time: time.replace(' 00분', ''),
      };

      if (!monthOverlappedCheckList.includes(month)) {
        monthOverlappedCheckList.push(month);
        scheduleData.isFirstMonthSchedule = true;
      }

      groupScheduleClassDateList.push(scheduleData);

      // 시작 스케줄일 때 추가로직
      if (i === 0) {
        firstScheduleText = makeDateTextFormatKo(scheduleStartDate, 'M월 d일');
        returnScheduleObj.scheduleStartDate = scheduleStartDate;
      }
      // 마지막 스케줄일 때 추가로직
      if (i === schedule.groupSchedules.length - 1) {
        lastScheduleText = makeDateTextFormatKo(scheduleStartDate, 'M월 d일');
      }

      const dayIndex = getISODay(scheduleStartDate);

      if (!returnScheduleObj.weekDayList.includes(dayIndex))
        returnScheduleObj.weekDayList = [...returnScheduleObj.weekDayList]
          .concat(dayIndex)
          .sort();
    });

    // 데이터 가공
    returnScheduleObj.groupScheduleClassDateList = groupScheduleClassDateList;

    const { maxCount, joinedCount } = schedule;

    if (maxCount <= joinedCount) returnScheduleObj.isCapacityOver = true;

    returnScheduleObj.headText = `${firstScheduleText} ~ ${lastScheduleText}`;

    return returnScheduleObj;
  });

  return scheduleList;
};

export const makeSubsScheduleData = timeTableList => {
  if (!timeTableList) return [];

  return timeTableList.map(timeTable => {
    const returnScheduleObj = {
      scheduleId: timeTable.id,
      scheduleGroupId: timeTable.groupNo,
      isCapacityOver: false,
      scheduleList: [],
      headText: null,
      weekDayList: [],
      classTimeText: null,
      ...timeTable,
    };

    // 시간 확인 로직
    const scheduleStartDate = new Date(
      `${timeTable.classDate}T${timeTable.classTimeStart}`,
    );
    returnScheduleObj.scheduleStartDate = scheduleStartDate;
    returnScheduleObj.headText = makeDateTextFormatKo(
      scheduleStartDate,
      'M월 d일부터 매주',
    );
    returnScheduleObj.weekDayList = timeTable.productTimeTableSlots
      .map(ts => ts.dayOfWeek)
      .sort();

    returnScheduleObj.classTimeText = makeDateTextFormatKo(
      scheduleStartDate,
      'aa h시 m분',
    ).replace(' 0분', '');

    // 여석 확인 로직
    const { maxUser, joinedCount } = timeTable;
    if (maxUser <= joinedCount) returnScheduleObj.isCapacityOver = true;

    return returnScheduleObj;
  });
};

// parameter로 받은 스케줄의 시작일자 + 시작시간과 현재 시간 사이에 몇 시간이 차이나는지 계산하는 함수
export const getHoursLeftClassStart = scheduleStartDate => {
  const nowTime = new Date();

  return differenceInHours(scheduleStartDate, nowTime);
};

// HH:mm -> HH
// 수업 검색 일정 필터가 21시까지만 있어서 21 제한
export const returnHour = HHmm => {
  if (!HHmm) return null;
  const [HH] = HHmm.split(':');
  if (Number(HH) > 21) return 21;
  return Number(HH);
};

/**
 * @description
 * 수업 상세 > 중복 스케줄 표기 텍스트 가공 함수
 */
export const makeOverlappedText = overlappedList => {
  if (!overlappedList || overlappedList.length === 0) return null;

  const { childrenName } = overlappedList[0];

  if (overlappedList.length === 1) {
    return `${childrenName} 어린이에게 이미 예정된 스케줄이 있어요.`;
  }

  return `${childrenName} 어린이 외 ${
    overlappedList.length - 1
  }명의 어린이에게 이미 예정된 스케줄이 있어요.`;
};
