export const state = () => ({
  banner: null,
  onEvent: false,
});

export const getters = {
  onEvent: state => state.onEvent,
  banner: state => state.banner,
  paymentEvent: state => state,
};

export const actions = {
  getPaymentEvent({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${window.location.origin}/api/firebase-admin/payment-event`)
        .then(res => {
          commit('SET_PAYMENT_EVENT', { ...res });
          resolve({ ...res });
        })
        .catch(res => {
          console.error(res);
          reject(res);
        });
    });
  },
};

export const mutations = {
  SET_PAYMENT_EVENT: (state, { banner, onEvent }) => {
    state.banner = banner;
    state.onEvent = onEvent;
  },
};
