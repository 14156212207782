
import DepthItem from './DepthItem.vue';

export default {
  name: 'DepthList',
  components: { DepthItem },
  props: {
    categoryName: { type: String, default: '' },
    categoryId: { type: Number, required: true },
    categoryDepthList: { type: Array, required: true, default: () => [] },
  },
};
