import { format, formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';

/**
 * @author Greg
 * @param {Date} date
 * @param {String} dateFormat 'yyyy. M. d.'
 * @description
 * date타입을 받아 date-fns format을 돌려 대한민국 포맷의 string으로 반환해주는 함수
 */
export const makeDateTextFormatKo = (date, dateFormat) => {
  if (!date) return '-';

  const formatType = dateFormat || `yyyy. M. d. (EEEEE) aa h:mm`;

  return format(date, formatType, { locale: ko });
};

/**
 * @returns '2022년 5월 10일 수요일'
 */
export const makeChatDateText = date => {
  // 날짜 포맷 format 함수에서 PPP는 날짜, EEE는 요일, p는 시간을 나타낸다.
  return `${format(new Date(date), 'PPP EEE', { locale: ko })}요일`;
};

export const formatDate = (date, formatStr = 'yyyy년 M월 d일') => {
  return format(new Date(date), formatStr, {
    locale: ko,
  });
};

/**
 * @returns '오후 4:22'
 * @returns '오전 12:22'
 * @returns '정오 12:22 -> 낮 12:22'
 */
export const makeHHMM = date => {
  const d = new Date(date);
  return `${format(d, 'bbb h:mm', { locale: ko }).replace('정오', '낮')}`;
};

/**
 * @returns '2023. 1. 17. (수) 오후 09:00'
 */
export const makeYYMMDDHHMM = date => {
  const d = new Date(date);
  // 날짜 포맷 format 함수에서
  // PPP는 날짜, EEE는 요일, p는 시간을 나타낸다.
  return `${format(d, 'yyyy. M. d. (eee) aaa hh:mm', { locale: ko })}`;
};

/**
 *
 * @param {*} date
 * @returns '방금 전' or  3일 미만일땐 시간차이 출력('몇시간 전', '몇일 전') or '2022년 2월 16일'
 */
export const timeForToday = date => {
  const dateObj = new Date(date);
  const now = Date.now();
  const diff = (now - dateObj.getTime()) / 1000; // 현재 시간과의 차이(초)
  if (diff < 60 * 1) {
    // 1분 미만일땐 '방금 전' 표기
    return '방금 전';
  }
  if (diff < 60 * 60 * 24 * 3) {
    // 3일 미만일땐 시간차이 출력('몇시간 전', '몇일 전') formatDistanceToNow : 현재 시각을 기준으로 단어를 사용해 시간을 나타낸다.(예: '5분 전')
    return formatDistanceToNow(dateObj, { addSuffix: true, locale: ko });
  }
  // 날짜 포맷 format 함수에서 PPP는 날짜, EEE는 요일, p는 시간을 나타낸다.
  return format(dateObj, 'PPP', { locale: ko });
};
