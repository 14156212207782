
/**
 * @description
 * 로드맵 아이템 > 카드 형태일때 뜨는 로드맵 표기 태그
 */

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'CourseTag',
  components: { Icon },
};
