// 홈 화면 > 재능 탭 스토어
import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';

export const actions = {
  /**
   * @description
   * 홈 > 재능 > 이번주 인기 급상승 큐레이션
   */
  async getTrendingList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/trending`,
      );

      return data.map((ti, i) => ({ ...ti, rank: i + 1 }));
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 > 후회없는 첫 구매 선택
   */
  async getMostPurchasedByNewUser() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/most-purchased-by-new-user`,
      );

      return convertV3ClassToV2Product(data);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 > 리얼 후기중에 이미지가 있는 리뷰 받아오는 api
   * 학습 탭에는 리뷰가 따로 없어서 재능 스토어에서 하나로만 관리중입니다.
   * isLogin = 이미지가 있는 리뷰만 받아오는 파라미터
   */
  async getTalentReviewListWithImage(_) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/user/main/review`,
        { params: { isLogin: true, isTalent: true } },
      );
      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 홈 > 재능 > 친구들이 많이 찾는 테마 api
   */
  async getTrendingCategoryList() {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/category/trending`,
      );

      return data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
};
