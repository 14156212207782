
/**
 * @description
 * 로드맵 아이템
 */

import { ADD_FAVORITE_ROADMAP } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { getLastClickSection } from '@/utils/localStorageUtils.js';
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CourseTitle from './CourseTitle.vue';
import TagList from './TagList.vue';
import TeacherImageList from './TeacherImageList.vue';
import ThumbnailItem from './ThumbnailItem.vue';

export default {
  name: 'CourseItem',
  components: { ThumbnailItem, TagList, CourseTitle, TeacherImageList },
  props: {
    id: { type: Number, default: -1, required: true },
    title: { type: String, default: null },
    thumbnailImage: { type: String, default: null },
    categoryName: { type: String, default: null },
    totalClassCount: { type: Number, default: 0 },
    minAge: { type: Number, default: 0 },
    maxAge: { type: Number, default: 0 },
    teacherImages: { type: Array, default: () => [] },
    isCard: { type: Boolean, default: false },
    progress: { type: Number, default: -1 },
    // 스토어에서 삭제할지 유무
    useFavoriteStore: { type: Boolean, default: true },
  },
  data() {
    return {
      isPending: false,
    };
  },
  computed: {
    ...mapGetters('user/favorite', ['favoriteCourseIds']),
    isFavoriteCourse: ({ favoriteCourseIds, id }) =>
      favoriteCourseIds.includes(id),
    favoriteCourseObj() {
      return {
        id: this.id,
        title: this.title,
        thumbnailImage: this.thumbnailImage,
        categoryName: this.categoryName,
        totalClassCount: this.totalClassCount,
        minAge: this.minAge,
        maxAge: this.maxAge,
        teacherImages: this.teacherImages,
        progress: this.progress,
      };
    },
  },
  methods: {
    ...mapActions('user/favorite', [
      'createCourseFavorite',
      'deleteCourseFavorite',
    ]),
    ...mapMutations({
      addFavoriteCourse: 'user/favorite/ADD_FAVORITE_COURSE_PRODUCT',
      removeFavoriteCourse: 'user/favorite/REMOVE_FAVORITE_COURSE_PRODUCT',
    }),
    async onClickCourseFavorite() {
      // 비로그인 유저는 로그인 모달 열고 얼리리턴
      if (!this.$auth.loggedIn) {
        this.requestToken({});
        return;
      }

      if (this.isPending) return;

      try {
        this.isPending = true;

        if (this.isFavoriteCourse) {
          await this.deleteCourseFavorite({ courseId: this.id });
          if (this.useFavoriteStore) {
            this.removeFavoriteCourse({ courseId: this.id });
          }
        } else {
          await this.createCourseFavorite({ courseId: this.id });

          this.logCourseFavorite();

          if (this.useFavoriteStore) {
            this.addFavoriteCourse({
              courseId: this.id,
              course: this.favoriteCourseObj,
            });
          }

          this.$toast.open({
            message:
              '<p class="font-caption-bold">찜한 로드맵에 추가되었어요.</p>',
            type: 'default',
            duration: 2000,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isPending = false;
      }
    },

    logCourseFavorite() {
      this.logUserCustomEvent(ADD_FAVORITE_ROADMAP, {
        roadmap_id: this.id,
        roadmap_name: this.title,
        roadmap_category_name: this.categoryName,
        at: getPathAt(this.$route),
        section: getLastClickSection(),
        is_participate: this.progress === -1 ? undefined : this.progress > 0,
        component: 'card',
      });
    },
  },
};
