const PREV_PATH_KEY = 'prev_path';
const NEW_CARD_SUBSCRIBE = 'subscribe_data';

/**
 * @description
 * 세션스토리지에 key, value 세팅하는 try catch 함수
 * @param {string} key
 * @param {string | Object} value
 * @returns true | false
 */
const _saveSessionStorage = (key, value) => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @param {string} key
 * @returns data | false
 */
const _getSessionStorage = key => {
  try {
    const data = window.sessionStorage.getItem(key);

    // 데이터가 있다면 parse해서 내려주고 없다면 false로 실패
    return data ? JSON.parse(data) : false;
  } catch (e) {
    return false;
  }
};

/**
 * @description
 * 세션스토리지에서 삭제하고싶은 key값 제거 함수
 * @param {string} key
 * @returns true | false
 */
const _removeSessionStorage = key => {
  try {
    window.sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const savePrevPathSessionStorage = prevPath => {
  _saveSessionStorage(PREV_PATH_KEY, prevPath);
};
export const removePrevPathSessionStorage = () => {
  _removeSessionStorage(PREV_PATH_KEY);
};

export const getPrevPathSessionStorage = () => {
  const res = _getSessionStorage(PREV_PATH_KEY);
  return res || null;
};

export const saveSubscribeDataSessionStorage = subsData => {
  _saveSessionStorage(NEW_CARD_SUBSCRIBE, subsData);
};

export const getSubscribeDataSessionStorage = () => {
  const res = _getSessionStorage(NEW_CARD_SUBSCRIBE);
  return res || null;
};

export const removeSubscribeDataSessionStorage = () => {
  _removeSessionStorage(NEW_CARD_SUBSCRIBE);
};
