export const state = () => ({
  shippingList: [],
});

export const getters = {
  shippingList: state => state.shippingList,
  // TODO: 관리 기능 생기기 전까지 레코드 하나만 임시로 사용
  shipping: state => {
    if (state.shippingList.length) {
      return state.shippingList[0];
    }
    return {
      id: 0,
      name: '',
      phone: '',
      zipCode: '',
      address: '',
      addressDetail: '',
    };
  },
};

export const actions = {
  selectShippingList({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/shipping`)
        .then(res => {
          commit('SET_SHIPPING_LIST', { shippingList: res });
          resolve(res);
        })
        .catch(reject);
    });
  },
  createShipping(_, { name, phone, zipCode, address, addressDetail }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/shipping`, {
          name,
          phone,
          zipCode,
          address,
          addressDetail,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  updateShipping(_, { id, name, phone, zipCode, address, addressDetail }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`/v2/user/shipping/${id}`, {
          name,
          phone,
          zipCode,
          address,
          addressDetail,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // TODO: 관리 기능 생기면 살리기
  // deleteShipping(_, { id }) {
  //   return new Promise((resolve, reject) => {
  //     this.$axios
  //       .$delete(`/v2/user/shipping/${id}`)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(reject);
  //   });
  // },
};

export const mutations = {
  SET_SHIPPING_LIST(state, { shippingList }) {
    if (Array.isArray(shippingList)) {
      state.shippingList = shippingList;
    }
  },
};
