const EducationIconSet = {
  book: {
    drawings: [
      {
        type: 'path',
        d: 'M38 40H15.25C12.3505 40 10 37.851 10 35.2M10 35.2C10 32.549 12.3505 30.4 15.25 30.4H34C36.2091 30.4 38 28.6091 38 26.4V11.2C38 9.43269 36.433 8 34.5 8H15.25C12.3505 8 10 10.149 10 12.8V35.2ZM34.5 30.4V40',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
      {
        type: 'path',
        d: 'M18 14H30',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  course: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M6 20H12C12.7956 20 13.5587 19.6839 14.1213 19.1213C14.6839 18.5587 15 17.7957 15 17L15 7C15 6.20435 14.6839 5.44129 14.1213 4.87868C13.5587 4.31607 12.7957 4 12 4L6 4C5.20435 4 4.44129 4.31607 3.87868 4.87868C3.31607 5.44129 3 6.20435 3 7L3 17C3 17.7956 3.31607 18.5587 3.87868 19.1213C4.44129 19.6839 5.20435 20 6 20ZM17 18C16.7551 18 16.5187 17.91 16.3356 17.7473C16.1526 17.5845 16.0357 17.3603 16.007 17.117L16 17L16 7C16.0003 6.74512 16.0979 6.49997 16.2728 6.31463C16.4478 6.12929 16.687 6.01776 16.9414 6.00283C17.1958 5.98789 17.4464 6.07067 17.6418 6.23426C17.8373 6.39785 17.9629 6.6299 17.993 6.883L18 7L18 17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18ZM19.3356 16.7473C19.5187 16.91 19.7551 17 20 17C20.2652 17 20.5196 16.8946 20.7071 16.7071C20.8946 16.5196 21 16.2652 21 16V8L20.993 7.883C20.9629 7.6299 20.8373 7.39786 20.6418 7.23427C20.4464 7.07068 20.1958 6.98789 19.9414 7.00283C19.687 7.01777 19.4478 7.1293 19.2728 7.31463C19.0979 7.49997 19.0003 7.74512 19 8V16L19.007 16.117C19.0357 16.3603 19.1526 16.5845 19.3356 16.7473Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 24 24',
  },
};

const EducationIconNames = Object.keys(EducationIconSet);

export { EducationIconNames, EducationIconSet };
