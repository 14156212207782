export const actions = {
  /**
   * @description
   * 랭킹 상세페이지 > 아이템 리스트를 메인 필터 단위로 받아오는 api
   *
   * aggregationDate: string // 배치 돈 마지막 시간. 업데이트 시간 표기용
   *
   * subFilters: object // 필터 상태에 따라 그룹된 서브 필터 아이템 리스트. 서브필터 id를 키값으로 가지는 object타입으로 내려옵니다.
   *
   * ex) { ENGLISH: [...], MATH: [...] }
   *
   * error시 null 리턴
   * @return { aggregationDate, subFilters  }
   */
  async getRankingClassList(_, { query }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/ranking/group-by-major-filter`,
        { params: query },
      );

      // subFilters 내의 아이템에 rank를 메겨서 내려줍니다.
      return {
        ...data,
        subFilters: Object.entries(data.subFilters).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.map((ri, i) => ({ ...ri, rank: i + 1 })),
          }),
          {},
        ),
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @description
   * 홈 화면 > 조건 걸어 필터링한 랭킹 순위 리스트 받아오는 api
   * error시 null 리턴
   */
  // async getHomeRankingClassList(_, { query }) {
  //   try {
  //     const { data } = await this.$axios.$get(
  //       `${process.env.API_V3}/v3/class/ranking`,
  //       { params: query },
  //     );

  //     // items 내의 아이템에 rank를 메겨서 내려줍니다.
  //     return {
  //       ...data,
  //       items: data.items?.map((ri, i) => ({ ...ri, rank: i + 1 })),
  //     };
  //   } catch (e) {
  //     console.error(e);
  //     return null;
  //   }
  // },
  /**
   * @description
   * 홈 화면 > 전체 랭킹 리스트 받아오는 api
   * error시 null로 리턴해줍니다.
   */
  async getHomeRankingClassAll(_, { learningType }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/ranking/home`,
        { params: { learningType } },
      );

      return Object.entries(data).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.map((ri, i) => ({ ...ri, rank: i + 1 })),
        }),
        {},
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  },
};
