import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2c0ba068 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _77c3589e = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _5742e595 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _89108b2c = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _680d3b1d = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _1fc98540 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _04f208b2 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _24f39d5e = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _4d1db65a = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _28571582 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _452df44b = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _48400610 = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _06e37074 = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _5ad6015c = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _27647e4c = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _7e0ef0c6 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _680e282e = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _91aebf1c = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _0966ab5a = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _59dc5789 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _54408bac = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _cc2bf6c2 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _2af7454a = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _633249c9 = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _9580ff18 = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _048e1f48 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _d8862b14 = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _2aec3cfa = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _5984c416 = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _447c2823 = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _3a6d3531 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _2c184fcc = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _32b4d3be = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _390dceb0 = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _2c60ae8e = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _54c2a3ec = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _01a28289 = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _2565f30a = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _e5d57f54 = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _6719cf70 = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _25c985b0 = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _326afd82 = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _3e193861 = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _470e9c9e = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _74265b8d = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _7e2a657a = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _4cab7190 = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _5dd2ac46 = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _7d670dc2 = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _01ea54c8 = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _06b3c76e = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _6695e55e = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _0af0546e = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _568de716 = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _74d6de0f = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _303cec1e = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _4b14591d = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _137cc75f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _513c40ae = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _0a045383 = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _726fba45 = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _8197a80a = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _6e47e254 = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _4e1f6aa3 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _2a54ff20 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _8c7dcdbc = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _3b2abe25 = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _ce354174 = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _142cbb67 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _663a4eac = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _9ac0d044 = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _5f191dbc = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _1d32c7d3 = () => interopDefault(import('../pages/product/_id/notice/_noticeId.vue' /* webpackChunkName: "pages/product/_id/notice/_noticeId" */))
const _43775303 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _3655a3b4 = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _3ffd9337 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _01565990 = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _2980f738 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _20e8175a = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _2c50a4b6 = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _2c0ba068,
    name: "blog"
  }, {
    path: "/chat",
    component: _77c3589e,
    name: "chat"
  }, {
    path: "/error",
    component: _5742e595,
    name: "error"
  }, {
    path: "/event",
    component: _89108b2c,
    name: "event"
  }, {
    path: "/introduction",
    component: _680d3b1d,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _1fc98540,
    name: "maintenance"
  }, {
    path: "/my",
    component: _04f208b2,
    name: "my"
  }, {
    path: "/notice",
    component: _24f39d5e,
    name: "notice"
  }, {
    path: "/private",
    component: _4d1db65a,
    name: "private"
  }, {
    path: "/product",
    component: _28571582,
    name: "product"
  }, {
    path: "/psych",
    component: _452df44b,
    name: "psych"
  }, {
    path: "/psyche",
    component: _48400610,
    name: "psyche"
  }, {
    path: "/ranking",
    component: _06e37074,
    name: "ranking"
  }, {
    path: "/request",
    component: _5ad6015c,
    name: "request"
  }, {
    path: "/roadmap",
    component: _27647e4c,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _7e0ef0c6,
    name: "schedule"
  }, {
    path: "/search",
    component: _680e282e,
    name: "search"
  }, {
    path: "/teacher",
    component: _91aebf1c,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _0966ab5a,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _59dc5789,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _54408bac,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _cc2bf6c2,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _2af7454a,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _633249c9,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _9580ff18,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _048e1f48,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _d8862b14,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _2aec3cfa,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _5984c416,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _447c2823,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _3a6d3531,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _2c184fcc,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _32b4d3be,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _390dceb0,
    name: "my-subscription"
  }, {
    path: "/my/voucher",
    component: _2c60ae8e,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _54c2a3ec,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _01a28289,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _2565f30a,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _e5d57f54,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _6719cf70,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _25c985b0,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _326afd82,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _3e193861,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _470e9c9e,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _74265b8d,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _7e2a657a,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _4cab7190,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _5dd2ac46,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _7d670dc2,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _01ea54c8,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _06b3c76e,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _6695e55e,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _0af0546e,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _568de716,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _74d6de0f,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _303cec1e,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _4b14591d,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _137cc75f,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _513c40ae,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _0a045383,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _726fba45,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _8197a80a,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _6e47e254,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _4e1f6aa3,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _2a54ff20,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _8c7dcdbc,
    children: [{
      path: "",
      component: _3b2abe25,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _ce354174,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _142cbb67,
    children: [{
      path: "",
      component: _663a4eac,
      name: "product-id"
    }, {
      path: "payment",
      component: _9ac0d044,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _5f191dbc,
      name: "product-id-subscribe"
    }, {
      path: "notice/:noticeId?",
      component: _1d32c7d3,
      name: "product-id-notice-noticeId"
    }]
  }, {
    path: "/psych/:slug",
    component: _43775303,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _3655a3b4,
    children: [{
      path: "",
      component: _3ffd9337,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _01565990,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _2980f738,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _20e8175a,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _2c50a4b6,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
