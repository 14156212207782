
/**
 * @description
 * 홈 > 레이아웃 > 다가오는 수업 > 타이머
 */
import { mapActions } from 'vuex';
import { differenceInMilliseconds } from 'date-fns';
import { getHHMMSS } from '@/utils/timeUtils.js';

export default {
  name: 'UpcomingClassStartTimer',
  props: {
    classStartTime: { type: String, required: true },
    classDate: { type: String, required: true },
    childrenName: { type: String, required: true },
  },
  data() {
    return {
      timer: 0,
      intervalTimer: null,
    };
  },
  computed: {
    timeString() {
      const { mm } = getHHMMSS(this.timer);

      return `
        ${this.timer < 0 ? `수업이 진행 중` : mm <= 1 ? '1분' : `${mm}분`}${
        this.timer >= 0 ? ' 후 수업이 시작해요' : '이에요'
      }`;
    },
  },
  async mounted() {
    const serverTime = await this.receiveServerTime();
    this.timer = differenceInMilliseconds(
      new Date(`${this.classDate}T${this.classStartTime}:00+09:00`),
      serverTime,
    );

    // 30분 이내의 수업일 시 노출
    if (this.timer < 1000 * 60 * 30) {
      this.$emit('show-upcoming');
      this.setIntervalTimer();
    }
  },

  beforeDestroy() {
    this.removeIntervalTimer();
  },
  methods: {
    ...mapActions(['receiveServerTime']),
    setIntervalTimer() {
      this.intervalTimer = setInterval(() => {
        this.timer = this.timer - 1000;
        if (this.timer < 0) {
          this.removeIntervalTimer();
        }
      }, 1000);
    },
    removeIntervalTimer() {
      clearInterval(this.intervalTimer);
    },
  },
};
