/* eslint-disable */
import Vue from 'vue';

import { CLICK_SHARE } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { getPathAt } from '@/utils/path';

export default ({ store, route }, inject) => {
  inject('loginRequiredExternalLink', urlLink => {
    // 비로그인상태라면 로그인 퍼널
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
    } else {
      // 외부 링크 오픈
      window.open(urlLink);
    }
  });

  inject('kakaoShare', () => {
    // 비로그인상태라면 로그인 퍼널
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
    } else {
      const kakaoShareEventObject =
        store.getters['event/kakaoShareEventObject'];

      // 로그인한 유저의 recommendCode를 넣어줍니다.
      kakaoShareEventObject.recommendCode =
        store.state.auth.user?.recommendCode;

      Kakao.Share.sendCustom({
        templateId: process.env.MODE === 'production' ? 91711 : 91671,
        templateArgs: kakaoShareEventObject,
      });

      $nuxt.logUserCustomEvent(CLICK_SHARE, {
        at: getPathAt($nuxt.$route),
        content: 'event',
        method: 'kakao',
        event_slug: store.getters['event/eventSlug'],
      });
    }
  });

  inject('linkShare', () => {
    // 비로그인상태라면 로그인 퍼널
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
    } else {
      const eventSlug = store.getters['event/eventSlug'];
      const shareLink = `${window.location.origin}/event/${eventSlug}?invite=${store.state.auth.user?.recommendCode}`;

      navigator.clipboard.writeText(shareLink).then(() => {
        store.dispatch('openSimpleAlert', '링크가 복사되었습니다.');
      });

      $nuxt.logUserCustomEvent(CLICK_SHARE, {
        at: getPathAt($nuxt.$route),
        content: 'event',
        method: 'copy',
        event_slug: eventSlug,
      });
    }
  });

  inject('issueCoupon', async (...couponCodes) => {
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
      return;
    }

    if (!couponCodes.length) return;

    if (process.client) {
      const couponTitles = [];
      try {
        for (const couponCode of couponCodes) {
          const res = await store.dispatch(
            'user/coupon/createUserCouponByCode',
            { code: couponCode },
          );
          couponTitles.push(res.data.title);
        }
        store.dispatch(
          'openSimpleAlert',
          '쿠폰이 발급되었습니다.\n\n - ' + `${couponTitles.join('\n - ')}`,
        );
      } catch (e) {
        console.error(e);
        let msg = '오류가 발생했습니다. ';
        if (e.response && e.response.data.error) {
          msg = `${e.response.data.message}`;
        } else {
          msg += '잠시 후 다시 시도해 보세요.';
        }
        store.dispatch('openSimpleAlert', msg);
      }
    }
  });

  inject('inviteFriend', type => {
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
      return;
    }

    if (type === 'kakao') {
      // 카카오톡 공유하기
      Kakao.Share.sendCustom({
        templateId: process.env.MODE === 'production' ? 86712 : 86821,
        templateArgs: {
          recommendCode: store.state.auth.user.recommendCode,
          description: '',
        },
      });
    } else {
      const recommendLink = `${window.location.origin}?invite=${store.state.auth.user.recommendCode}`;
      navigator.clipboard.writeText(recommendLink).then(() => {
        store.dispatch('openSimpleAlert', '초대링크가 복사되었습니다.');
      });
    }
  });

  inject('ggugeAlarm', async opt => {
    if (!store.state.auth.loggedIn && Vue.__kakao_mixin__) {
      $nuxt.$callLogin();
      return;
    }

    if (process.client) {
      try {
        await store.dispatch('event/createAlarm', {
          slug: $nuxt.$route?.params?.slug,
          opt,
        });
        await store.dispatch('openSimpleAlert', '알람 신청이 완료되었습니다.');
      } catch (e) {
        console.error(e);
        const message = '오류가 발생했습니다. 잠시 후 다시 시도해 보세요.';
        await store.dispatch('openSimpleAlert', message);
      }
    }
  });
};
