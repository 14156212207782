import intl from '@/utils/intl';

export const state = () => ({
  children: [],
  /* 친구초대 통계 */
  monthlyFriends: 0, // 이번 달
  totalFriends: 0, // 누적
});

export const getters = {
  children: state => state.children,
  userChildrenAgesForQuery: state => {
    if (state.children.length > 0) {
      const today = new Date();

      const resultAgeArr = state.children
        .map(
          // 한국식 세는나이를 배열로 생성
          child =>
            parseInt(intl.getYear(today)) - parseInt(child.birthYear) + 1,
        )
        .reduce((acc, cur) => {
          // 13살 이상 -> 13 & 5살 이하 -> 5로 취급
          const age = cur > 13 ? 13 : cur < 5 ? 5 : cur;
          // 중복 체크
          if (!acc.includes(age)) return acc.concat(age);
          return acc;
        }, []);
      return resultAgeArr.sort((a, b) => a - b).join(',');
    }
    return null;
  },
  userChildrenAgesArrayForFilter: state => {
    const today = new Date();

    const resultAgeArr = state.children
      .map(
        // 한국식 세는나이를 배열로 생성
        child => parseInt(intl.getYear(today)) - parseInt(child.birthYear) + 1,
      )
      .reduce((acc, cur) => {
        // 13살 이상 -> 13 & 5살 이하 -> 5로 취급
        const age = cur > 13 ? 13 : cur < 5 ? 5 : cur;
        // 중복 체크
        if (!acc.includes(age)) return acc.concat(age);
        return acc;
      }, []);

    return resultAgeArr.sort((a, b) => a - b);
  },
  friendsStat: state => {
    return {
      monthly: state.monthlyFriends,
      total: state.totalFriends,
    };
  },
};

export const actions = {
  async checkValidationRecommendCode(_, code) {
    try {
      const res = await this.$axios.$post(`/v2/user/recommend-code/validate`, {
        recommendCode: code,
      });
      return res;
    } catch (e) {
      return e.response.data;
    }
  },

  createUser(
    _,
    { token, name, email, phone, profile, children, isAgree, recommendCode },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/user/signup`, {
          token,
          name,
          email,
          phone,
          profile,
          children,
          isAgree,
          isAgreeEmail: false,
          recommendCode,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  updateUser(_, { name, email, phone, profile, meta, isAgree }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`/v2/user`, {
          name,
          email,
          phone,
          profile,
          meta,
          isAgree,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  getUserKakaoProfile(_) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/me`)
        .then(res => {
          if (!res.error) {
            resolve(res.data);
          } else {
            console.error(res.message);
          }
        })
        .catch(reject);
    });
  },
  deleteUser(_, { reasons, text }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/user/signout`, {
          reasons,
          text,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 친구초대 통계
  selectUserFriendStatistic() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/friend/statistic`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 로그인한 유저의 아이 목록 조회
  selectChildren({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/children`)
        .then(res => {
          res.forEach(child => {
            if (!child.meta || !child.meta.note) {
              child.meta = {
                note: '',
              };
            }
          });
          commit('SET_CHILDREN', res);
          resolve(res);
        })
        .catch(reject);
    });
  },
  createChild(
    _,
    { name, birthYear, birthMonth, birthDay, gender, relation, meta },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/children`, {
          name,
          birthYear,
          birthMonth,
          birthDay,
          gender,
          relation,
          meta,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  updateChild(
    _,
    { id, name, birthYear, birthMonth, birthDay, gender, relation, meta },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$put(`/v2/user/children`, {
          id,
          name,
          birthYear,
          birthMonth,
          birthDay,
          gender,
          relation,
          meta,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  deleteChild(_, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`/v2/user/children/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 범용 이미지 업로드 (여러 개 업로드 가능)
  createImage(_, formData) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/image`, formData)
        .then(res => {
          if (!res.error) {
            resolve(res.data);
          } else {
            console.error(res.message);
          }
        })
        .catch(reject);
    });
  },
  // 단일 범용 이미지 삭제
  deleteImage(_, key) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`/v2/user/image`, {
          data: { key },
        })
        .then(res => {
          if (!res.error) {
            resolve(res.data);
          } else {
            console.error(res.message);
          }
        })
        .catch(reject);
    });
  },
  // 아이별로 들은 수업이 몇개인지 조회 (user_product cnt)
  selectUserProductByChild(_, childId) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/user/children/${childId}/product`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 앱 인증코드 발급
  createUserAppAuthCode() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/user/access-code`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 앱 인증코드 조회
  getUserAppAuthCode() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/access-code`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_CHILDREN(state, children) {
    state.children = children;
  },
};
