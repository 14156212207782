
import { mapActions } from 'vuex';
import { getIsApp } from '@/utils/localStorageUtils.js';

import NavigationBar from '@/components/common/nav/NavigationBar.vue';
import AppFooter from '@/components/layout/footer/AppFooter';

// error 페이지 layout
export default {
  components: { NavigationBar, AppFooter },
  computed: {
    isApp() {
      if (process.client) {
        return this.$route.query.isApp || getIsApp() === true;
      } else {
        return this.$route.query.isApp;
      }
    },
  },
  created() {
    this.getProductCategoryList();
  },
  methods: {
    ...mapActions('filter', ['getProductCategoryList']),
  },
};
