
/**
 * @description
 * 로드맵 아이템의 타이틀 부분
 */

export default {
  name: 'CourseTitle',
  props: {
    title: { type: String, default: null },
  },
};
