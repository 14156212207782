// layout 에 전달하려는 global state 를 관리
export const state = () => ({
  // swiper slides
  slideList: [],
  // alert
  isAlert: false,
  alert: {
    title: '',
    msg: '',
  },
  // layout-result background
  resultBgImg: '',
  alarmList: [],
  isHeaderFill: false,
  // loginModal
  isLoginModalOpen: false,
  loginModalProperties: {},
  loginModalCurrentPath: null,
  isHiddenNavHeader: false,
  isHiddenNavFooter: false,
  activeMobileCategoryListModal: false, // 모바일 > 네비 푸터 > 카테고리 리스트 모달
  // firebase remote config
  maintenance: null, // {isUnder: false, desc: '', startTime: '', endTime: '', serverTime: null}
  openSignupSuccessEventPopup: false,
});

export const getters = {
  slideList: state => state.slideList,
  getIsHiddenNavHeader: state => state.isHiddenNavHeader,
  getIsHiddenNavFooter: state => state.isHiddenNavFooter,
  activeMobileCategoryListModal: state => state.activeMobileCategoryListModal,
  // 대화 이 외에 알림
  alarmList: state => state.alarmList,
  isAlert: state => state.isAlert,
  alert: state => state.alert,
  resultBgImg: state =>
    state.resultBgImg ||
    `url('${require('@/assets/img/result_pattern-bg.png')}')`,
  isHeaderFill: state => state.isHeaderFill,
  isLoginModalOpen: state => state.isLoginModalOpen,
  loginModalProperties: state => state.loginModalProperties,
  loginModalCurrentPath: state => state.loginModalCurrentPath,
  // firebase remote config
  maintenanceDesc: state => state.maintenance.desc,
  maintenanceStartTime: state => state.maintenance.startTime,
  maintenanceEndTime: state => state.maintenance.endTime,
  serverTime: state => state.maintenance.nowServerTime,
  openSignupSuccessEventPopup: state => state.openSignupSuccessEventPopup,
};

export const actions = {
  getAlarmList(storeContext) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2.1/common/alarm?alarmType=excludeFeedback`)
        .then(res => {
          resolve(res);
          if (res.statusCode === 200) {
            storeContext.commit(
              'SET_ALARM_LIST',
              (res.data && res.data[0]) || {},
            );
          }
        })
        .catch(reject);
    });
  },
  // 알람 빨간 불 OFF
  checkAlarm() {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2.1/common/alarm/read`)
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  async nuxtServerInit(_, { res, $auth, query, redirect, error }) {
    // 페이지 첫 진입시 query에 accessToken이 있다면 아래 로직을 탐
    const accessToken = query.kakao_access_token;
    const inviteCode = query.invite;
    const prevPath = query.currentPath;

    if (accessToken && !$auth.loggedIn) {
      await $auth
        .loginWith('local', { data: { token: accessToken } })
        .then(res2 => {
          const authHeader = res.getHeader('set-cookie');
          const resultHeader = authHeader.filter(
            h => !h.split(';').some(s => s === 'auth._token.local=false'),
          );
          res.setHeader('set-cookie', resultHeader);
          delete query.kakao_access_token;
          redirect(query.currentPath || '/', query);
        })
        .catch(e => {
          console.error(e);
          let msg = 'CLIENT';
          if (e.response) {
            if (
              e.response.status === 400 &&
              e.response.data.message === 'Not found user'
            ) {
              let redirectUrl = `${
                query.origin ? query.origin : `https://www.gguge.com`
              }/auth/signup?t=${accessToken}&prevPath=${prevPath}`;
              if (inviteCode)
                redirectUrl = `${redirectUrl}&invite=${inviteCode}`;
              redirect(redirectUrl);
            }

            if (e.response.status === 401 && e.response.data.code) {
              switch (e.response.data.code) {
                // 현재 서버에서 code: 1001만 보내줌.(카카오 인증 실패)
                case 1001:
                  msg = `${e.response.data.code}: ${e.response.data.message}`;
                  break;
                default:
                  msg = `${e.response.data.code}: ${e.response.data.message}`;
                  break;
              }
            } else {
              msg = `${e.response.data.code}: ${e.response.data.message}`;
            }

            // error 페이지로 보내기 (error 페이지용 layout으로 errorLayout이 따로 있어서 default에 있는 access로직을 타지 않습니다)
            error({ message: msg, statusCode: e.response.status });
          }
        });
    }
  },
  openSimpleAlert({ commit }, msg) {
    commit('SET_ALERT', { title: '', msg });
    commit('OPEN_ALERT', true);
  },
  // 서버타밈 받아오는 api
  async receiveServerTime() {
    // window객체가 없다면 서버에서 호출되었으므로 그냥 new Date를 돌려주면 됩니다
    if (!window?.location?.origin) return new Date().getTime();

    try {
      const { serverTime } = await this.$axios.$get(
        `${window.location.origin}/api/serverTime`,
      );
      return serverTime;
    } catch (e) {
      console.error(e);
      return new Date()?.getTime();
    }
  },
  async fileDownloader(_, fileUrl) {
    try {
      const data = await this.$axios.$get(
        `${window.location.origin}/api/fileDownloader`,
        {
          params: { fileUrl },
        },
      );
      const blob = await new Blob([Uint8Array.from(data.arrayBuffer)], {
        type: data.type,
      });
      return blob;
    } catch (e) {
      console.error(e);
    }
  },
  setActiveMobileCategoryListModal({ commit }, bool) {
    commit('SET_ACTIVE_MOBILE_CATEGORY_LIST_MODAL', bool);
  },
  setOpenSignupSuccessEventPopup({ commit }, bool) {
    commit('OPEN_SIGNUP_SUCCESS_EVENT_POPUP', bool);
  },
};

export const mutations = {
  SET_ALARM_LIST(state, alarmList) {
    state.alarmList = alarmList;
  },
  SET_SLIDE_LIST(state, slideList) {
    state.slideList = slideList;
  },
  OPEN_ALERT(state, flag) {
    state.isAlert = flag;
  },
  SET_ALERT(state, alert) {
    Object.assign(state.alert, alert);
  },
  SET_RESULT_BG_IMG(state, string) {
    state.resultBgImg = string;
  },
  SET_HEADER_FILL(state, isFill) {
    state.isHeaderFill = isFill;
  },
  SET_LOGIN_MODAL(state, isOpen) {
    state.isLoginModalOpen = isOpen;
  },
  SET_LOGIN_PROPERTIES(state, { currentPath, ...restProperties }) {
    state.loginModalProperties = { ...restProperties };
    state.loginModalCurrentPath = currentPath;
  },
  RESET_LOGIN_MODAL(state) {
    state.loginModalProperties = {};
    state.loginModalCurrentPath = null;
  },
  setIsHiddenNavHeader(state, bool) {
    state.isHiddenNavHeader = bool;
  },
  setIsHiddenNavFooter(state, bool) {
    state.isHiddenNavFooter = bool;
  },
  SET_MAINTENANCE(state, remoteConfigObj) {
    state.maintenance = { ...remoteConfigObj };
  },
  SET_ACTIVE_MOBILE_CATEGORY_LIST_MODAL(state, bool) {
    state.activeMobileCategoryListModal = bool;
  },
  OPEN_SIGNUP_SUCCESS_EVENT_POPUP(state, bool) {
    state.openSignupSuccessEventPopup = bool;
  },
};

export const strict = false;
