import { addDays, isAfter, isBefore } from 'date-fns';

export const state = () => ({
  noticeList: [],
});

export const getters = {
  noticeList: state => state.noticeList,
};

export const actions = {
  // 공지 목록 조회
  selectNoticeList({ commit }, { page, limit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/notice`, {
          params: {
            page,
            limit,
          },
        })
        .then(res => {
          if (!res.error) {
            res.data.items = res.data.items.map(item => {
              const today = new Date();

              const createdDate = new Date(item.createAt);
              // 공지 생성일로부터 3일까지 공지 유효시간
              const noticeEndDate = addDays(createdDate, 3);

              const isNew =
                isAfter(today, createdDate) && isBefore(today, noticeEndDate);

              return {
                ...item,
                isNew,
              };
            });
            commit('SET_NOTICE_LIST', res.data.items);

            resolve(res);
          } else {
            console.error(res.message);
          }
          resolve(res);
        })
        .catch(reject);
    });
  },
  // 단일 공지 내용 조회
  selectNotice(_, id) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/notice/${id}`)
        .then(res => {
          if (!res.error) {
            resolve(res.data);
          } else {
            console.error(res.message);
          }
        })
        .catch(reject);
    });
  },
};

export const mutations = {
  SET_NOTICE_LIST(state, noticeList) {
    state.noticeList = noticeList;
  },
};
