
export default {
  name: 'LinkButton',
  props: {
    // to나 href중 하나는 있어야 함
    to: { type: [Object, String], default: () => ({ name: '/' }) },
    href: { type: String, default: '' },
    nuxtLinkBlank: { type: Boolean, default: false }, // nuxt-link to 사용중에 새창으로 열기가 쓰고싶다면 true로
    disabled: { type: Boolean, default: false },
    text: { type: String, required: true },
    useLeftIcon: { type: Boolean, default: false },
    useRightIcon: { type: Boolean, default: false },
    leftIcon: { type: String, default: 'gi-small_down_caret' },
    rightIcon: { type: String, default: 'gi-small_down_caret' },
  },
};
