const initialState = {
  teacherId: -1,
  scheduleList: {},
  calendarStartDate: null,
  calendarEndDate: null,
  showPurchasableOnly: false,
  selectedClassType: null,
  minAge: null,
  maxAge: null,

  selectedAgeOption: null,
  earliestTime: null,
  latestTime: null,

  isPending: false,
};

export const state = () => Object.assign({}, initialState);

export const getters = {
  teacherId: ({ teacherId }) => teacherId,
  scheduleList: ({ scheduleList }) => scheduleList,
  calendarStartDate: ({ calendarStartDate }) => calendarStartDate,
  calendarEndDate: ({ calendarEndDate }) => calendarEndDate,
  showPurchasableOnly: ({ showPurchasableOnly }) => showPurchasableOnly,
  selectedClassType: ({ selectedClassType }) => selectedClassType,
  minAge: ({ minAge }) => minAge,
  maxAge: ({ maxAge }) => maxAge,
  selectedAgeOption: ({ selectedAgeOption }) => selectedAgeOption,
  isPending: ({ isPending }) => isPending,
  getEarliestTime: ({ earliestTime }) => {
    if (!earliestTime) return null;
    const eh = earliestTime.slice(0, 2);
    return Math.max(parseInt(eh), 1);
  },
  getLatestTime: ({ latestTime }, { getEarliestTime }) => {
    if (!latestTime) return null;
    const lh = latestTime.slice(0, 2);
    return Math.max(Math.min(parseInt(lh), 24), getEarliestTime);
  },
};

export const actions = {
  /**
   * @description
   * teacher의 스케줄을 범위로 받아와 돌려주는 api
   */
  async getTeacherSchedule(
    { state, commit },
    { teacherId, selectedClassType, minAge, maxAge },
  ) {
    if (state.isPending) return;

    const { calendarStartDate, calendarEndDate } = state;
    try {
      commit('SET_IS_PENDING', true);

      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/teacher-schedule`,
        {
          params: {
            teacherId,
            from: calendarStartDate,
            to: calendarEndDate,
            classType: selectedClassType,
            minAge,
            maxAge,
          },
        },
      );

      if (!data.earliestTime && !data.latestTime) return null;

      return data;
    } catch (e) {
      console.error(e);
      return;
    } finally {
      commit('SET_IS_PENDING', false);
    }
  },
  async selectTeacherSchedule({ state, commit, dispatch }, { teacherId }) {
    if (state.isPending) return;

    commit('SET_TEACHER_ID', { teacherId });

    try {
      const res = await dispatch('getTeacherSchedule', { teacherId });

      if (!res) return false;

      commit('SET_TEACHER_SCHEDULE_LIST', {
        teacherId,
        scheduleList: res.schedules,
        earliestTime: res.earliestTime,
        latestTime: res.latestTime,
      });

      return true;
    } catch (e) {
      console.error(e);
    }
  },
  async setAgeOption({ state, commit, dispatch }, { ageOption, teacherId }) {
    try {
      const [minAge, maxAge] = ageOption ? ageOption.split('~') : [null, null];

      const res = await dispatch('getTeacherSchedule', {
        teacherId,
        selectedClassType: state.selectedClassType,
        minAge,
        maxAge,
      });

      if (!res) return false;

      commit('SET_AGE_OPTION', { ageOption, minAge, maxAge });

      commit('SET_TEACHER_SCHEDULE_LIST', {
        teacherId,
        scheduleList: res.schedules,
        earliestTime: res.earliestTime,
        latestTime: res.latestTime,
      });

      return true;
    } catch (e) {
      console.error(e);
    }
  },
  async setClassType({ state, commit, dispatch }, { classType, teacherId }) {
    try {
      const res = await dispatch('getTeacherSchedule', {
        teacherId,
        selectedClassType: classType,
        minAge: state.minAge,
        maxAge: state.maxAge,
      });

      if (!res) return false;

      commit('SET_CLASS_TYPE', classType);

      commit('SET_TEACHER_SCHEDULE_LIST', {
        teacherId,
        scheduleList: res.schedules,
        earliestTime: res.earliestTime,
        latestTime: res.latestTime,
      });

      return true;
    } catch (e) {
      console.error(e);
    }
  },
};

export const mutations = {
  RESET_SCHEDULE_STORE(state) {
    Object.assign(state, { ...initialState });
  },
  SET_CALENDER_START_DATE(state, startDate) {
    state.calendarStartDate = startDate;
  },
  SET_CALENDER_END_DATE(state, endDate) {
    state.calendarEndDate = endDate;
  },
  SET_TEACHER_ID(state, { teacherId }) {
    state.teacherId = teacherId;
  },
  SET_TEACHER_SCHEDULE_LIST(
    state,
    { teacherId, scheduleList, earliestTime, latestTime },
  ) {
    // 선생님 아이디가 다르면 세팅하지 않습니다 (api응답이 늦게 왔거나 했을 때를 대비한 약간의 수비코드)
    if (state.teacherId !== teacherId) return;
    state.scheduleList = scheduleList;
    state.earliestTime = earliestTime;
    state.latestTime = latestTime;
  },
  SET_SHOW_PURCHASABLE(state, bool) {
    state.showPurchasableOnly = bool;
  },
  SET_IS_PENDING(state, bool) {
    state.isPending = bool;
  },
  SET_AGE_OPTION(state, { ageOption, minAge, maxAge }) {
    state.selectedAgeOption = ageOption;
    state.minAge = minAge;
    state.maxAge = maxAge;
  },
  SET_CLASS_TYPE(state, classType) {
    state.selectedClassType = classType;
  },
};
