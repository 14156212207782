import { numberToCommaString } from './numberString';

function omitText(text, frontTextCount = 8) {
  const displayTitle = {};
  displayTitle.front = text.substring(0, frontTextCount);
  displayTitle.back = text.substring(text.length - 7, text.length);
  return `${displayTitle.front}...${displayTitle.back}`;
}

function classAgeStringMake(classAge) {
  if (!classAge) return classAge;

  const ageList = classAge.match(/\d+/g);

  // 배열이 아니거나 길이가 0이라면 기존 classAge를 그대로 리턴합니다.
  if (!Array.isArray(ageList) || ageList.length === 0) return classAge;

  if (ageList.length === 2) {
    return `${ageList[0]}${
      ageList[0] === ageList[1] ? '' : `~${ageList[1]}`
    }세`;
  }

  return classAge;
}

const koPriceFormat = price => {
  // if (price === 0) return '무료';
  return `${numberToCommaString(price)}원`;
};

export { omitText, classAgeStringMake, koPriceFormat };
