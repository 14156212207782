
import { CLICK_TABBAR } from '@/utils/amplitudeEvent/amplitudeEvents.js';
import { FOOTER_TABBAR } from '@/utils/amplitudeEvent/amplitudeParams.js';
import {
  COMPONENT_NAME,
  SEARCH_INITIATE,
} from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { getPathAt } from '@/utils/path';
import { mapActions, mapGetters } from 'vuex';

import CategoryListModal from './category-list/CategoryListModal.vue';

export default {
  name: 'AppNavFooter',
  components: { CategoryListModal },
  data: () => {
    return {
      tabbarScheduleProp: FOOTER_TABBAR.TABBAR_SCHEDULE,
      tabbarMyInfoProp: FOOTER_TABBAR.TABBAR_MY_INFO,
    };
  },
  computed: {
    ...mapGetters(['activeMobileCategoryListModal']),
    // ...mapGetters('user', ['userChildrenAgesForQuery']),
    searchRouteTo() {
      const routePath = {
        name: 'search',
        params: {
          autoFocus: true,
          from: SEARCH_INITIATE.TAB_BAR,
        },
      };
      // 로그인 유저만 나이 세팅
      // if (this.$auth.loggedIn) {
      //   routePath.query = {
      //     selectedAge: this.userChildrenAgesForQuery,
      //   };
      // }
      return routePath;
    },
  },
  methods: {
    ...mapActions(['setActiveMobileCategoryListModal']),
    // nuxtLink에 로그인이 필수라면, 비로그인인 경우 해당 경로를 ''로 막아 이동하지 못하게 합니다
    nuxtLinkRequiredLoggedIn({ ...restProperties }) {
      if (!this.$auth.loggedIn) {
        return this.$route.fullPath;
      } else {
        return { ...restProperties };
      }
    },
    onClickHome() {
      this.logClickTabBarEvent(FOOTER_TABBAR.HOME);
      this.setActiveMobileCategoryListModal(false);
    },
    onClickCategory() {
      this.logClickTabBarEvent(FOOTER_TABBAR.CATEGORY);
      this.setActiveMobileCategoryListModal(
        !this.activeMobileCategoryListModal,
      );
    },
    onClickSearch() {
      this.logClickTabBarEvent(FOOTER_TABBAR.SEARCH);
      this.setActiveMobileCategoryListModal(false);
    },
    onClickMyProfile() {
      this.logClickTabBarEvent(FOOTER_TABBAR.MY_INFO);
      if (!this.$auth.loggedIn) {
        this.requestToken({});
      }
      this.setActiveMobileCategoryListModal(false);
    },
    onClickCalendar() {
      this.logClickTabBarEvent(FOOTER_TABBAR.SCHEDULE);

      if (!this.$auth.loggedIn) {
        this.requestToken({ componentName: COMPONENT_NAME.calendar });
      }
      this.setActiveMobileCategoryListModal(false);
    },
    logClickTabBarEvent(iconName) {
      this.logUserCustomEvent(CLICK_TABBAR, {
        at: getPathAt(this.$route),
        tab_icon: iconName,
      });
    },
  },
};
