export const actions = {
  // 내 구독 상품 목록 조회 v3
  selectMySubscriptionList(_, { page, limit, subscribeStatus, order }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/class/subscribe`, {
          params: {
            page,
            limit,
            subscribeStatus,
            order,
          },
        })
        .then(res => resolve(res.data))
        .catch(reject);
    });
  },
  /**
   * @description
   * subscribeId로 이 구독이 다음 스케줄을 결제했는지와, 다음 수업 날짜 데이터를 받습니다.
   * 구독 취소하러가기 전 다음 수업을 결제한 이력이 있는지 체크해 환불+취소일지 취소만 할지 결정하는데 사용.
   * @param {string} subscribeId 구독 아이디
   * @returns 구독의 다음 스케줄 결제 여부와 수업 날짜 데이터
   * {
   *  isPaid: true,
   *  nextClassDate: '2023-06-28',
   * }
   */
  selectUserSubscriptionCheckPaid(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/check-paid`,
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 주문: 구독 정보 조회
   * 구독을 해지 하는데 필요한 데이터들을 받아오는 API
   * @param {string} subscribeId 구독 아이디
   * @returns
   * classTitle, endDate, childName, subscriptionPeriod, cardName, cardNumber
   */
  selectUserSubscriptionCancelWait(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/cancel-wait`,
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 구독만 취소 (환불 받지않고)
   * @param {string} subscribeId 구독 아이디
   * @param {string} cancelCode 구독 취소 사유 CODE
   * @param {string} cancelReason 기타 사유시 추가
   */
  cancelSubscribe(_, { subscribeId, cancelCode, cancelReason }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/cancel`,
          {
            cancelCode,
            cancelReason,
          },
        )
        .then(res => {
          resolve(res.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
  /**
   * @description
   * 구독 대기중 상태의 구독을 다시 구독상태로 변경하는 api
   * CANCEL_WAIT -> SUBSCRIBE로 status를 변경해줍니다.
   * @param {string} subscribeId 구독 아이디
   */
  restoreSubscribe(_, { subscribeId }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(
          `${process.env.API_V3}/v3/order/subscribe/${subscribeId}/restore`,
        )
        .then(res => {
          resolve(res);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
};
