
/**
 * @description
 * 네비게이션 > 메뉴 리스트
 * 비로그인, 로그인에 따라 보여주는 컨텐츠가 다르고, drawer 오픈 여부에 따라 버튼이 달라집니다.
 */
import AuthUserNavMenu from '@/components/common/nav/AuthUserNavMenu.vue';
import LandingNavMenu from '@/components/common/nav/LandingNavMenu.vue';

export default {
  name: 'MenuList',
  components: { AuthUserNavMenu, LandingNavMenu },
  props: {
    isDrawer: { type: Boolean, default: false },
  },
  methods: {
    closeDrawer() {
      this.$emit('close-drawer');
    },
    toggleDrawer() {
      this.$emit('toggle-drawer');
    },
  },
};
