import { makeDateTextFormatKo } from '../../utils/dateFnsUtils';
const _COUPON_ORDER_LIST = [
  { value: 'discount', text: '할인가 높은순' },
  { value: 'register', text: '최근 등록순' },
  { value: 'expire', text: '만료 임박순' },
];

const _COUPON_TAB_LIST = [
  { value: 'usable', text: '사용 가능' },
  { value: 'unusable', text: '사용 완료/만료' },
];

const _COUPON_USABLE_ORDER_LIST = [
  { value: 'price', text: '할인가 높은순' },
  { value: 'expire', text: '만료 임박순' },
  { value: 'register', text: '최근 등록순' },
];

const _INITIAL_COUPON = {
  couponId: -1,
  userCouponId: -1,
  title: null,
  discountPrice: 0,
  adjustDiscountPrice: 0,
  overDiscount: false,
  usable: false,
  issueDate: '',
  expireDate: '',
};

const _initCouponStoreState = () => {
  return {
    selectedCouponTab: _COUPON_TAB_LIST[0]?.value || 'usable',
    initSelectedCouponTabIndex: 0,
    couponTabList: _COUPON_TAB_LIST,
    myPageCouponList: [],
    productCouponList: [],
    // 실제 적용되어있는 쿠폰
    appliedCoupon: Object.assign({}, _INITIAL_COUPON),
    // 쿠폰 모달에서 선택한 쿠폰
    selectedCoupon: Object.assign({}, _INITIAL_COUPON),
    couponMeta: {
      currentPage: 0,
      itemCount: 20,
      itemsPerPage: 20,
      totalItems: 0,
      totalPages: 2,
    },
    loadState: {
      isPending: false,
      isError: false,
      errorMessage: '',
    },
    isCouponModalOpen: false,
    selectedCouponOrder: _COUPON_ORDER_LIST[0]?.value || 'discount',
    selectedUsableCouponOrder: _COUPON_USABLE_ORDER_LIST[0]?.value || 'price',
    usableCouponCount: 0,
    maxBenefitCoupon: null,
  };
};

export const state = () => _initCouponStoreState();

export const getters = {
  selectedCouponTab: state => state.selectedCouponTab,
  initSelectedCouponTabIndex: state => state.initSelectedCouponTabIndex,
  couponOrderList: state => {
    if (state.isCouponModalOpen) return _COUPON_USABLE_ORDER_LIST;
    else return _COUPON_ORDER_LIST;
  },
  couponTabList: state => state.couponTabList,
  selectedCouponOrder: state => {
    if (state.isCouponModalOpen) return state.selectedUsableCouponOrder;
    return state.selectedCouponOrder;
  },

  // 적용되어있는 쿠폰
  appliedCoupon: state => state.appliedCoupon,

  // 선택한 쿠폰
  selectedCoupon: state => state.selectedCoupon,

  couponMeta: state => state.couponMeta,

  isPending: state => state.loadState.isPending,
  isError: state => state.loadState.isError,
  errorMessage: state => state.loadState.errorMessage,

  // 내 쿠폰 페이지 쿠폰리스트
  myPageCouponList: state =>
    state.myPageCouponList.map(coupon => {
      return {
        ...coupon,
        userCouponId: coupon.id,
        useDate: coupon?.useDate
          ? makeDateTextFormatKo(new Date(coupon.useDate), 'yyyy-LL-dd')
          : '',
        issueDate: makeDateTextFormatKo(
          new Date(coupon.createAt),
          'yyyy-LL-dd',
        ),
      };
    }),
  couponTotalCount: state => state.couponMeta.totalItems,

  // 쿠폰 사용 모달에 뿌리는 쿠폰 리스트
  productCouponList: state => state.productCouponList,
  usableCouponCount: state => state.usableCouponCount,
  isCouponModalOpen: state => state.isCouponModalOpen,
  isAppliedCoupon: state => state.appliedCoupon.userCouponId > -1,
  isAppliedMaxBenefitCoupon: state =>
    state.appliedCoupon?.couponId === state.maxBenefitCoupon?.couponId,
};

export const actions = {
  createUserCouponByCode(_, { code }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/user/coupon/key`, {
          key: code,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  getUserCouponList({ state, commit }, { page, size }) {
    // early return
    if (state.loadState.isPending) return;

    const loadState = { isPending: true, isError: false, errorMessage: '' };
    commit('SET_LOAD_STATE', loadState);

    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${process.env.API_V3}/v3/user/coupon`, {
          params: {
            page,
            size,
            usable: state.selectedCouponTab === 'usable',
            order: state.selectedCouponOrder,
          },
        })
        .then(res => {
          loadState.isPending = false;
          commit('SET_LOAD_STATE', loadState);
          commit('SET_COUPON_LIST', res.data.items);
          commit('SET_COUPON_META', {
            currentPage: res.data.currentPage,
            itemCount: res.data.items?.length || 0,
            itemsPerPage: res.data.itemsPerPage,
            totalItems: res.data.totalItems,
            totalPages: res.data.totalPages,
          });
          resolve(res);
        })
        .catch(e => {
          loadState.isPending = false;
          loadState.isError = true;
          // TODO 에러메시지 응답형태 확인하기
          loadState.errorMessage = '';
          commit('SET_LOAD_STATE', loadState);
          reject(e);
        });
    });
  },
  // 해당 상품에 사용 가능 여부와 discount 금액 등 정보가 담겨져 오는 api
  async getUserCouponListForThisProduct(
    { state, commit },
    { productId, page, limit },
  ) {
    // early return
    if (state.loadState.isPending) return;

    const loadState = { isPending: true, isError: false, errorMessage: '' };
    commit('SET_LOAD_STATE', loadState);

    try {
      const res = await this.$axios.$get(`/v2/user/coupon/check-product`, {
        params: {
          productId,
          page,
          limit,
          order: state.selectedUsableCouponOrder,
        },
      });
      loadState.isPending = false;
      commit('SET_LOAD_STATE', loadState);
      commit('SET_USABLE_COUPON_COUNT', res.usableCount);
      commit('SET_PRODUCT_COUPON_LIST', res.coupon);
    } catch (error) {
      loadState.isPending = false;
      loadState.isError = true;
      // TODO 에러메시지 응답형태 확인하기
      loadState.errorMessage = '';
      commit('SET_LOAD_STATE', loadState);
    }
  },
  setAppliedMaxBenefitCoupon({ state, commit }) {
    if (state.appliedCoupon?.couponId === state.maxBenefitCoupon?.couponId)
      commit('RESET_COUPON');
    else commit('APPLY_MAX_BENEFIT_COUPON');
  },
};

export const mutations = {
  // 스토어 전체 리셋
  RESET_COUPON_STORE(state) {
    Object.assign(state, _initCouponStoreState());
  },
  // 쿠폰 리스트와 meta 데이터 리셋
  RESET_COUPON_DATA(state) {
    state.myPageCouponList = [];
    state.productCouponList = [];
    state.couponMeta = {
      currentPage: 0,
      itemCount: 20,
      itemsPerPage: 20,
      totalItems: 20,
      totalPages: 2,
    };

    Object.assign(state.selectedCoupon, state.appliedCoupon);
  },
  SELECT_COUPON_TAB(state, { tabValue, tabIndex }) {
    state.selectedCouponTab = tabValue;
    state.initSelectedCouponTabIndex = tabIndex;
  },
  SELECT_COUPON_ORDER(state, { order }) {
    if (state.isCouponModalOpen) state.selectedUsableCouponOrder = order;
    else state.selectedCouponOrder = order;
  },
  SET_LOAD_STATE(state, { isPending, isError, errorMessage }) {
    state.loadState = {
      isPending,
      isError,
      errorMessage,
    };
  },
  SET_COUPON_LIST(state, couponList) {
    state.myPageCouponList.push(...couponList);
  },
  SET_PRODUCT_COUPON_LIST(state, couponList) {
    state.productCouponList.push(...couponList);
  },
  SET_COUPON_META(state, meta) {
    Object.assign(state.couponMeta, meta);
  },
  SELECT_COUPON(state, coupon) {
    state.selectedCoupon = coupon;
  },
  RESET_SELECT_COUPON(state) {
    state.selectedCoupon = Object.assign({}, _INITIAL_COUPON);
  },
  APPLY_COUPON(state) {
    Object.assign(state.appliedCoupon, state.selectedCoupon);
  },
  // 할인율이 가장 높은 쿠폰 찾기
  APPLY_MAX_BENEFIT_COUPON(state) {
    // 사용 가능한 쿠폰이 없다면 return
    if (state.usableCouponCount <= 0) return;

    const maxBenefitCoupon = state.productCouponList
      .filter(c => c.usable === true)
      .reduce((acc, coupon) => {
        if (acc.discountPrice > coupon.discountPrice) {
          return acc;
        }
        return coupon;
      }, state.productCouponList[0]);

    state.maxBenefitCoupon = Object.assign({}, maxBenefitCoupon);
    Object.assign(state.appliedCoupon, maxBenefitCoupon);
  },
  RESET_COUPON(state) {
    state.appliedCoupon = Object.assign({}, _INITIAL_COUPON);
  },
  SET_USABLE_COUPON_COUNT(state, count) {
    state.usableCouponCount = count;
  },
  OPEN_COUPON_MODAL(state) {
    state.isCouponModalOpen = true;

    Object.assign(state.selectedCoupon, state.appliedCoupon);
  },
  CLOSE_COUPON_MODAL(state) {
    state.isCouponModalOpen = false;
  },
};
