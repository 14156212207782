
/**
 * @description
 * svg icon을 위한 래퍼 컴포넌트
 */

import { IconSet, IconNames } from '@/utils/icon/constants.js';

import SvgPath from './SvgPath.vue';
import SvgCircle from './SvgCircle.vue';

export default {
  name: 'Icon',
  components: { SvgPath, SvgCircle },
  props: {
    alt: { type: String, default: null },
    icon: { type: String, default: null },
    size: { type: Number, default: 16 },
  },
  computed: {
    ic() {
      if (this.icon && IconNames.includes(this.icon)) {
        return IconSet[this.icon];
      } else {
        return null;
      }
    },
    viewBox() {
      return this.ic?.viewBox;
    },
    drawings() {
      return this.ic?.drawings;
    },
  },
};
