
/**
 * @description
 * 상품 컴포넌트 상단 썸네일 아이템
 * 좋아요 버튼 & 하단 정보 & 썸네일을 덮는 딤 필터 & 썸네일 포함
 */
import Thumbnail from '@/components/common/product/Thumbnail.vue';
import FavoriteButton from './FavoriteButton.vue';
import DimFilter from './DimFilter.vue';

export default {
  name: 'ThumbnailItem',
  components: { Thumbnail, FavoriteButton, DimFilter },
  props: {
    productId: { type: Number, required: true },
    thumbnailImg: { type: String, default: null },
    dimFilterText: { type: String, default: null },
  },
  methods: {
    clickEvent() {
      this.$emit('click-event');
    },
  },
};
