import { convertClassType } from '@/utils/classType.js';

export const convertV3ClassToV2Product = classList => {
  if (!classList) return [];
  return classList.map(product => {
    return {
      ...product,
      favoriteCount: isNaN(product.favoriteCount)
        ? product.voteCount
        : product.favoriteCount,
      classAge:
        product.minAge === product.maxAge
          ? `${product.minAge}세`
          : `${product.minAge}~${product.maxAge}세`,
      teacher: {
        id: product.teacherId,
        name: product.teacherName,
        profileImg: product.profileImage || product.teacherProfileImage,
      },
      thumbnailImg: product.thumbnailImage,
      classPrice: product.basePrice,
      productStatus: product.classStatus,
      classType: convertClassType(product.classType), // 'SUBSCRIBE' -> '구독 클래스'로 컨버트
    };
  });
};


export const convertV3SearchClassToV2Product = classList => {
  if (!classList) return [];
  return classList.map(product => {
    return {
      ...product,
      classAge:
        product.minAge === product.maxAge
          ? `${product.minAge}세`
          : `${product.minAge}~${product.maxAge}세`,
      teacher: {
        id: product.teacherId,
        name: product.name,
        profileImg: product.profileImage ,
      },
      discountRate: product.discountPrice ? Math.floor((product.discountPrice / (product.classPrice + product.discountPrice) ) * 100) : 0,
      productStatus: product.classStatus,
      ratingAvg: product.reviewRating,
      classType: convertClassType(product.classType), // 'SUBSCRIBE' -> '구독 클래스'로 컨버트
    };
  });
}


