
/**
 * @description
 * 홈 > 헤더 아래 붙는 키워드 리스트
 */

import { mapActions, mapGetters } from 'vuex';

import { getLastHomeType } from '@/utils/cookieUtils';
import ArrowBtn from '@/components/common/btn/ArrowBtn.vue';
import KeywordItem from './KeywordItem.vue';

export default {
  name: 'KeywordList',
  components: { KeywordItem, ArrowBtn },
  data() {
    return {
      isTalentPage: null,
      keywordList: [],

      swiperEl: null,
      // 좌 우 disable용 flag
      leftStop: false,
      rightStop: false,
    };
  },
  fetchOnServer: false,
  async fetch() {
    const lastHomeType = getLastHomeType();
    const isTalentPage =
      (this.$route.query?.tab === undefined && lastHomeType !== 'education') ||
      this.$route.query?.tab === 'talent';

    try {
      // 페이지에서 tab 쿼리가 변경되었다면 다시 패치합니다.
      if (this.isTalentPage === null || this.isTalentPage !== isTalentPage) {
        this.isTalentPage = isTalentPage;
        this.keywordList = await this.getKeywordList({
          isTalent: isTalentPage,
        });
      }

      this.$nextTick(() => {
        this.swiperEl.scroll({ left: 0 });
        this.scrollDisableHandler();
      });
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    ...mapGetters(['getIsHiddenNavHeader']),
    blurClass: ({ leftStop, rightStop }) => {
      const classList = [];

      if (!leftStop) classList.push('left-blur');
      if (!rightStop) classList.push('right-blur');

      return classList.join(' ');
    },
    subClassList: ({ getIsHiddenNavHeader, $fetchState }) =>
      getIsHiddenNavHeader || $fetchState.pending ? '' : 'show',
  },
  watch: {
    // 라우트 이동 (스위칭시) fetch를 다시 시켜 키워드 리스트를 받아오게 합니다.
    $route() {
      this.$fetch();
    },
  },
  beforeDestroy() {
    this.$refs.swiper &&
      this.$refs.swiper.removeEventListener(
        'scroll',
        this.scrollDisableHandler,
      );
  },
  mounted() {
    this.swiperEl = this.$refs.swiper;

    // 스크롤 좌, 우로 끝까지 도달했는지 체크
    this.$refs.swiper.addEventListener('scroll', this.scrollDisableHandler);
  },
  methods: {
    ...mapActions('new-home', ['getKeywordList']),
    handleScrollButton(direction) {
      // 반응형으로 하기 위해서 누른 순간에 스와이퍼의 스크롤 상태를 추적합니다
      const left = Math.ceil(this.swiperEl.scrollLeft);
      // left에서 스크롤된 위치 - 아이템
      const scrollLeft = direction === 'LEFT' ? left - 244 : left + 244;

      this.swiperEl.scroll({
        left: scrollLeft,
        behavior: 'smooth',
      });
    },
    // 좌 우 이동이 더 가능한지 판단
    scrollDisableHandler() {
      const scrollLeft = Math.ceil(this.swiperEl.scrollLeft); // 화면 %에 따라 픽셀이 소수점으로 내려가는 케이스가 있음
      this.leftStop = scrollLeft === 0;
      this.rightStop =
        scrollLeft + this.swiperEl.clientWidth >= this.swiperEl.scrollWidth;
    },
  },
};
