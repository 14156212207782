
export default {
  name: 'Divider',
  props: {
    color: { type: String, default: 'var(--normal-f3)' },
    height: { type: Number, default: 0 },
  },
  computed: {
    style: ({ color, height }) => ({
      backgroundColor: color,
      height: height ? `${height}px` : undefined,
    }),
  },
};
