
import { mapGetters } from 'vuex';

export default {
  name: 'BottomSheetContents',
  computed: {
    ...mapGetters('bottom-sheet', ['bottomSheetStyle']),
    style: ({ bottomSheetStyle }) => {
      if (!bottomSheetStyle) return undefined;
      const { initHeight } = bottomSheetStyle;
      return initHeight && initHeight > 0
        ? `height: ${initHeight}px`
        : undefined;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
