import Cookie from 'js-cookie';

const COOKIE_KEY = {
  // 최근 방문한 신규홈
  LAST_HOME_TYPE: 'lastHomeType',
};

Object.freeze(COOKIE_KEY);

const _setCookie = (key, value) => {
  try {
    Cookie.set(key, value, {expires: 365});
  } catch (e) {
    return false;
  }
};

const _getCookie = (key, req) => {
  try {
    if (req) {
      const cookies = req.headers.cookie.split(';').map(str => {
        const record = str.split('=');
        return {
          key: record[0].trim(),
          value: record[1].trim(),
        };
      });

      const data = cookies.find(x => x.key === key);
      return data ? data.value : false;
    } else {
      const data = Cookie.get(key);
      return data || false;
    }
  } catch (e) {
    return false;
  }
};

export const getLastHomeType = req =>
  _getCookie(COOKIE_KEY.LAST_HOME_TYPE, req) || null;
export const setLastHomeType = homeType =>
  _setCookie(COOKIE_KEY.LAST_HOME_TYPE, homeType);
