
import { PRODUCT_STATUS } from '@/utils/enums.js';

import CommonRating from '@/components/common/CommonRating.vue';
import CommonLikeCount from '@/components/common/CommonLikeCount.vue';

export default {
  name: 'RatingAndFavorite',
  components: { CommonRating, CommonLikeCount },
  props: {
    rating: { type: String, default: '0' },
    ratingCount: { type: Number, default: 0 },
    ratingAvg: { type: Number, default: 0 },
    favoriteCount: { type: Number, default: -1 }, // v2 api에서 favoriteCount가 없는 경우가 있어서 기본값을 -1로 줍니다.
    productStatus: { type: String, default: null },
  },
  computed: {
    // 이 상품이 현재 수요조사중인 상품이라면 표기가 다릅니다.
    isSurveyProduct: ({ productStatus }) =>
      productStatus === PRODUCT_STATUS.SURVEY,
    // 뒷부분이 .0이면 제거
    ratingText: ({ ratingAvg, rating, ratingCount }) => {
      if (ratingAvg > 0) return ratingAvg;
      if (ratingCount <= 0) return 0;

      return (Math.ceil((rating / ratingCount) * 10) / 10)
        .toString()
        .replace('.0', '');
    },
  },
};
