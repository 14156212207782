
/**
 * @description
 * 상품 컴포넌트의 좋아요 버튼
 * favorite store에서 좋아요 리스트를 받아 props로 받은 id가 있는지 체크 후 아이콘 표기 변경
 * click event는 Emit으로 이벤트 올리기만 합니다
 */
import { COMPONENT_NAME } from '@/utils/amplitudeEvent/amplitudeSectionNames.js';
import { mapGetters } from 'vuex';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'FavoriteButton',
  components: { Icon },
  props: {
    productId: { type: Number, required: true },
  },
  computed: {
    ...mapGetters('user/favorite', ['favoriteProductIds']),
    isFavoriteProduct: ({ productId, favoriteProductIds }) =>
      favoriteProductIds.includes(productId),
    favoriteSectionName: () => COMPONENT_NAME.favorite,
  },
  methods: {
    onClickFavorite() {
      if (!this.$auth.loggedIn) {
        this.requestToken({
          productId: this.productId,
          componentName: this.favoriteSectionName,
        });
      } else {
        this.$emit('click-event');
      }
    },
  },
};
