import * as filterOptions from '@/utils/filter/filterOptions';

const initState = filterOptions.INIT_CATEGORY;

export const state = () => Object.assign({}, initState);

export const getters = {
  categoryOptions: state =>
    state.categoryOptions
      ? state.categoryOptions.map(c => {
          return {
            value: c.id.toString(),
            text: c.name,
            icon: c.image,
          };
        })
      : [],
  selectedCategory: state => state.selectedCategory,
  categoryTagList: (state, getters) => {
    const selectedCategory = getters.categoryOptions.find(
      c => c.value === state.selectedCategory,
    );
    if (selectedCategory)
      return [
        {
          value: selectedCategory.value,
          text: selectedCategory.text,
        },
      ];
    else return [];
  },
  isCategoryFilterActive: state => state.selectedCategory !== null,
};

export const actions = {
  selectCategory({ state, commit }, categoryObject) {
    if (categoryObject.value === state.selectedCategory) {
      commit('REMOVE_CATEGORY');
    } else {
      commit('ADD_CATEGORY', categoryObject.value);
    }
  },
  // 필터 초기화
  categoryFilterReset({ commit }) {
    commit('RESET_CATEGORY');
  },
};

export const mutations = {
  RESET_CATEGORY(state) {
    state.selectedCategory = null;
  },
  SET_CATEGORY_LIST(state, res) {
    state.categoryOptions = res;
  },
  SET_CATEGORY_FILTER(state, categoryFilter) {
    Object.assign(state, categoryFilter);
  },
  ADD_CATEGORY(state, category) {
    state.selectedCategory = category;
  },
  REMOVE_CATEGORY(state) {
    state.selectedCategory = null;
  },
};
