const middleware = {}

middleware['show-navigation'] = require('../middleware/show-navigation.js')
middleware['show-navigation'] = middleware['show-navigation'].default || middleware['show-navigation']

middleware['under-maintenance'] = require('../middleware/under-maintenance.js')
middleware['under-maintenance'] = middleware['under-maintenance'].default || middleware['under-maintenance']

middleware['utm-redirection'] = require('../middleware/utm-redirection.js')
middleware['utm-redirection'] = middleware['utm-redirection'].default || middleware['utm-redirection']

export default middleware
