
/**
 * @description
 * drawer메뉴 하단 찜한수업, 찜한로드맵 영역 탭
 */
import TextTabBar from '@/components/common/TextTabBar';
import FavoriteClassList from '@/components/layout/drawer/FavoriteClassList';
import FavoriteCourseList from '@/components/layout/drawer/FavoriteCourseList';

export default {
  name: 'ExpansionSection',
  components: { TextTabBar, FavoriteClassList, FavoriteCourseList },
  props: {
    isDrawer: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selectedTab: 'class',
      tabList: [
        { text: `찜한 수업`, value: 'class' },
        { text: `찜한 로드맵`, value: 'course' },
      ],
    };
  },
  methods: {
    onChangeTab(tabValue) {
      this.selectedTab = tabValue;
    },
    onForceClose() {
      this.$emit('force-close');
    },
  },
};
