import { fromUnixTime } from 'date-fns';
/**
 * @description route middleware에서 점검중인지 확인하고 점검중이면 /maintenance로 리다이렉트합니다.
 * route middleware에서는 store에 접근할 수 있어서, 점검중 시간에 걸린다면, 점검중 페이지에 필요한 정보를 store에 저장해서, maintenance.vue에서 사용하도록 했습니다.
 */
export default async function ({ $axios, store, redirect }) {
  if (store.state?.maintenance) return;
  const remoteConfigObj = await getFirebaseMaintenanceInfo();
  if (!remoteConfigObj) return;

  store.commit('SET_MAINTENANCE', remoteConfigObj);
  if (!store.state?.maintenance?.isUnder) return;
  return redirect('/maintenance');

  /**
   * @description route middleware에서 Firebase Remote Config에서 점검중 정보를 가져옵니다.
   */
  async function getFirebaseMaintenanceInfo() {
    try {
      const response = await $axios({
        method: 'GET',
        url: `${process.env.DOMAIN}/api/firebase-admin`,
        timeout: 3000,
      });
      const data = response.data;
      const maintenanceObj = JSON.parse(data.value);
      const nowServerTime = new Date(fromUnixTime(data.nowServerTime));
      const startTime = new Date(
        new Date(maintenanceObj.startTime).getTime() +
          new Date().getTimezoneOffset() * 60 * 1000,
      );
      const endTime = new Date(
        new Date(maintenanceObj.endTime).getTime() +
          new Date().getTimezoneOffset() * 60 * 1000,
      );
      if (!startTime || !endTime) return null;

      return {
        isUnder: startTime < nowServerTime && endTime > nowServerTime,
        desc: maintenanceObj.description,
        startTime,
        endTime,
        nowServerTime,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
