import {
  makeGroupScheduleData,
  makeOnedayScheduleData,
  makeSubsScheduleData,
} from '@/utils/classScheduleUtils.js';
import { convertClassType } from '@/utils/classType.js';

const initProductPayState = () => {
  return {
    id: null,
    classType: null,
    title: null,
    classAge: '0세~99세',
    scheduleList: [],
    classPrice: 0,
    refundable: true, // 수업 자체의 환불 가능 유무
    shopUrl: null, // 꾸그몰 링크

    serverTime: null, // 서버 타임
    productStatus: null, // 수업 상태
  };
};

export const state = () => initProductPayState();

export const getters = {
  productStatus: state => state.productStatus,
  classType: state => convertClassType(state.classType),
  classId: state => state.id,
  productTitle: state => state.title,
  classAge: state => state.classAge,
  classMinAge: state => {
    const [minAge] = state.classAge.replace('세', '').split('~');

    return +minAge;
  },
  classMaxAge: state => {
    const [minAge, maxAge] = state.classAge.replace('세', '').split('~');

    return maxAge ? +maxAge : +minAge;
  },
  refundable: state => state.refundable,
  productTimeTables: state => state.productTimeTables.filter(t => t.isOpen),
  curriculum: state => {
    if (!state.contentsNew) return [];
    return state.contentsNew.curr;
  },
  filteredScheduleList: state => {
    switch (state.classType) {
      case '원데이 클래스':
        return makeOnedayScheduleData(state.scheduleList);
      case '꾸러미 클래스':
        return makeGroupScheduleData(state.scheduleList);
      case '롤링 클래스':
        return makeSubsScheduleData(state.productTimeTables);
      default:
        return [];
    }
  },
  shopUrl: state => state.shopUrl,
  // 현재 판매중인 상품인가? 플래그
  isUse: state => state.isUse,
};

export const actions = {
  // 서버 타임을 받아옵니다.
  receiveCurrentTime({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`${window.location.origin}/api/serverTime`)
        .then(res => {
          const { serverTime } = res;
          commit('SET_CURRENT_SERVER_TIME', serverTime);
          resolve(serverTime);
        })
        .catch(reject);
    });
  },
  getProductDetail({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$get(`/v2/product/${id}`)
        .then(res => {
          commit('SET_PRODUCT_DETAIL', res);
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description 수업 결제를 위한 orderId를 발급받는 API
   * @param {number} childrenId 아이 ID
   * @param {number} scheduleId 스케줄 ID
   * @param {number} userCouponId 사용하려는 쿠폰 ID
   * @param {number} payMoneyAmount 사용하려는 충전머니
   * @param {number} payRewardMoneyAmount 사용하려는 혜택머니
   * @param {number} pagPgAmount 구매해야하는 PG 결제 금액
   * @param {boolean} agreeEvent 기부 이벤트 동의 여부
   * @returns {object}
   * {
   *  {string} orderUniqueId,
   *  {string} payMethod,
   * }
   */
  createOrderForClass(
    _,
    {
      childrenId,
      scheduleId,
      userCouponId,
      payMoneyAmount,
      payRewardMoneyAmount,
      payPgAmount,
      agreeEvent,
    },
  ) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`${process.env.API_V3}/v3/order/class`, {
          childrenId,
          userCouponId,
          scheduleId,
          paymentInfo: {
            payMoneyAmount,
            payRewardMoneyAmount,
            payPgAmount,
          },
          agreeEvent,
        })
        .then(res => {
          resolve(res?.data);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  },
};

export const mutations = {
  SET_PRODUCT_DETAIL(state, res) {
    Object.assign(state, res.product);
  },
  RESET_PAY_STORE(state) {
    Object.assign(state, initProductPayState());
  },
  SET_SCHEDULE_LIST(state, scheduleList) {
    state.scheduleList = scheduleList;
  },
  SET_CURRENT_SERVER_TIME(state, data) {
    state.serverTime = data;
  },
};
