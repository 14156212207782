import { convertV3ClassToV2Product } from '@/utils/convertV3ToV2.js';

export const actions = {
  // TODO 버전업, 엔드포인트 수정
  createProductFeedback(_, { name, phone, contents }) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/v2/product/comment`, {
          name,
          phone,
          contents,
        })
        .then(res => {
          resolve(res);
        })
        .catch(reject);
    });
  },
  /**
   * @description
   * 구독 커리큘럼을 받아오는 api
   * 종강일이나 휴강일 정보 등도 담겨있음
   */
  async getSubsTimeTableCurriculum(_, { timeTableId, classDate }) {
    try {
      const res = await this.$axios.$get(
        `/v2/product/timetable/${timeTableId}/curriculum`,
        {
          params: { classDate },
        },
      );

      return res;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 상품 상세페이지 하단 해당 상품의 선생님의 또다른 수업들 리스트 받아오는 api
   */
  async getTeacherRelatedClassListByProductId(_, { productId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/${productId}/related-teacher`,
      );

      // 데이터 가공
      const convertClassList = convertV3ClassToV2Product(data);

      return convertClassList || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  /**
   * @description
   * 상품 상세페이지 하단 해당 상품과 같은 추천연령의 수업들 리스트
   */
  async getRecommendByAge(_, { productId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/${productId}/recommended-by-age`,
      );

      // 데이터 가공
      const convertClassList = convertV3ClassToV2Product(data);

      return convertClassList || [];
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  async getRecommendSameCategoryProducts(_, { productId }) {
    try {
      const { data } = await this.$axios.$get(
        `${process.env.API_V3}/v3/class/${productId}/same-category`,
      );

      const convertClassList = convertV3ClassToV2Product(data);
      return convertClassList;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
};
