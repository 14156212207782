
import { mapGetters, mapMutations } from 'vuex';
import { DOM_ID_LIST } from '@/utils/enums.js';

import AppDrawer from '@/components/layout/AppDrawer';
import PsycheLogo from '@/components/psyche/layout/PsycheLogo.vue';

export default {
  name: 'PsycheNavigationBar',
  components: { AppDrawer, PsycheLogo },
  data() {
    return {
      isDrawer: false,
      before: 0,
      headerShadow: false,
    };
  },
  computed: {
    ...mapGetters(['getIsHiddenNavHeader']),
    navigationId: () => DOM_ID_LIST.NAV,
    navClassList() {
      const returnClassList = [];

      if (this.headerShadow) returnClassList.push('header-shadow');
      if (this.$auth.loggedIn) {
        // 로그인
        // 스크롤이 아래로 갔다면 네비를 숨기는 class 추가
        if (this.getIsHiddenNavHeader) returnClassList.push('hide-nav');
      }
      if (this.isDrawer) returnClassList.push('active-drawer');

      return returnClassList.join(' ');
    },
  },
  watch: {
    $route() {
      this.closeDrawer();
    },
  },
  mounted() {
    // <header>가 scrollUp될 때, position: sticky로 수정하고, transition되도록 함.
    addEventListener('scroll', this.scrollEvent);
  },
  methods: {
    ...mapMutations(['setIsHiddenNavHeader']),
    closeDrawer() {
      this.isDrawer = false;
    },
    toggleDrawer() {
      this.isDrawer = !this.isDrawer;
    },
    scrollEvent() {
      // 스크롤이 60(헤더 모바일 높이정도)보다 적을때는 헤더는 늘 보입니다
      if (window?.scrollY < 60) {
        this.setIsHiddenNavHeader(false);
        this.headerShadow = false;
        return;
      }
      this.headerShadow = true;

      if (window.scrollY <= 0 || window.scrollY >= window?.scrollHeight) return;

      if (this.before < window.scrollY) {
        // 스크롤 내릴 때: 헤더가 viewport에서 사라짐
        this.setIsHiddenNavHeader(true);
      } else {
        // 스크롤 올릴 때: 헤더가 viewport에 나타나야 함.
        this.setIsHiddenNavHeader(false);
      }
      this.before = scrollY;
    },
  },
};
