const HeartIconSet = {
  heart: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M23.9877 11.8323C20.3888 7.63827 14.3875 6.51008 9.87845 10.3506C5.36936 14.191 4.73454 20.6121 8.27556 25.1543C11.2197 28.9308 20.1296 36.8957 23.0498 39.4736C23.3765 39.762 23.5398 39.9062 23.7304 39.9629C23.8967 40.0124 24.0787 40.0124 24.245 39.9629C24.4355 39.9062 24.5989 39.762 24.9256 39.4736C27.8458 36.8957 36.7557 28.9308 39.6998 25.1543C43.2408 20.6121 42.6835 14.1506 38.0969 10.3506C33.5103 6.55048 27.5865 7.63827 23.9877 11.8323Z',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  heartBlur: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M15.9918 7.88837C13.5926 5.09234 9.59169 4.34022 6.58563 6.90054C3.57957 9.46086 3.15636 13.7416 5.51704 16.7697C7.47978 19.2873 13.4197 24.5973 15.3665 26.3159C15.5843 26.5082 15.6932 26.6043 15.8203 26.6421C15.9311 26.6751 16.0524 26.6751 16.1633 26.6421C16.2903 26.6043 16.3992 26.5082 16.617 26.3159C18.5638 24.5973 24.5038 19.2873 26.4665 16.7697C28.8272 13.7416 28.4557 9.43393 25.3979 6.90054C22.3402 4.36715 18.391 5.09234 15.9918 7.88837Z',
        fill: 'currentColor',
        'fill-opacity': '0.5',
      },
      {
        type: 'path',
        d: 'M15.2329 8.53957C15.4229 8.76097 15.7 8.88837 15.9918 8.88837C16.2835 8.88837 16.5607 8.76097 16.7507 8.53957C18.8477 6.09568 22.1824 5.53506 24.7599 7.67058C27.3911 9.85057 27.7143 13.5427 25.6779 16.1549C24.7513 17.3433 22.8324 19.2494 20.8505 21.1141C18.9123 22.9378 16.9738 24.6666 15.9918 25.534C15.0097 24.6666 13.0713 22.9378 11.133 21.1141C9.15118 19.2494 7.23222 17.3433 6.3057 16.1549C4.27816 13.5541 4.63072 9.87913 7.23404 7.66183C9.74958 5.5193 13.1283 6.08692 15.2329 8.53957Z',
        stroke: 'currentColor',
        'stroke-opacity': '0.5',
        'stroke-width': '2',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 32 32',
  },
  heartFilled: {
    drawings: [
      {
        type: 'path',
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        d: 'M23.9877 11.8323C20.3888 7.63827 14.3875 6.51008 9.87845 10.3506C5.36936 14.191 4.73454 20.6121 8.27556 25.1543C11.2197 28.9308 20.1296 36.8957 23.0498 39.4736C23.3765 39.762 23.5398 39.9062 23.7304 39.9629C23.8967 40.0124 24.0787 40.0124 24.245 39.9629C24.4355 39.9062 24.5989 39.762 24.9256 39.4736C27.8458 36.8957 36.7557 28.9308 39.6998 25.1543C43.2408 20.6121 42.6835 14.1506 38.0969 10.3506C33.5103 6.55048 27.5865 7.63827 23.9877 11.8323Z',
        fill: 'currentColor',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const HeartIconNames = Object.keys(HeartIconSet);

export { HeartIconNames, HeartIconSet };
