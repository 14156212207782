function numberToCommaString(int) {
  if (typeof int !== 'number') {
    return '0';
  }
  return Math.floor(int).toLocaleString('ko-KR');
}

function stringToNumber(string) {
  if (typeof string !== 'string') return 0;

  // 숫자만 남게 replace
  let returnNumber = string.replaceAll(/\D/g, '');

  if (!returnNumber) returnNumber = 0;

  return parseInt(returnNumber);
}

export { numberToCommaString, stringToNumber };
